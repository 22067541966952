import { useEffect } from 'react'
import {
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
} from '@chakra-ui/react'
import Button from 'components/Button'
import GroupCheckBox from 'components/GroupCheckBox'
import GroupChips from 'components/GroupChips'
import MultipleSelect from 'components/MultipleSelect'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { textBlue500 } from 'theme/globalStyles'
import { statusLeaveRequestOptions } from 'constants/defaultOptionList'
import { getArrayValueFromParsedQuery, getOptionsOfCheckboxGroup } from 'utils/commonUtils'
import { getOptionsSelected } from 'utils/dropdownOptionUtils'
import { IFilterForm } from './interface'

const FilterForm = (props: IFilterForm) => {
  const { openModalFilterForm, setOpenFilterForm, usersList, filterSubmit } = props

  const methods = useForm()
  const { register, handleSubmit, reset } = methods
  const history = useHistory()
  const URLSearchParams = history.location.search

  const filterValues = queryString.parse(URLSearchParams, { parseBooleans: true })

  const selectedUserIds = getArrayValueFromParsedQuery(filterValues, 'userId')
  const selectedStatus = getArrayValueFromParsedQuery(filterValues, 'status')

  const selectedUserOptions = getOptionsSelected(selectedUserIds, usersList)
  const selectedStatusOptions = getOptionsOfCheckboxGroup(selectedStatus, statusLeaveRequestOptions)

  function closeModal(): void {
    setOpenFilterForm(false)
  }
  useEffect(() => {
    reset({
      userId: selectedUserOptions,
      status: selectedStatusOptions,
    })
  }, [usersList])

  function resetForm() {
    reset({
      userId: [],
      status: statusLeaveRequestOptions,
    })
  }

  return (
    <HStack spacing={4} alignSelf="flex-start" display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit((data) => filterSubmit(data))}>
            <DrawerContent>
              <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect name="userId" label="User" options={usersList} selectedEntityName="Developers" />
                    <VStack alignSelf="start">
                      <GroupChips fieldName="userId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 2, lg: 2 }} width="full" marginBottom={5}>
                  <VStack alignItems={'normal'}>
                    <GroupCheckBox {...register('status')} name={'status'} label="Status" />
                  </VStack>
                </SimpleGrid>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                  Reset
                </Button>
                <HStack>
                  <Button onClick={closeModal} variant="outline">
                    Cancel
                  </Button>
                  <Button variant="solid" type="submit">
                    Apply Filter
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
