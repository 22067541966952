import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/interface'

export async function getCvFileForGuestAPI(cvFileName: string): Promise<ISuccessResponse<BlobPart>> {
  try {
    const url = APIRouters.guest.getCVFile.forGuest.cvFileName.value(cvFileName)
    return apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

export async function downloadCVFromGuestAPI(userId: string, cvFileName: string): Promise<ISuccessResponse<BlobPart>> {
  try {
    const url = APIRouters.guest.downloadCV.guestDownload.userId_cvFileName.value(userId, cvFileName)
    return apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}
