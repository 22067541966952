import { getListCreatableOptionsAPI } from 'API/creatableOption'
import {
  ICreatableOptionListWithFilterRequest,
  ICreatableOptionListWithFilterResponse,
} from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { filterPaymentsAdminAPI, editPaymentAdminAPI, editRequestPaymentAPI } from 'API/payment'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import { makeAutoObservable } from 'mobx'
import { IPaymentList } from 'types/payment'
import { ECreatableOptionScope, ECreatableOptionTypes, EStatusOfPaymentRequest } from 'constants/enum'
import { ICreatableOption } from 'constants/schema'
import RootStore from '../rootStore'

class AdminPaymentStore {
  rootStore: RootStore
  payments: IPaymentList[]
  paymentCategoryOptions: ICreatableOption[]
  isPaymentLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPaymentCategoryOptionsList(): Promise<void> {
    try {
      this.isPaymentLoading = true
      const payload: ICreatableOptionListWithFilterRequest = {
        filter: { scope: ECreatableOptionScope.USER, type: ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER },
      }
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI(
        payload
      )
      this.paymentCategoryOptions = response?.data?.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'getPaymentCategoryOptionsList')
    } finally {
      this.isPaymentLoading = false
    }
  }

  public async filterPayments(payload: IFilterPaymentsPayload): Promise<void> {
    try {
      this.isPaymentLoading = true
      const { paymentsList } = await filterPaymentsAdminAPI(payload)
      this.payments = paymentsList
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'filterPaymentsAdmin')
    } finally {
      this.isPaymentLoading = false
    }
  }

  public async editPayment(id: string, field: string, currentValue: boolean): Promise<void> {
    try {
      await editPaymentAdminAPI({
        id,
        field,
        value: !currentValue,
      })
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'editPayment')
    }
  }

  public async editRequestPayment(id: string, value: EStatusOfPaymentRequest): Promise<void> {
    try {
      await editRequestPaymentAPI({ id, value })
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'editRequestPayment')
    }
  }
}

export default AdminPaymentStore
