export interface IHeader {
  Authorization?: string
  'Content-Type'?: string
  Accept?: string
}

export enum EAuthStorageKey {
  IS_REMEMBER_ME = 'isRememberMe',
  REQUEST_TOKEN = 'rToken',
  ACCESS_TOKEN = 'aToken',
  REQUEST_TOKEN_V2 = 'rTokenV2',
  ACCESS_TOKEN_V2 = 'aTokenV2',
}

export enum EStatusCode {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
}
