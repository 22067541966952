import { ViewIcon, DownloadIcon, LinkIcon } from '@chakra-ui/icons'
import { VStack, HStack, Text, Box, Container, useMediaQuery } from '@chakra-ui/react'
import { downloadCVFromAdminAPI } from 'API/user'
import { ReactComponent as CvFile } from 'assets/images/svg/uploadCV.svg'
import MobileCV from 'components/CurriculumVitaeDetail/MobileCV'
import EmptyContentBox from 'components/EmptyContentBox'
import Section from 'components/Section'
import { saveAs } from 'file-saver'
import { useFormContext } from 'react-hook-form'
import { pdfjs } from 'react-pdf'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { maxMobileSize } from 'constants/common'
import { EUserDetailTitle } from 'constants/enum'
import { AppConfig } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { copyToClipboardHandler, toBase64 } from 'utils/commonUtils'
import styles from '../../index.module.scss'
import { EIdItemLink } from '../MenuDetail/MenuItem/constants'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ICurriculumViate {
  userId: string
  name: string
  isAllowedDownloadCV?: string
}

const CurriculumViate = (props: ICurriculumViate) => {
  const { getValues } = useFormContext()
  const { name, userId, isAllowedDownloadCV } = props
  const [isMobile] = useMediaQuery(maxMobileSize)
  const history = useHistory()
  const userCvName = getValues(name)

  function handleViewDetailCV() {
    const pathname = generatePath(frontendRoutes.userPage.detail.cv.id.value, { id: userId })
    history.push(pathname)
  }

  async function downloadCVHandler() {
    const response = await downloadCVFromAdminAPI(userId, userCvName)
    const cv = response.data
    const result = await toBase64(new Blob([cv], { type: 'application/pdf' }))
    saveAs(result, userCvName)
  }

  function copyCVLinkHandler() {
    let enabledDownloadCode
    if (isAllowedDownloadCV === 'Yes') {
      enabledDownloadCode = AppConfig.ENABLED_DOWNLOAD_CV
    } else {
      enabledDownloadCode = AppConfig.DISALLOW_DOWNLOAD_CV
    }
    let shareCVURL = undefined
    if (userCvName) {
      const originalPathName = document.location.origin
      const pathname = generatePath(frontendRoutes.shareCVPage.fileId_userId_sharedCode.value, {
        fileId: userCvName,
        userId,
        sharedCode: enabledDownloadCode,
      })
      shareCVURL = `${originalPathName}${pathname}`
      copyToClipboardHandler(shareCVURL)
      toast.success('Copy to clipboard success')
    }
  }

  return (
    <Section title={EUserDetailTitle.CURRICULUMN_VITAE}>
      {isMobile ? (
        <MobileCV userCVName={userCvName} handleViewDetail={handleViewDetailCV} handleDownload={downloadCVHandler} />
      ) : (
        <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8} id={EIdItemLink.CURRICULUM_VITAE}>
          <VStack padding="15px 12px" className={styles.cvSection}>
            {userCvName ? (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
                  <Box flex="1" />
                  <HStack>
                    <Box onClick={handleViewDetailCV} className={styles.cvButton}>
                      <ViewIcon className={styles.svgIcon} />
                    </Box>
                    <Box onClick={downloadCVHandler} className={styles.cvButton}>
                      <DownloadIcon className={styles.svgIcon} />
                    </Box>
                    <Box onClick={copyCVLinkHandler} className={styles.cvButton}>
                      <LinkIcon className={styles.svgIcon} />
                    </Box>
                  </HStack>
                </Box>
                <Box width={'100%'} border="2px solid #E2E8F0" borderRadius="8px" padding="50px 0">
                  <CvFile />
                  <Text>{userCvName}</Text>
                </Box>
              </>
            ) : (
              <EmptyContentBox text="There is no CV here" />
            )}
          </VStack>
        </Container>
      )}
    </Section>
  )
}

export default CurriculumViate
