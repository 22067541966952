import { VStack, HStack } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import { laptopSize } from 'constants/common'
import { frontendRoutes } from '../../constants/routes'
import { EContractTabs } from './constants'
import ContractList from './ContractList'

const Contract = () => {
  const [isTablet] = useMediaQuery(laptopSize)
  const history = useHistory()

  function handleCreate(): void {
    history.push(frontendRoutes.contractPage.create.value)
  }

  return (
    <VStack
      spacing={{ base: 6, lg: 8 }}
      alignItems={'flex-start'}
      backgroundColor="white"
      borderRadius="8px"
      p={{ base: 6, lg: 8 }}>
      <HStack spacing={3} alignSelf="flex-end" flex={1}>
        <Button variant="solid" leftIcon="add-white.svg" onClick={handleCreate}>
          {isTablet ? String(EContractTabs.CONTRACT_CREATE).split(' ')[0] : String(EContractTabs.CONTRACT_CREATE)}
        </Button>
      </HStack>
      <ContractList />
    </VStack>
  )
}

export default Contract
