import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  SimpleGrid,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import GroupCheckBox from 'components/GroupCheckBox'
import { statusLeaveRequestOptions } from 'constants/defaultOptionList'
import queryString from 'query-string'
import { useEffect } from 'react'
import { FormProvider, useForm, useFormContext, Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import { textBlue500 } from 'theme/globalStyles'
import { getArrayValueFromParsedQuery, getOptionsOfCheckboxGroup } from 'utils/commonUtils'
import { IFilterForm } from './interface'
import { workingPeriodOptions } from 'containers/Diagram/components/DiagramFilterForm/constants'
import SingleSelect from 'components/SingleSelect'
import DatePicker from 'components/DatePicker'
import { Messages } from 'constants/index'

const FilterForm = (props: IFilterForm) => {
  const { openModalFilterForm, setOpenFilterForm, filterSubmit } = props

  const methods = useForm()
  const { register, handleSubmit, reset, control, setValue, setError } = methods
  const history = useHistory()
  const URLSearchParams = history.location.search

  const filterValues = queryString.parse(URLSearchParams, { parseBooleans: true })

  const selectedStatus = getArrayValueFromParsedQuery(filterValues, 'status')

  const selectedStatusOptions = getOptionsOfCheckboxGroup(selectedStatus, statusLeaveRequestOptions)

  function pickDate(date: Date): void {
    setValue('date', date)
  }

  function closeModal(): void {
    setOpenFilterForm(false)
  }
  useEffect(() => {
    reset({
      status: selectedStatusOptions,
    })
  }, [])

  function resetForm() {
    reset({
      status: statusLeaveRequestOptions,
      period: '',
      date: '',
    })
  }

  const onSubmit = (data: any) => {
    console.log(data)
    if ((data.period && !data.date) || (!data.period && data.date)) {
      setError('period', {
        type: 'manual',
        message: Messages.periodDateSelectionErrorMessage,
      })
      setError('date', {
        type: 'manual',
        message: Messages.periodDateSelectionErrorMessage,
      })
      return
    }
    data.period
      ? filterSubmit({
          ...data,
          period: data.period.value,
        })
      : filterSubmit(data)
  }

  return (
    <HStack spacing={4} alignSelf="flex-start" display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit(onSubmit)}>
            <DrawerContent>
              <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 2, lg: 2 }} width="full" marginBottom={5}>
                  <VStack alignItems={'normal'}>
                    <GroupCheckBox {...register('status')} name={'status'} label="Status" />
                  </VStack>
                </SimpleGrid>
                <SimpleGrid marginBottom={5}>
                  <SingleSelect
                    name="period"
                    label="Period"
                    placeHolder="Select Period"
                    optionsData={workingPeriodOptions}
                    isClearable={false}
                  />
                </SimpleGrid>
                <FormControl>
                  <FormLabel fontWeight="400">Date</FormLabel>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field: { value } }) => <DatePicker isRequired={true} selectedDate={value} onChange={pickDate} />}
                  />
                </FormControl>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                  Reset
                </Button>
                <HStack>
                  <Button onClick={closeModal} variant="outline">
                    Cancel
                  </Button>
                  <Button variant="solid" type="submit">
                    Apply Filter
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
