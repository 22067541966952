import { Stack, HStack, Text, VStack, SimpleGrid, FormControl, FormLabel } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { UserRole } from 'constants/defaultOptionList'
import { ECreatableOptionTypes, EPaymentCategoryOfUser, EYesNoOptionWithCapitalization } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { ICreatableOption } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import {
  createOptionsOfSelectFromDropdown,
  createOptionsOfReactSelectFromDB,
  createOptionsBadgeImageOfReactSelectFromDB,
} from 'utils/dropdownOptionUtils'
import { ICreateUserFormProps, isSupervisor, isDownloadCV } from './constants'
import styles from './index.module.scss'

const CreateUserForm = (props: ICreateUserFormProps) => {
  const { projectNamList, partnerList, submitHandler, creatableOptionList, technologyList, isLoading } = props
  const title: string = 'Add New User'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const methods = useForm()
  const history = useHistory()
  const { handleSubmit, watch, reset } = methods
  const options = getValidArray(creatableOptionList)
  const titleOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const technologyListOptions = createOptionsOfReactSelectFromDB(technologyList)
  const levelOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options
      .filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
      .sort((previous, current) => (previous?.order || 0) - (current?.order || 0))
  )
  const paymentCategoryOptions = createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const projectsListOptions = createOptionsOfReactSelectFromDB(projectNamList)
  const extraProjects: [] = watch('projects') !== undefined ? watch('projects') : []
  const extraProjectsOptions = createOptionsOfSelectFromDropdown(extraProjects)
  const checkIsSupervisor = watch('isSupervisor')
  const paymentCategoryOfUser = watch('paymentCategoryId')
  const isFreelancerToo = paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE

  const isReceivedPayroll =
    paymentCategoryOfUser?.label === EPaymentCategoryOfUser.PAYROLL ||
    paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE

  const closeCreateUserForm = () => {
    reset()
    history.push(frontendRoutes.userPage.list.value)
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack p={6} paddingInline={{ base: 6, lg: 6 }} paddingStart={{ base: '27px' }} spacing="30px">
          <Stack
            direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
            className={styles.formHeaderSection}
            padding={{ base: 4, lg: 4 }}>
            <Text fontSize={'lg'} color="gray.700" margin={0} alignSelf="center">
              New User
            </Text>
            <HStack>
              <Button variant="outline" onClick={closeCreateUserForm}>
                Cancel
              </Button>
              <Button variant="solid" type="submit" isLoading={isLoading} disabled={isLoading}>
                Save
              </Button>
            </HStack>
          </Stack>
          <VStack
            background="white"
            padding={{ base: 6, lg: 6 }}
            width="full"
            borderRadius="8px"
            marginTop="24px !important">
            <SimpleGrid columns={{ base: 1, md: 3, lg: 3, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="fullName" label="Full Name" isRequired />
              <FormInput name="email" label="Email" isRequired />
              <SingleSelect
                placeHolder="-Select Partner-"
                name="partnerId"
                label="Partner"
                optionsData={partnersOptions}
                isRequire={true}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="phone" label="Phone Number" type="tel" isRequired />
              <FormControl>
                <FormLabel fontWeight="400">Birth Date (Optional)</FormLabel>
                <Controller
                  name="dateOfBirth"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => handleDatePickerChange(field, date)}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="400">Join Date (Optional)</FormLabel>
                <Controller
                  name="joinDate"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => handleDatePickerChange(field, date)}
                    />
                  )}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 2, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="role" data={UserRole} label="Role" defaultValue="user" />
              <VStack>
                <SingleSelect
                  placeHolder="- Select Title -"
                  name="titleId"
                  label="Title"
                  isRequire
                  optionsData={titleOptions}
                  containerStyle={{ lineHeight: '0px' }}
                />
              </VStack>
              <VStack>
                <SingleSelect
                  placeHolder="- Select Level -"
                  name="levelId"
                  label="Level"
                  isRequire
                  optionsData={levelOptions}
                  containerStyle={{ lineHeight: '0px' }}
                />
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <VStack>
                <MultipleSelect
                  label="Projects (Optional)"
                  name="projects"
                  placeholder="Select Projects"
                  options={projectsListOptions}
                  allowSelectAll={false}
                  selectedEntityName="Projects"
                />
                <VStack alignSelf={'flex-start'}>
                  <GroupChips fieldName="projects" />
                </VStack>
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <SingleSelect
                name="paymentCategoryId"
                label="Category of Payment"
                isRequire
                optionsData={paymentCategoryOptions}
              />
              <VStack>
                <MultipleSelect
                  label="Extra Projects (Optional)"
                  name="extraProjects"
                  options={extraProjectsOptions}
                  isDisabled={!isFreelancerToo}
                  placeholder="Select Extra Projects"
                  allowSelectAll={false}
                  selectedEntityName="Extra Projects"
                />
                <VStack alignSelf={'flex-start'}>
                  <GroupChips fieldName="extraProjects" />
                </VStack>
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="defaultRating" label="Default Rate" isRequired />
              {isReceivedPayroll && <FormInput name="fixedSalary" label="Net Salary (VND)" isRequired />}
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="isSupervisor" data={isSupervisor} label="Is Supervisor" defaultValue="No" />
              <VStack>
                <MultipleSelect
                  label="Projects Of Supervisor (Optional)"
                  name="projectsOfSupervisor"
                  options={extraProjectsOptions}
                  placeholder="Select Projects Of Supervisor"
                  isDisabled={!(checkIsSupervisor === EYesNoOptionWithCapitalization.YES)}
                  allowSelectAll={false}
                  selectedEntityName="Projects Of Supervisor"
                />
                <VStack alignSelf={'flex-start'}>
                  <GroupChips fieldName="projectsOfSupervisor" />
                </VStack>
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <VStack>
                <MultipleSelect
                  label="External Projects (Optional)"
                  name="externalProjects"
                  options={extraProjectsOptions}
                  placeholder="Select External Projects"
                  allowSelectAll={false}
                  selectedEntityName="External Projects"
                />

                <VStack alignSelf={'flex-start'}>
                  <GroupChips fieldName="externalProjects" />
                </VStack>
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="allowToDownloadCV" data={isDownloadCV} label="Is Dowload CV" defaultValue="Yes" />
              <VStack>
                <MultipleSelect
                  label="Technology (Optional)"
                  selectedEntityName="Technology"
                  name="technologies"
                  placeholder="Select Technology"
                  options={technologyListOptions}
                  menuPlacement="top"
                  menuStyles={{
                    borderRadius: '4px 4px 0 0',
                  }}
                  allowSelectAll={false}
                />
                <VStack alignSelf={'flex-start'}>
                  <GroupChips fieldName="technologies" />
                </VStack>
              </VStack>
            </SimpleGrid>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  )
}
export default CreateUserForm
