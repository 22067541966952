import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/interface'
import { AxiosResponse } from 'axios'
import {
  IBillReportListRequest,
  IBillReportListResponse,
  IReportDetailSupervisorResponseData,
  IReportDetailAdminResponseData,
  IReportDetailUserPayload,
  IOverviewReportRequest,
  IOverviewReportResponse,
  IExportDataForSupervisorRequest,
  IExportDataForSupervisorResponse,
  ISupervisorReportRequest,
  IAdminReportRequest,
} from './constants'

export async function getBillReportAdminListAPI(payload: IBillReportListRequest): Promise<IBillReportListResponse> {
  try {
    const url: string = APIRouters.report.reportTimesheet.billReport.admin.list.value
    const response: ISuccessResponse<IBillReportListResponse> = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getReportDetailAdminAPI(
  payload: IAdminReportRequest
): Promise<ISuccessResponse<IReportDetailAdminResponseData>> {
  try {
    const url = APIRouters.report.reportTimesheet.admin.detail.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response
  } catch (error) {
    throw error
  }
}

export async function getReportDetailUserAPI(
  payload: IReportDetailUserPayload
): Promise<ISuccessResponse<IReportDetailSupervisorResponseData>> {
  try {
    const url = APIRouters.report.reportTimesheet.user.detail.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response
  } catch (error) {
    throw error
  }
}

export async function getSupervisorReportAPI(
  data: ISupervisorReportRequest
): Promise<ISuccessResponse<IReportDetailSupervisorResponseData>> {
  try {
    const url = APIRouters.report.reportTimesheet.supervisor.list.value
    return apiV2.post(url, data)
  } catch (error) {
    throw error
  }
}

export async function getOverviewAnalyticsAPI(
  data: IOverviewReportRequest
): Promise<ISuccessResponse<IOverviewReportResponse>> {
  try {
    const url = APIRouters.report.reportTimesheet.overviewAnalytic.value
    return apiV2.post(url, data)
  } catch (error) {
    throw error
  }
}

export async function getExportDataForSupervisorAPI(
  data: IExportDataForSupervisorRequest
): Promise<ISuccessResponse<IExportDataForSupervisorResponse>> {
  try {
    const url = APIRouters.report.reportTimesheet.exportDataForSupervisor.value
    return apiV2.post(url, data)
  } catch (error) {
    throw error
  }
}

export interface ISkillSummaryForAdminItem {
  id: string
  fullName?: string
  title?: string
  level?: string
  workingAttitude?: number
  meetDeadlines?: number
  problemSolving?: number
  communication?: number
  teamPlayer?: number
  english?: number
}
