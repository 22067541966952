import { Box } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import Button from 'components/Button'
import FormInput from 'components/FormInput'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { borderGray200 } from 'theme/globalStyles'
import { ICommentLeaveRequestForm, ILeaveCommentModalProps } from './interface'

const LeaveCommentModal = (props: ILeaveCommentModalProps) => {
  const { isOpen, isLoading, closeHandler, saveHandler, leaveSelectedData } = props
  const methods: UseFormReturn<ICommentLeaveRequestForm> = useForm<ICommentLeaveRequestForm>({})
  const { getValues } = methods

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="lg" isCentered>
      <ModalOverlay />
      <FormProvider {...methods}>
        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
          <ModalContent maxHeight={{ lg: '280px', md: '280px', sm: '280px' }}>
            <Box background="white" width="full">
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                Comment
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            </Box>
            <ModalBody overflowY="auto">
              <FormInput
                placeholder="Add comment"
                id="commentTextarea"
                name="comment"
                isRequired
                type="textarea"
                minHeightOfTextarea="79px"
              />
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button
                onClick={() => saveHandler(leaveSelectedData?.id, getValues('comment'))}
                variant="solid"
                type="submit"
                isLoading={isLoading}>
                Send
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default LeaveCommentModal
