import { Td, Tooltip } from '@chakra-ui/react'
import { EUploadedImageType } from 'constants/enum'
import { getTooltipTitle } from '../../container'
import ImageCell from '../ImageCell'
import StatusCell from '../StatusCell'
import TagColorCell from '../TagColorCell'
import TagListCell from '../TagListCell'
import { ICellProps } from './interfaces'

export enum RENDER {
  CELL = 'Cell',
}

export enum HEADER {
  STATUS = 'Status',
  BOOLEAN = 'ed',
  ROLE = 'Role',
  SCOPE = 'scope',
  TECHNOLOGY = 'TECHNOLOGY',
  LOGO = 'LOGO',
  TAG_COLOR = 'TAG COLOR',
  BADGE_IMAGE = 'BADGE IMAGE',
  FULL_NAME = 'Full Name',
}

const TagHeader: string[] = ['Status', 'Role', 'Scope', 'ed', 'is', 'Archived', 'Deleted']

const capitalizeHeaders = (headersToCapitalize: string[], cellValue: string, columnId: string) => {
  if (headersToCapitalize.includes(columnId) && typeof cellValue === 'string') {
    return cellValue.toUpperCase()
  }
  return cellValue
}

const Cell = (props: ICellProps) => {
  const { cell, isBorderBottomCell = true } = props
  const header: string = cell?.column?.Header ?? ''
  const accessor: string = cell?.column?.id ?? ''

  let cellContent = cell.render(RENDER.CELL)

  if (TagHeader.includes(header)) {
    cellContent = <StatusCell status={cell?.value}>{cell.render(RENDER.CELL)}</StatusCell>
  }

  if (header === HEADER.FULL_NAME) {
    cellContent = capitalizeHeaders([HEADER.FULL_NAME], cell.value, header)
  }

  if (header === HEADER.TECHNOLOGY) {
    cellContent = <TagListCell values={cell?.value} />
  }
  if (header === HEADER.LOGO) {
    cellContent = <ImageCell data={cell?.value} type={EUploadedImageType.LOGO} />
  }
  if (header === HEADER.TAG_COLOR) {
    cellContent = <TagColorCell color={cell?.value} />
  }
  if (header === HEADER.BADGE_IMAGE) {
    cellContent = <ImageCell data={cell?.value} type={EUploadedImageType.BADGE_IMAGE} />
  }

  const tooltipTitle = getTooltipTitle(header, accessor)

  return (
    <Td borderBottom={isBorderBottomCell ? '1px solid black' : 'none'} py={['3px', 2]} px={['2px', 2]}>
      {tooltipTitle ? (
        <Tooltip label={tooltipTitle} aria-label={`${header} tooltip`}>
          <span>{cellContent}</span>
        </Tooltip>
      ) : (
        cellContent
      )}
    </Td>
  )
}

export default Cell
