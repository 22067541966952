import { IProjectNameResponse } from 'API/project/constants'
import { ICreatableOption } from 'types/creatableOption'
import { ITechnology } from 'types/technology'
import { EYesNoOptionWithCapitalization } from 'constants/enum'
import { IPartner } from 'constants/schema'

export const isSupervisor = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isDownloadCV = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export interface ICreateUserFormProps {
  isLoading: boolean
  partnerList: IPartner[]
  projectNamList: IProjectNameResponse[]
  creatableOptionList: ICreatableOption[]
  technologyList: ITechnology[]
  submitHandler
}
