import { FormControl, InputGroup, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { GroupBase, Select } from 'chakra-react-select'
import { IChakraInputDropdownProps, IOption } from 'components/Dropdown/types'
import { DropdownStylesWithoutCondition } from 'components/Styles/DropdownStyles'
import { Controller, useFormContext } from 'react-hook-form'

const InputDropdown = (props: IChakraInputDropdownProps) => {
  const { name, label, optionsData, placeHolder, isRequire, isClearable = true, borderColor, onChangeValue } = props
  const { watch, control } = useFormContext()
  const formValueWatcher: IOption = watch(name)
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequire ? 'This field is required.' : false }}
        render={({ field: { onChange, name, ref }, fieldState: { error } }) => (
          <FormControl width="full" isInvalid={!!error} id={name}>
            {label && (
              <FormLabel fontWeight={'normal'} mb={2} color="gray.700">
                {label}
              </FormLabel>
            )}
            <InputGroup borderRadius="6px" width="full" bg="white">
              <Select<IOption, true, GroupBase<IOption>>
                name={name}
                ref={ref}
                placeholder={placeHolder}
                colorScheme="gray.700"
                value={formValueWatcher}
                options={optionsData}
                closeMenuOnSelect={true}
                size="md"
                isClearable={isClearable}
                onChange={(selectedOption) => {
                  onChange(selectedOption)
                  if (onChangeValue) {
                    onChangeValue(selectedOption)
                  }
                }}
                focusBorderColor={borderColor}
                chakraStyles={DropdownStylesWithoutCondition()}
              />
            </InputGroup>
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </>
  )
}

export default InputDropdown
