import { useState } from 'react'
import { Image, Text } from '@chakra-ui/react'
import NoDataText from 'components/NoDataText'
import { ICreatableOption } from 'types/creatableOption'
import { EUploadedImageType } from 'constants/enum'
import { generateImageURL } from 'utils/imageUtils'

export interface IBadgeImageProps {
  data: ICreatableOption
  isTextBold?: boolean
}

const BadgeImage = (props: IBadgeImageProps) => {
  const { data, isTextBold = false } = props
  const [hasImageError, setHasImageError] = useState(false)
  const fontWeight = isTextBold ? 500 : 400

  if (!data) return null

  if (data?.badgeImage && !hasImageError) {
    return (
      <Image
        role="img"
        src={generateImageURL(data?.badgeImage, EUploadedImageType.BADGE_IMAGE)}
        alt={`${data?.type}-image`}
        maxHeight="32px"
        onError={() => {
          setHasImageError(true)
        }}
        title={`${data.value}`}
      />
    )
  }

  if (data?.value) {
    return <Text fontWeight={fontWeight}>{data?.value}</Text>
  }

  return <NoDataText />
}

export default BadgeImage
