export const leaveRequest = {
  admin: {
    value: '/admin/leave-requests',
    aggregate: {
      value: '/admin/leave-requests/aggregate',
    },
    review: {
      id: {
        value: (id: string) => `/admin/leave-requests/${id}/review`,
      },
    },
    comment: {
      id: {
        value: (id: string) => `/admin/leave-requests/${id}/comment`,
      },
    },
  },
  user: {
    value: '/user/leave-requests',
    id: {
      value: (id: string) => `/user/leave-requests/${id}`,
    },
    aggregate: {
      value: '/user/leave-requests/aggregate',
    },
  },
}
