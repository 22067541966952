import { handleError } from 'API/error'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { getProjectHistoryAPI, getUserHistoryAPI } from 'API/timesheet'
import { getAllNameUserListAPI } from 'API/user'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { EConditionGetList } from 'constants/enum'
import {
  Project,
  User,
  Filters,
  FiltersProject,
  FiltersUser,
  ProjectHistoryData,
  UserHistoryData,
  Mode,
} from 'containers/TimeSheet/TimeSheetAdmin/History/constant'

class AdminTimesheetHistoryStore {
  rootStore: RootStore
  selectedMode: Mode = Mode.PROJECT
  projects: Project[] = []
  users: User[] = []
  isReportLoading: boolean = false
  projectHistoryData: ProjectHistoryData | undefined = undefined
  userHistoryData: UserHistoryData[] = []
  filters: Filters = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    )
    this.rootStore = rootStore
  }

  public setSelectedMode(mode: Mode): void {
    this.selectedMode = mode
    this.filters = {}
    this.projectHistoryData = undefined
    this.userHistoryData = []
  }

  public setFilters(filters: Filters): void {
    this.filters = filters
  }

  public async getListProjects(): Promise<void> {
    try {
      const response = await getAllNameProjectListOfAdminAPI(EConditionGetList.ALL)
      this.projects = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/timesheetAdminHistoryStore.ts', 'getListProjects')
    }
  }

  public async getListUsers(): Promise<void> {
    try {
      const response = await getAllNameUserListAPI(EConditionGetList.ALL)
      this.users = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/timesheetAdminHistoryStore.ts', 'getListUsers')
    }
  }

  public updateQueryString(mode: Mode, parsedValues: Filters): void {
    const params = new URLSearchParams()
    params.set('selectedMode', mode || this.selectedMode)
    Object.keys(parsedValues).forEach((key) => {
      params.set(key, parsedValues[key])
    })
    const newUrl = window.location.pathname + '?' + params.toString()
    window.history.replaceState({}, '', newUrl)
  }

  public async generateProjectHistory(values: FiltersProject): Promise<void> {
    try {
      this.isReportLoading = true
      const parsedValues = { ...values }
      if (values?.project) {
        parsedValues.project = get(values, 'project.value', values.project)
      }
      const response = await getProjectHistoryAPI(parsedValues)
      this.projectHistoryData = response?.data?.projectHistoryData
      this.updateQueryString(Mode.PROJECT, parsedValues)
      this.isReportLoading = false
      this.filters = parsedValues
    } catch (error) {
      handleError(error, 'src/store/admin/timesheetAdminHistoryStore.ts', 'generateProjectHistory', false, true)
      this.projectHistoryData = undefined
      this.filters = {}
      this.isReportLoading = false
    }
  }

  public async generateUserHistory(values: FiltersUser): Promise<void> {
    try {
      this.isReportLoading = true
      const parsedValues = { ...values }
      if (values?.user) {
        parsedValues.user = get(values, 'user.value', values.user)
      }
      const response = await getUserHistoryAPI(parsedValues)
      this.userHistoryData = response?.data?.userHistoryData
      this.updateQueryString(Mode.USER, parsedValues)
      this.isReportLoading = false
      this.filters = parsedValues
    } catch (error) {
      handleError(error, 'src/store/admin/timesheetAdminHistoryStore.ts', 'generateUserHistory')
      this.userHistoryData = []
      this.filters = {}
      this.isReportLoading = false
    }
  }
}

export default AdminTimesheetHistoryStore
