import { Fragment, useEffect, useState } from 'react'
import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react'
import { ReactComponent as LogoMini } from 'assets/images/logo/logo-icon-mini.svg'
import { ReactComponent as Logo } from 'assets/images/logo/logo-icon.svg'
import { ReactComponent as Arrow } from 'assets/images/svg/arrow-left-white.svg'
import { ReactComponent as ArrowRight } from 'assets/images/svg/arrow-right-s-line.svg'
import { ReactComponent as Birthday } from 'assets/images/svg/birthday-cake.svg'
// TODO: Integrate later
// import { ReactComponent as Accounting } from 'assets/images/svg/calculator.svg'
import { ReactComponent as Dashboard } from 'assets/images/svg/dashboard.svg'
import { ReactComponent as Leave } from 'assets/images/svg/holiday.svg'
import { ReactComponent as Information } from 'assets/images/svg/information.svg'
import { ReactComponent as InternalDoc } from 'assets/images/svg/internal-doc.svg'
import { ReactComponent as TodoList } from 'assets/images/svg/list-check.svg'
import { ReactComponent as Payment } from 'assets/images/svg/payment.svg'
import { ReactComponent as Policy } from 'assets/images/svg/policy.svg'
import { ReactComponent as Contract, ReactComponent as Project } from 'assets/images/svg/project.svg'
import { ReactComponent as Extensions } from 'assets/images/svg/qr-code.svg'
import { ReactComponent as Timesheet } from 'assets/images/svg/timesheet.svg'
import { observer } from 'mobx-react'
import { Link, useHistory } from 'react-router-dom'
import { FeatureFlag } from 'react-unleash-flags'
import { maxMobileSize } from 'constants/common'
import { ERoleOfUser } from 'constants/enum'
import { EFirstPathElement } from 'constants/enum'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { frontendRoutes } from 'constants/routes'
import { EUserTitle } from 'constants/user'
import { useStores } from 'utils/hooks/useStores'
import SubMenu from './components/Submenu'
import SubmenuAdmin from './components/SubmenuAdmin'
import {
  settingSubmenu,
  adminDashboardSubmenu,
  ESidebarHeaderTitle,
  ESidebarMenuContent,
  ESubMenuName,
  IRouteName,
} from './constant'
import {
  getRouteName,
  getTimesheetSubmenu,
  handleRedirect,
  getUserDashboardSubmenu,
  getLeaveManagementSubmenu,
} from './utils'

interface INormalSidebarV2Props {
  isCollapsed: boolean
  actionTab: string
  toggleCollapsedSidebar: () => void
}

const NormalSidebarV2 = (props: INormalSidebarV2Props) => {
  const { isCollapsed, actionTab, toggleCollapsedSidebar } = props
  const role: string = window.localStorage.getItem(ELocalStorageKeys.USER_ROLE)
  const userTitle: string = window.localStorage.getItem(ELocalStorageKeys.USER_TITLE)
  const userLevel: string = window.localStorage.getItem(ELocalStorageKeys.USER_LEVEL)
  const isAdmin: boolean = role === ERoleOfUser.ADMIN
  const [subMenu, setSubmenu] = useState<string>('')
  const route: IRouteName = getRouteName(isAdmin)
  const { userProfileStore, authStore } = useStores()
  const { isSupervisor } = userProfileStore
  const [timeSheetMenuList, setTimeSheetMenuList] = useState([])
  const [userDashboardMenuList, setUserDashboardMenuList] = useState([])
  const [leaveManagementMenuList, setLeaveManagementMenuList] = useState([])
  const [isMobile] = useMediaQuery(maxMobileSize)
  const history = useHistory()
  const currentPath = history.location.pathname
  function renderSidebarClassName(name: string): string {
    return actionTab?.includes(name) ? 'sidebar-item active' : 'sidebar-item'
  }

  function renderHRMenuList() {
    if (userTitle === EUserTitle.HR) {
      return (
        <Fragment>
          <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.HUMAN_RESOURCES}</Box>
          <Box
            className={`show-desktop ${renderSidebarClassName(EFirstPathElement.TODO_LIST_OF_HR)}`}
            onClick={() => handleOpenSubmenu(EFirstPathElement.TODO_LIST_OF_HR)}>
            <Link
              to={route.todoListOfHRRoute}
              onClick={() => handleRedirect(route.todoListOfHRRoute, currentPath, handleCollapse)}>
              <TodoList className="icon" /> {ESidebarMenuContent.TODO_LIST_OF_HR}
            </Link>
          </Box>
          <Box
            className={`show-desktop ${renderSidebarClassName(EFirstPathElement.STAFF_DOCUMENT_OF_HR)}`}
            onClick={() => handleOpenSubmenu(EFirstPathElement.STAFF_DOCUMENT_OF_HR)}>
            <Link
              to={route.staffDocumentOfHRRoute}
              onClick={() => handleRedirect(route.staffDocumentOfHRRoute, currentPath, handleCollapse)}>
              <InternalDoc className="icon" /> {ESidebarMenuContent.STAFF_DOCUMENT}
            </Link>
          </Box>
          <Box className={`show-desktop ${renderSidebarClassName(EFirstPathElement.CONTRACT)}`}>
            <Link
              to={frontendRoutes.contractPage.list.value}
              onClick={() => handleRedirect(frontendRoutes.contractPage.list.value, currentPath, handleCollapse)}>
              <Contract className="icon" /> {ESidebarMenuContent.CONTRACTS}
            </Link>
          </Box>
        </Fragment>
      )
    }
    return null
  }

  function forceLogout() {
    const isFirstLogout: string = window.localStorage.getItem(ELocalStorageKeys.IS_FIRST_LOGOUT)
    if (isFirstLogout !== 'YES') {
      window.localStorage.clear()
      window.location.reload()
    }
  }

  function handleOpenSubmenu(subMenuName: string): void {
    if (isCollapsed) {
      toggleCollapsedSidebar()
      setSubmenu(subMenuName)
    } else {
      setSubmenu(subMenuName === subMenu ? '' : subMenuName)
    }
  }

  useEffect(
    function handleActionTab() {
      if (
        actionTab === ESubMenuName.SETTINGS ||
        actionTab === ESubMenuName.USER_DASHBOARD ||
        actionTab === ESubMenuName.ADMIN_DASHBOARD ||
        actionTab === ESubMenuName.TIMESHEET ||
        actionTab === ESubMenuName.LEAVE_MANAGEMENT
      ) {
        setSubmenu(actionTab)
      }
    },
    [actionTab]
  )

  function handleCollapse(): void {
    if (isMobile) {
      toggleCollapsedSidebar()
    }
  }
  function handleSubmenuLeaveOpen() {
    handleOpenSubmenu(ESubMenuName.LEAVE_MANAGEMENT)
  }

  useEffect(() => {
    forceLogout() // Just Apply for version 2.22.0
    userProfileStore.getIsSupervisorOfUser()
    authStore.handleRefreshToken()
  }, [])

  useEffect(() => {
    const timesheetMenuListNew = getTimesheetSubmenu(isAdmin, isSupervisor)
    const userDashboardListNew = getUserDashboardSubmenu(isSupervisor)
    const userLeaveManagementList = getLeaveManagementSubmenu(isAdmin, isSupervisor)
    setTimeSheetMenuList(timesheetMenuListNew)
    setUserDashboardMenuList(userDashboardListNew)
    setLeaveManagementMenuList(userLeaveManagementList)
  }, [isSupervisor])

  function handleSubmenuBasedOnPath() {
    if (currentPath.includes(EFirstPathElement.USER_DASHBOARD)) {
      setSubmenu(ESubMenuName.USER_DASHBOARD)
    } else if (currentPath.includes(EFirstPathElement.TIMESHEET)) {
      setSubmenu(ESubMenuName.TIMESHEET)
    } else if (currentPath.includes(EFirstPathElement.ADMIN_DASHBOARD)) {
      setSubmenu(ESubMenuName.ADMIN_DASHBOARD)
    } else {
      setSubmenu('')
    }
  }

  useEffect(handleSubmenuBasedOnPath, [currentPath])

  return (
    <Box className="sidebar">
      {isCollapsed ? (
        <LogoMini />
      ) : (
        <HStack justifyContent="center" spacing={0} width="100%" height={20}>
          <Arrow onClick={toggleCollapsedSidebar} className="collapse-button" />
          <Logo />
        </HStack>
      )}
      <Box className="sidebar-menu" marginY="14px">
        {isAdmin && (
          <SubmenuAdmin
            subMenuListOfSettingTab={settingSubmenu}
            subMenuListOfAdminDashboardTab={adminDashboardSubmenu}
            getClassName={renderSidebarClassName}
            handleOpenSubmenu={handleOpenSubmenu}
            subMenu={subMenu}
            actionTab={actionTab}
            isCollapsed={isCollapsed}
            handleCollapse={handleCollapse}
          />
        )}
        {!isAdmin && (
          <>
            <Box className="sidebar-header">{ESidebarHeaderTitle.MANAGEMENT}</Box>
            <SubMenu
              subMenuList={userDashboardMenuList}
              isOpen={subMenu === ESubMenuName.USER_DASHBOARD && !isCollapsed}
              className="user-dashboard"
              toggleCollapsedSidebar={toggleCollapsedSidebar}>
              <Box
                className={renderSidebarClassName(EFirstPathElement.USER_DASHBOARD)}
                onClick={() => handleOpenSubmenu(ESubMenuName.USER_DASHBOARD)}>
                <Text userSelect="none">
                  <Dashboard className="icon" /> {ESidebarMenuContent.USER_DASHBOARD}
                </Text>
                {!isCollapsed && (
                  <ArrowRight
                    className={`icon arrowIcon ${subMenu === ESubMenuName.USER_DASHBOARD ? '' : 'rotateArrow'}`}
                  />
                )}
              </Box>
            </SubMenu>
          </>
        )}
        <Box className="sidebar-header">{ESidebarHeaderTitle.DEVELOPMENT}</Box>
        <SubMenu
          subMenuList={timeSheetMenuList}
          isOpen={subMenu === ESubMenuName.TIMESHEET && !isCollapsed}
          className="timesheet"
          toggleCollapsedSidebar={toggleCollapsedSidebar}>
          <Box
            className={renderSidebarClassName(EFirstPathElement.TIMESHEET)}
            onClick={() => handleOpenSubmenu(ESubMenuName.TIMESHEET)}>
            <Text userSelect="none">
              <Timesheet className="icon" /> {ESidebarMenuContent.TIMESHEET}
            </Text>
            {!isCollapsed && (
              <ArrowRight className={`icon arrowIcon ${subMenu === ESubMenuName.TIMESHEET ? '' : 'rotateArrow'}`} />
            )}
          </Box>
        </SubMenu>
        <Box className={renderSidebarClassName(EFirstPathElement.PROJECT)}>
          <Link to={route.projectRoute} onClick={() => handleRedirect(route.projectRoute, currentPath, handleCollapse)}>
            <Project className="icon" /> {ESidebarMenuContent.PROJECT}
          </Link>
        </Box>
        <Box className="sidebar-header">{ESidebarHeaderTitle.GENERAL}</Box>
        <Box className={renderSidebarClassName(EFirstPathElement.POLICIES)}>
          <Link to={route.policyRoute} onClick={() => handleRedirect(route.policyRoute, currentPath, handleCollapse)}>
            <Policy className="icon" /> {ESidebarMenuContent.POLICY}
          </Link>
        </Box>
        <Box className={renderSidebarClassName(EFirstPathElement.ANNOUNCEMENTS)}>
          <Link
            to={route.announcementRoute}
            onClick={() => handleRedirect(route.announcementRoute, currentPath, handleCollapse)}>
            <Information className="icon" />
            {ESidebarMenuContent.ANNOUNCEMENT}
          </Link>
        </Box>
        {isAdmin && (
          <FeatureFlag name={process.env.REACT_APP_FEATURE_FLAG_LEAVE_REQUEST}>
            <SubMenu
              subMenuList={leaveManagementMenuList}
              isOpen={subMenu === ESubMenuName.LEAVE_MANAGEMENT && !isCollapsed}
              className="leave-management"
              toggleCollapsedSidebar={handleCollapse}>
              <Box
                className={renderSidebarClassName(EFirstPathElement.LEAVE_MANAGEMENT)}
                onClick={handleSubmenuLeaveOpen}>
                <Text userSelect="none">
                  <Leave className="icon" /> {ESidebarMenuContent.LEAVE_MANAGEMENT}
                </Text>
                {!isCollapsed && (
                  <ArrowRight
                    className={`icon arrowIcon ${subMenu === ESubMenuName.LEAVE_MANAGEMENT ? '' : 'rotateArrow'}`}
                  />
                )}
              </Box>
            </SubMenu>
          </FeatureFlag>
        )}
        {!isAdmin && (
          <FeatureFlag name={process.env.REACT_APP_FEATURE_FLAG_LEAVE_REQUEST}>
            <SubMenu
              subMenuList={leaveManagementMenuList}
              isOpen={subMenu === ESubMenuName.LEAVE_MANAGEMENT && !isCollapsed}
              className="leave-management"
              toggleCollapsedSidebar={toggleCollapsedSidebar}>
              <Box
                className={renderSidebarClassName(EFirstPathElement.LEAVE_MANAGEMENT)}
                onClick={() => handleOpenSubmenu(ESubMenuName.LEAVE_MANAGEMENT)}>
                <Text userSelect="none">
                  <Leave className="icon" /> {ESidebarMenuContent.LEAVE_MANAGEMENT}
                </Text>
                {!isCollapsed && (
                  <ArrowRight
                    className={`icon arrowIcon ${subMenu === ESubMenuName.LEAVE_MANAGEMENT ? '' : 'rotateArrow'}`}
                  />
                )}
              </Box>
            </SubMenu>
          </FeatureFlag>
        )}
        <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.FINANCIAL}</Box>
        <Box
          className={`show-desktop ${renderSidebarClassName(EFirstPathElement.PAYMENTS)}`}
          onClick={() => handleOpenSubmenu('payments')}>
          <Link to={route.paymentRoute} onClick={() => handleRedirect(route.paymentRoute, currentPath, handleCollapse)}>
            <Payment className="icon" /> {ESidebarMenuContent.PAYMENTS}
          </Link>
        </Box>
        {isAdmin && (
          <Fragment>
            <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.EXTENSIONS}</Box>
            <Box className={`show-desktop ${renderSidebarClassName(EFirstPathElement.QR_CODE)}`}>
              <Link
                to={frontendRoutes.qrCodePage.value}
                onClick={() => handleRedirect(frontendRoutes.qrCodePage.value, currentPath, handleCollapse)}>
                <Extensions className="icon" /> {ESidebarMenuContent.QR_CODE}
              </Link>
            </Box>
            <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.OPERATING_MANAGEMENT}</Box>
            <Box className={`show-desktop ${renderSidebarClassName(EFirstPathElement.CONTRACT)}`}>
              <Link
                to={frontendRoutes.contractPage.list.value}
                onClick={() => handleRedirect(frontendRoutes.contractPage.list.value, currentPath, handleCollapse)}>
                <Contract className="icon" /> {ESidebarMenuContent.CONTRACTS}
              </Link>
            </Box>
            <Box className={`show-desktop ${renderSidebarClassName(EFirstPathElement.BIRTHDAY)}`}>
              <Link
                to={frontendRoutes.birthdayPage.value}
                onClick={() => handleRedirect(frontendRoutes.birthdayPage.value, currentPath, handleCollapse)}>
                <Birthday className="icon" /> {ESidebarMenuContent.BIRTHDAY_OF_STAFFS}
              </Link>
            </Box>
          </Fragment>
        )}
        {renderHRMenuList()}
      </Box>
    </Box>
  )
}
export default observer(NormalSidebarV2)
