import { Tag, TagLabel } from '@chakra-ui/react'
import { convertTextToColor } from 'utils/commonUtils'

interface ITagOnTableProps {
  label: string
  color?: string
  textColor?: string
}

const TagOnTable = (props: ITagOnTableProps) => {
  const { label, color, textColor } = props
  const backgroundColor: string = convertTextToColor(color, 0.2)
  return (
    <Tag
      width="fit-content"
      border="1px solid"
      borderColor={color}
      backgroundColor={backgroundColor}
      color={textColor}
      paddingX={3}
      paddingY={1}
      marginRight={4}
      marginBottom={2}
      borderRadius="6px">
      <TagLabel fontWeight={400} fontSize="md" color={textColor}>
        {label}
      </TagLabel>
    </Tag>
  )
}

export default TagOnTable
