import * as Yup from 'yup'
import { EInternalDocScope } from 'constants/enum'
import { Messages } from 'constants/index'
import { IInternalDocument, IPartner, ICreatableOption } from 'constants/schema'

export const InternalDocumentStatus = ['pending', 'published', 'sent to the partner', 'destroyed']
export const InternalDocumentScope = [EInternalDocScope.INTERNAL, EInternalDocScope.EXTERNAL]

export const initialValues: IInternalDocument = {
  number: '',
  scope: '',
  typeId: '',
  note: '',
  description: '',
  status: '',
  storageURL: '',
  equipmentInfo: '',
  result: '',
  invoiceInfo: '',
  partnerId: null,
  publishDate: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
}

export const validationSchema = Yup.object().shape({
  scope: Yup.string().required(Messages.fieldRequired),
  number: Yup.string().required(Messages.fieldRequired),
  typeId: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  partnerId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required(Messages.fieldRequired),
  note: Yup.string(),
  description: Yup.string(),
  status: Yup.string(),
  storageURL: Yup.string(),
  equipmentInfo: Yup.string(),
  result: Yup.string(),
  invoiceInfo: Yup.string(),
  publishedDate: Yup.date(),
  startDate: Yup.date(),
  endDate: Yup.date(),
  amount: Yup.number(),
  year: Yup.number().min(1900).max(2100),
  month: Yup.number().min(1).max(12),
})

export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface States {
  internalDocument: IInternalDocument
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
}
