import React from 'react'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import {
  updateInternalDocumentAPI,
  createInternalDocumentAPI,
  getInternalDocumentDetailAPI,
} from 'API/internalDocument'
import { IFormattedInternalDocument, IInternalDocumentDetail } from 'API/internalDocument/constants'
import { getAllNamePartnerListOfAdminAPI } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import Button from 'components/Button'
import FormField from 'components/FormField'
import FormModel from 'components/FormModel'
import SelectField from 'components/SelectField'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { EConditionGetList, ECreatableOptionScope } from 'constants/enum'
import { Messages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { ICreatableOption, IInternalDocument, IPartner } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { createOptionsOfSelect, createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { validationSchema, initialValues, Props, States } from './constant'
import { InternalDocumentStatus, InternalDocumentScope } from './constant'
import '../index.scss'

const InternalDocumentCreateForm = (props) => {
  const { dirty, isSubmitting, handleSubmit, match, values, partners, doctypes } = props
  const id = get(match, 'params.id', '')
  const disabled = get(values, 'isDeleted', false)
  const title = id
    ? !disabled
      ? 'Edit Internal Document'
      : 'Internal Document Detail'
    : 'Create New Internal Document'
  window.sessionStorage.setItem('subPageTitle', title.split(' ')[0])
  window.dispatchEvent(new Event('storage'))
  const statusOptions = createOptionsOfSelect(InternalDocumentStatus)
  const scopeOptions = createOptionsOfSelect(InternalDocumentScope)
  const typeOptions = createOptionsOfReactSelectFromDB(doctypes)
  const partnerOptions = createOptionsOfReactSelectFromDB(partners)
  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">{title}</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <SelectField name="scope" required options={scopeOptions} disabled={disabled} />
                <SelectField name="status" options={statusOptions} disabled={disabled} />
                <SelectField name="typeId" label="Type" isCreatable options={typeOptions} disabled={disabled} />
              </div>
              <div className="fields">
                <FormField name="description" disabled={disabled} />
                <FormField name="amount" extraWidth disabled={disabled} />
                <FormField name="number" label="Doc’s number*" extraWidth disabled={disabled} />
              </div>
              <div className="fields">
                <FormField name="month" disabled={disabled} />
                <FormField name="year" disabled={disabled} />
                <FormField name="storageURL" label="Storage URL" disabled={disabled} />
              </div>
              <div className="fields">
                <FormField name="publishDate" label="Publish date" isDate disabled={disabled} />
                <FormField name="equipmentInfo" label="Equipment Info" multiline disabled={disabled} />
                <FormField name="invoiceInfo" label="Lookup E-invoice" multiline disabled={disabled} />
              </div>
              <div className="fields">
                <FormField name="result" disabled={disabled} />
                <FormField name="note" required disabled={disabled} />
                <SelectField
                  name="partnerId"
                  label="Belong to Partner*"
                  isReactSelect
                  options={partnerOptions}
                  data={partners}
                  disabled={disabled}
                />
              </div>
              <div className="fields">
                <FormField name="startDate" label="Start Date*" type="date" extraWidth isDate />
                <FormField name="endDate" label="End Date*" type="date" extraWidth isDate />
              </div>
              <div className="options-sub">
                <Button variant="solid" type="submit" disabled={(!dirty && isSubmitting) || disabled}>
                  {id ? 'Save Internal Document' : 'Create Internal Document'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

class InternalDocumentCreate extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = { internalDocument: initialValues }
  }
  componentDidMount() {
    const { match } = this.props
    const id = get(match, 'params.id', '')
    id && this.getDetailInternalDocumentHandler(id)
    this.getListPartnersHandler()
    this.getListDoctypesHandler()
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return {}
    }
  }
  submitHandler = async (values: IInternalDocument) => {
    try {
      const parsedValues = { ...values }
      if (values?.typeId) {
        parsedValues.typeId = get(values, 'typeId.value', '')
      }
      if (values?.startDate) {
        parsedValues.startDate = new Date(values.startDate)
      }
      if (values?.endDate) {
        parsedValues.endDate = new Date(values.endDate)
      }
      if (values?.publishDate) {
        parsedValues.publishDate = new Date(values.publishDate)
      }
      if (values?.partnerId) {
        parsedValues.partnerId = get(values, 'partnerId.value', '')
      }
      if (!isNaN(Number(values?.amount))) {
        parsedValues.amount = Number(values?.amount)
      }
      if (!parsedValues.partnerId) {
        delete parsedValues.partnerId
      }
      const { match } = this.props
      const id = get(match, 'params.id', '')
      const { history } = this.props
      id ? await updateInternalDocumentAPI(id, parsedValues) : await createInternalDocumentAPI(parsedValues)
      history.push(frontendRoutes.internalDocument.list.value)
      toast.success(id ? Messages.updateInternalDocumentSuccess : Messages.createInternalDocumentSuccess)
    } catch (error) {
      const { match } = this.props
      const id: string = get(match, 'params.id', '')
      toast.error(id ? Messages.updateInternalDocumentFailed : Messages.createInternalDocumentFailed)
      handleError(error, 'src/containers/InternalDocument/InternalDocumentForm/index.tsx', 'submitHandler')
    }
  }
  getDetailInternalDocumentHandler = async (internalDocumentId: string) => {
    try {
      const response: IInternalDocumentDetail = await getInternalDocumentDetailAPI(internalDocumentId)
      const internalDocument: IFormattedInternalDocument = response?.internalDocument
      const partner: IPartner = getValidArray(internalDocument?.partner)?.[0]
      const typeData: ICreatableOption = getValidArray(internalDocument?.typeData)?.[0]

      this.setState({
        internalDocument: {
          ...internalDocument,
          typeId: {
            label: typeData?.value ?? '',
            value: typeData?._id ?? '',
          },
          partnerId: { label: partner?.fullName ?? '', value: partner?._id ?? '' },
        },
      })
    } catch (error) {
      handleError(
        error,
        'src/containers/InternalDocument/InternalDocumentForm/index.tsx',
        'getDetailInternalDocumentHandler'
      )
    }
  }
  getListPartnersHandler = async () => {
    try {
      const response: ISuccessResponse<IPartnerListResponse> = await getAllNamePartnerListOfAdminAPI(
        EConditionGetList.ACTIVE
      )
      this.setState({
        partners: response?.data?.partners,
      })
    } catch (error) {
      handleError(error, 'src/containers/InternalDocument/InternalDocumentForm/index.tsx', 'getListPartnersHandler')
    }
  }
  getListDoctypesHandler = async () => {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: { scope: ECreatableOptionScope.INTERNAL_DOCUMENT },
      })
      this.setState({
        doctypes: response?.data?.creatableOptions ?? [],
      })
    } catch (error) {
      handleError(error, 'src/containers/InternalDocument/InternalDocumentForm/index.tsx', 'getListDoctypesHandler')
    }
  }
  render() {
    const { internalDocument, partners, doctypes } = this.state
    return (
      <div className="internalDocument-create-page" style={{ marginTop: 10 }}>
        <FormModel
          initialValues={internalDocument}
          schema={validationSchema}
          handleSubmit={this.submitHandler}
          component={<InternalDocumentCreateForm partners={partners} doctypes={doctypes} {...this.props} />}
        />
      </div>
    )
  }
}

export default InternalDocumentCreate
