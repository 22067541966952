import { useEffect, useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { VStack, Tag, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { ILeaveTypeDataFromCreateForm } from 'API/leaveType/constants'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import { observer } from 'mobx-react'
import { backgroundBlue500, backgroundGrey200 } from 'theme/globalStyles'
import { ILeaveType } from 'types/leaveRequest'
import { EActionType } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import LeaveTypeModal from '../components/LeaveTypeModal'
import { getLeaveTypeHeaderList } from '../constants'
import styles from '../globalConfig.module.scss'

const LeaveType = () => {
  const { adminLeaveTypeStore } = useStores()
  const { leaveTypeList, isLoadNewLeaveTypeList, isLoadingLeaveTypeList } = adminLeaveTypeStore
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [selectedLeaveType, setSelectedLeaveType] = useState<ILeaveType>()
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState<boolean>(false)

  useEffect(() => {
    adminLeaveTypeStore.getLeaveTypeList()
  }, [isLoadNewLeaveTypeList])

  function openCreateModal(): void {
    setIsOpenCreateModal(true)
  }
  function closeCreateModal(): void {
    setIsOpenCreateModal(false)
    setSelectedLeaveType(null)
  }

  function createNewLeaveTypeHandler(item: ILeaveTypeDataFromCreateForm): void {
    const itemData = {
      name: item?.name ?? '',
      tagColor: item?.tagColor ?? '',
    }
    adminLeaveTypeStore.createLeaveType(itemData)
    closeCreateModal()
  }

  function openConfirmDeleteModal(leaveType: ILeaveType): void {
    setSelectedLeaveType(leaveType)
    setIsOpenConfirmDeleteModal(true)
  }
  function closeConfirmDeleteModal(): void {
    setSelectedLeaveType(null)
    setIsOpenConfirmDeleteModal(false)
  }

  function deleteLeaveTypeHandler(id: string): void {
    adminLeaveTypeStore.deleteLeaveType(id)
    closeConfirmDeleteModal()
  }

  const tableData = getValidArray(leaveTypeList).map((leaveType) => {
    if (leaveType.isDefault) {
      return {
        ...leaveType,
        actions: (
          <Tag
            backgroundColor={backgroundBlue500}
            size="md"
            variant="solid"
            fontWeight="400"
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="8px"
            paddingX="8px"
            paddingY="2px">
            Default
          </Tag>
        ),
      }
    }

    const actions = (
      <OptionButtonsWithoutDropBox
        isEdit={true}
        isDelete={true}
        editActionHandler={() => {}}
        deleteActionHandler={() => {
          openConfirmDeleteModal(leaveType)
        }}
      />
    )
    return {
      ...leaveType,
      actions,
    }
  })

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          width={{ base: '100%', lg: '540px' }}
          background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input type="search" placeholder="Search Leave Type by name..." />
        </InputGroup>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          Create
        </Button>
      </HStack>
      <Table
        className={styles.fullWidth}
        isLoading={isLoadingLeaveTypeList}
        headerList={getLeaveTypeHeaderList()}
        tableData={tableData}
        isStriped
        isShowPagination={true}
        hasBottomHeader={false}
      />
      <LeaveTypeModal
        actionType={EActionType.CREATE}
        isOpen={isOpenCreateModal}
        closeHandler={closeCreateModal}
        saveHandler={createNewLeaveTypeHandler}
        data={selectedLeaveType}
        isSubmitSuccess={isLoadNewLeaveTypeList}
      />
      <ConfirmModal
        data={selectedLeaveType}
        isOpen={isOpenConfirmDeleteModal}
        closeHandler={closeConfirmDeleteModal}
        OKClickHandler={deleteLeaveTypeHandler}
        title={'Leave Type'}
        content={'name'}
        type={EModalType.DELETE}
      />
    </VStack>
  )
}

export default observer(LeaveType)
