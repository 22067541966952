import { handleError } from 'API/error'
import { getAllNamePartnerListForHrAPI } from 'API/partner'
import { IPartnerNameResponse, IPartnerNameListResponse } from 'API/partner/constants'
import { getAllNameUserLisWithAGivenTitleAPI, getAllNameUserListForHrAPI } from 'API/user'
import { IUserNameListResponse, IUserNameResponse } from 'API/user/constants'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { EUserTitle } from 'constants/user'

class UserHumanResourcesStore {
  rootStore: RootStore
  userNameListWithAGivenTitle: IUserNameResponse[] = []
  userNameListForHR: IUserNameResponse[] = []
  partnerNameList: IPartnerNameResponse[]
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getHRUserNameList(condition: string) {
    try {
      const response: ISuccessResponse<IUserNameListResponse> = await getAllNameUserLisWithAGivenTitleAPI(
        EUserTitle.HR,
        condition
      )
      this.userNameListWithAGivenTitle = response.data.users
    } catch (error) {
      handleError(error, 'src/store/user/humanResourcesStore.ts', 'getHRUsers')
    }
  }

  public async getAllNameUserListForHR(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IUserNameListResponse> = await getAllNameUserListForHrAPI(condition)
      this.userNameListForHR = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameUserList')
    }
  }

  public async getPartnerNameList(condition: string) {
    try {
      const response: ISuccessResponse<IPartnerNameListResponse> = await getAllNamePartnerListForHrAPI(condition)
      this.partnerNameList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/user/humanResourcesStore.ts', 'getHRUsers')
    }
  }
}

export default UserHumanResourcesStore
