import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/modal'
import { backgroundGrey200, textGrey500 } from 'theme/globalStyles'
import LeaveRequestDetailForm from '../LeaveRequestDetailForm'
import { ILeaveRequestSummaryModalProps } from './interface'
export default function LeaveRequestSummaryModal(props: ILeaveRequestSummaryModalProps) {
  const { isOpen, handleCloseModal, selectedLeaveRequest } = props

  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={handleCloseModal}>
      <DrawerOverlay />
      <DrawerContent maxHeight="80vh">
        <DrawerHeader borderBottom={`1px solid ${backgroundGrey200}`}>Leave Request</DrawerHeader>
        <DrawerCloseButton color={textGrey500} background="none" />
        <DrawerBody padding={4}>
          <LeaveRequestDetailForm selectedLeaveRequest={selectedLeaveRequest} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
