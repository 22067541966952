import { useEffect, useContext, useState } from 'react'
import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { getAssignmentsAPI, updateAssignmentsAPI } from 'API/diagram'
import { IAssignment, IAssignmentDetailQuery } from 'API/diagram/interface'
import { handleError } from 'API/error'
import Button from 'components/Button'
import startCase from 'lodash/startCase'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { borderGray200, textGrey700, textGrey800 } from 'theme/globalStyles'
import { editAssignFields, EEditAssignModalType, IEditAssignModalProps } from 'containers/Diagram/constant'
import { DiagramContext } from 'containers/Diagram/diagram.context'
import { StackItem } from 'containers/Diagram/diagram.styles'
import { getValidArray } from 'utils/commonUtils'
import DataRow from './components/DataRow'
import { IEditAssignmentTableData, IRowData } from './types'
import { getEditAssignmentTableData, getAssignmentsPayload, getEditAssignmentsPayload } from './utils'

const EditAssignModal = (props: IEditAssignModalProps) => {
  const { isProjectNode, nodeDetailInformation, isOpen, onClose } = props
  const methods: UseFormReturn<IEditAssignmentTableData> = useForm<IEditAssignmentTableData>()
  const { handleSubmit, reset } = methods
  const { fetchDiagramData } = useContext(DiagramContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fieldName: EEditAssignModalType = isProjectNode ? EEditAssignModalType.PROJECT : EEditAssignModalType.USER

  async function onSubmit(data: IEditAssignmentTableData): Promise<void> {
    try {
      setIsLoading(true)
      const editAssignmentPayload: IAssignment[] = getEditAssignmentsPayload(data[fieldName])
      await updateAssignmentsAPI(editAssignmentPayload)
      await fetchDiagramData()
      toast.success('Update assignments successfully')
      onClose()
    } catch (error) {
      toast.error('Update assignments failed')
      handleError(error, 'src/containers/Diagram/components/EditAssignModal/index.tsx', 'onSubmit')
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchData(): Promise<void> {
    try {
      const assignmentsPayload: IAssignmentDetailQuery[] = getAssignmentsPayload(nodeDetailInformation?.content)
      const assignments: IAssignment[] = await getAssignmentsAPI(assignmentsPayload)
      const tableData: IRowData[] = getEditAssignmentTableData(assignments)
      reset({
        [`${fieldName}`]: tableData,
      })
    } catch (error) {
      handleError(error, 'src/containers/Diagram/components/EditAssignModal/index.tsx', 'fetchData')
    }
  }

  useEffect(() => {
    fetchData()
  }, [nodeDetailInformation?.content])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent minWidth={{ base: 'full', lg: '1100px' }} height="700">
            <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
              Edit {isProjectNode ? startCase(EEditAssignModalType.PROJECT) : startCase(EEditAssignModalType.USER)}{' '}
              Assignment
            </ModalHeader>
            <ModalCloseButton cursor={'pointer'} color={textGrey700} background="none" mt="2" />
            <ModalBody overflowY="auto" py={6}>
              <Text color={textGrey800} fontSize="xl" fontWeight={700} lineHeight={7} margin={0}>
                {nodeDetailInformation.title}
              </Text>
              <VStack
                borderRadius="12px"
                border="1px solid"
                borderColor={borderGray200}
                background="white"
                padding={{ base: 4, lg: 3 }}
                spacing={{ base: 4, lg: 3 }}
                marginTop="24px !important">
                <Grid alignItems="center" templateColumns={{ base: 'repeat(7, 1fr) 0.2fr' }} width="full">
                  {getValidArray(editAssignFields).map((field, index) => {
                    return (
                      <StackItem key={index} width={field?.width ?? '141.5px'}>
                        <Text
                          color="gray.700"
                          fontSize="xs"
                          lineHeight={4}
                          fontWeight={600}
                          textAlign="center"
                          boxSizing="border-box"
                          margin={0}
                          padding={2}>
                          {/* INFO: the first label in editAssignFields is User, but if this is edit User modal, we need to
                          change it to PROJECT */}
                          {index === 0 && !isProjectNode ? EEditAssignModalType.PROJECT.toUpperCase() : field?.label}
                        </Text>
                      </StackItem>
                    )
                  })}
                  <StackItem width="48px"></StackItem>
                </Grid>
                {getValidArray(nodeDetailInformation?.content).map((content, index) => {
                  return (
                    <DataRow
                      key={index}
                      content={content}
                      index={index}
                      isProjectNode={isProjectNode}
                      fieldName={fieldName}
                    />
                  )
                })}
              </VStack>
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button variant="outline" onClick={onClose} isLoading={isLoading} customStyles={{ marginRight: '12px' }}>
                Cancel
              </Button>
              <Button type="submit" isLoading={isLoading}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditAssignModal
