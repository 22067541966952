export function getProjectShortName(projectName: string): string {
  const words: string[] = String(projectName)?.split(' ')
  const firstWord: string = words[0]
  const subWord = String(projectName)?.split(' - ')?.[1]?.split(' ')?.[0] ?? ''
  return `${firstWord} ${subWord}`
}

export function truncateText(projectName: string, maxLength: number = 13): string {
  const validatedName = String(projectName)
  if (validatedName.length > maxLength) {
    return `${validatedName.slice(0, maxLength - 3)}...`
  }
  return validatedName
}
