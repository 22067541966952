import { FormControl, FormLabel, FormErrorMessage, Tooltip, Box, HStack, Stack, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import Icon from '../Icon'
import FormInputField from './components/FormInputField'
import { IFormItemProps } from './interfaces'

const FormItem = (props: IFormItemProps) => {
  const {
    name,
    label,
    type,
    placeholder,
    isRequired,
    children,
    helperText,
    helperTextShow,
    readonly,
    rightAddonText,
    disabled,
    isWebsite,
    defaultValue,
    minHeightOfTextarea,
    onBlurHandler,
    id,
    step,
  } = props
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <FormControl isInvalid={!!get(errors, name, false)}>
      <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
        <FormLabel fontWeight={'normal'} color="gray.700" lineHeight={6} marginBottom={2} marginInlineEnd={0}>
          {label}
        </FormLabel>
        {helperTextShow && <Text fontSize="xs">{helperText}</Text>}
        {helperText && !helperTextShow && (
          <Tooltip label={helperText} hasArrow placement="right" background="gray.900" color="white" fontSize="sm">
            <Box textAlign="center" height="20px">
              <Icon iconName="information.svg" size={20} />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Stack>
        {children ? (
          children
        ) : (
          <FormInputField
            step={step}
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            isRequired={isRequired}
            helperText={helperText}
            helperTextShow={helperTextShow}
            readonly={readonly}
            rightAddonText={rightAddonText}
            disabled={disabled}
            isWebsite={isWebsite}
            defaultValue={defaultValue}
            minHeightOfTextarea={minHeightOfTextarea}
            onBlurHandler={onBlurHandler}
          />
        )}
      </Stack>
      <FormErrorMessage>{get(errors, `${name}.message`, '')}</FormErrorMessage>
    </FormControl>
  )
}

export default FormItem
