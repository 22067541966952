import { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/layout'
import { Flex, ButtonGroup, Menu, MenuList, MenuItem, MenuButton } from '@chakra-ui/react'
import { IInternalDocumentItem } from 'API/internalDocument/constants'
import Button from 'components/Button'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import LoadingChakra from 'components/LoadingChakra'
import StaffDocumentList from 'components/StaffDocumentList'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import {
  backgroundBlue600,
  backgroundBlue800,
  backgroundBlueSecondary,
  borderGray200,
  textWhite,
} from 'theme/globalStyles'
import { EConditionGetList, EInternalDocScope, EPartnerCategory } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { useStores } from 'utils/hooks/useStores'
import AddStaffDocumentModal from '../components/AddStaffDocumentModal'
import { IStaffDocumentForm } from '../components/AddStaffDocumentModal/constants'
import StaffDocumentFilterModal from '../components/StaffDocumentFilterModal'
import { IStaffDocumentFilter } from './constants'

const StaffDocument = () => {
  const { userHumanResourcesStore, userStaffDocumentOfHRStore } = useStores()
  const history = useHistory()

  const [isCreateStaffDocumentModalOpen, setIsCreateStaffDocumentModalOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<IStaffDocumentFilter>({
    filteredPartnerCategory: EPartnerCategory.EMPLOYEE,
  })
  const [selectedInternalDocument, setSelectedInternalDocument] = useState<IInternalDocumentItem>()
  const [isDeleteInternalDocumentModalOpen, setIsDeleteInternalDocumentModalOpen] = useState<boolean>(false)
  const [isEditInternalDocumentModalOpen, setIsEditInternalDocumentModalOpen] = useState<boolean>(false)
  const [isFilterStaffDocumentModalOpen, setIsFilterStaffDocumentModalOpen] = useState<boolean>(false)
  const { partnerNameList } = userHumanResourcesStore
  const { staffDocumentList, isLoading } = userStaffDocumentOfHRStore

  function openFilterModalHandler() {
    setIsFilterStaffDocumentModalOpen(true)
  }

  function closeFilterModalHandler() {
    setIsFilterStaffDocumentModalOpen(false)
  }

  function openConfirmDeleteModalHandler(internalDocument: IInternalDocumentItem) {
    setSelectedInternalDocument(internalDocument)
    setIsDeleteInternalDocumentModalOpen(true)
  }

  function closeConfirmDeleteModalHandler() {
    setIsDeleteInternalDocumentModalOpen(false)
  }
  async function deleteStaffDocumentHandler(internalDocumentId: string) {
    await userStaffDocumentOfHRStore.deleteStaffDocument(internalDocumentId)
    closeConfirmDeleteModalHandler()
    await userStaffDocumentOfHRStore.getStaffDocumentListForHR(filter)
  }

  async function updateStaffDocumentHandler(data: IStaffDocumentForm) {
    const newData = {
      ...data,
      partnerId: data.partnerId.value,
    }
    await userStaffDocumentOfHRStore.updateStaffDocument(selectedInternalDocument.id, newData)
    closeEditStaffDocumentModalHandler()
    await userStaffDocumentOfHRStore.getStaffDocumentListForHR(filter)
  }

  function openCreateStaffDocumentModalHandler() {
    setIsCreateStaffDocumentModalOpen(true)
  }
  function closeCreateStaffDocumentModalHandler() {
    setIsCreateStaffDocumentModalOpen(false)
  }
  function openEditStaffDocumentModalHandler(data: IInternalDocumentItem) {
    setSelectedInternalDocument(data)
    setIsEditInternalDocumentModalOpen(true)
  }
  function closeEditStaffDocumentModalHandler() {
    setIsEditInternalDocumentModalOpen(false)
  }

  async function filterStaffDocumentHandler(data: IStaffDocumentFilter) {
    setFilter(data)
    closeFilterModalHandler()
  }

  async function createStaffDocumentHandler(data: IStaffDocumentForm) {
    const formattedData = {
      type: data.type,
      description: data.description,
      scope: EInternalDocScope.INTERNAL,
      publishedDate: data.publishedDate,
      number: data.number,
      storageURL: data.storageURL,
      partnerId: `${data?.partnerId?.value}`,
    }
    await userStaffDocumentOfHRStore.createStaffDocument(formattedData)
    closeCreateStaffDocumentModalHandler()
    await userStaffDocumentOfHRStore.getStaffDocumentListForHR(filter)
  }
  function openCreateContractHandler() {
    history.push(frontendRoutes.contractPage.create.value)
  }
  useEffect(() => {
    userHumanResourcesStore.getPartnerNameList(EConditionGetList.ACTIVE)
  }, [])
  useEffect(() => {
    userStaffDocumentOfHRStore.getStaffDocumentListForHR(filter)
  }, [filter])

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Button variant="outline" onClick={openFilterModalHandler} leftIcon="filter.svg">
          Filter
        </Button>
        <ButtonGroup isAttached variant="solid" borderRadius="6px">
          <Button onClick={openCreateStaffDocumentModalHandler}>Add Staff Document</Button>
          <Menu>
            <MenuButton
              cursor="pointer"
              borderLeft={`1px solid ${borderGray200}`}
              borderRadius="6px"
              backgroundColor={backgroundBlueSecondary}
              color={textWhite}
              _hover={{ backgroundColor: backgroundBlue600 }}
              _active={{ backgroundColor: backgroundBlue800 }}>
              <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem cursor="pointer" onClick={openCreateContractHandler}>
                Create contract
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Flex>
      <Box paddingY="6">
        <LoadingChakra isLoading={isLoading}>
          <StaffDocumentList
            dataGroupsByMonth={staffDocumentList}
            openConfirmDeleteModal={openConfirmDeleteModalHandler}
            openEditModal={openEditStaffDocumentModalHandler}
          />
        </LoadingChakra>
        <AddStaffDocumentModal
          isOpenModal={isCreateStaffDocumentModalOpen}
          partnerNameList={partnerNameList}
          saveHandler={createStaffDocumentHandler}
          closeHandler={closeCreateStaffDocumentModalHandler}
        />
        <AddStaffDocumentModal
          selectedItem={selectedInternalDocument}
          isEdit={true}
          saveHandler={updateStaffDocumentHandler}
          isOpenModal={isEditInternalDocumentModalOpen}
          partnerNameList={partnerNameList}
          closeHandler={closeEditStaffDocumentModalHandler}
        />
        <ConfirmYesNoDeleteModal
          isOpen={isDeleteInternalDocumentModalOpen}
          title="Staff Document"
          closeHandler={closeConfirmDeleteModalHandler}
          OKClickHandler={deleteStaffDocumentHandler}
          itemId={selectedInternalDocument?.id}
        />
        <StaffDocumentFilterModal
          isOpen={isFilterStaffDocumentModalOpen}
          isLoading={isLoading}
          closeHandler={closeFilterModalHandler}
          submitHandler={filterStaffDocumentHandler}
        />
      </Box>
    </Box>
  )
}

export default observer(StaffDocument)
