/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { VStack, Stack, Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import ConfirmYesNoModal from 'components/ConfirmYesNoModal'
import BadgeImage from 'components/LabelForm/BadgeImage'
import LoadingChakra from 'components/LoadingChakra'
import difference from 'lodash/difference'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IOption } from 'types/creatableOption'
import { IRowActionItem } from 'types/table'
import { IProfileDetailOfDetailForm } from 'types/user'
import { EConditionGetList, EYesNoOptionWithCapitalization } from 'constants/enum'
import { Messages, actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getUserStatus } from 'utils/user'
import UnarchiveUserModal from '../components/UnarchiveUserModal'
import CurriculumViate from './components/CurriculumVitae'
import DetailUserForm from './components/DetailUserForm'
import EditUserForm from './components/EditUserForm'
import HeaderSection from './components/HeaderSection'
import LaborContract from './components/LaborContract'
import MenuDetail from './components/MenuDetail'
import ProjectRating from './components/ProjectRating'
import SkillAnalysis from './components/SkillAnalysis'
import UserAvatar from './components/UserAvatar'
import UserData from './components/UserData'

const UserDetail = () => {
  const title: string = 'User Detail'
  window.sessionStorage.setItem('subPageTitle', title)

  window.dispatchEvent(new Event('storage'))
  const isFirstTimeUpdateUser = window.localStorage.getItem('isFirstTimeUpdateUser')
  const methods = useForm()
  const { handleSubmit, reset, setValue } = methods
  const params = useParams()
  const history = useHistory()
  const userId = get(params, 'id', '')
  const { adminUserStore, adminProjectStore, adminPartnerStore } = useStores()
  const { projectNameList, creatableOptionList, userDetail, technologyList } = adminUserStore
  const { partnerNameList } = adminPartnerStore
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [isOpenUnarchiveModal, setIsOpenUnarchiveModal] = useState(false)
  const [isOpenActiveModal, setIsOpenActiveModal] = useState(false)
  const [isOpenConfirmYesNoModal, setIsOpenConfirmYesNoModal] = useState(false)
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function getInformationOfUserDetail(): void {
    setIsLoading(true)
    adminUserStore.getOptionList()
    adminUserStore.getTechnologyList()
    adminPartnerStore.getAllNamePartnerList(EConditionGetList.ACTIVE)
    adminUserStore.getProjectNameList(EConditionGetList.ACTIVE, () => {
      adminUserStore.getUserDetailV2(userId, isEdit)
    })
  }

  useEffect(getInformationOfUserDetail, [isEdit, userId])
  useEffect(() => {
    if (userDetail) {
      reset({
        ...userDetail,
        profileDetailData: {
          ...userDetail?.profileDetailData,
          IDIssuePlace: {
            label: userDetail?.profileDetailData?.IDIssuePlace ?? '',
            value: userDetail?.profileDetailData?.IDIssuePlace ?? '',
          },
          levelId: {
            label: <BadgeImage data={userDetail?.profileDetailData?.level} />,
            value: userDetail?.profileDetailData?.level?._id,
          },
          titleId: {
            label: <BadgeImage data={userDetail?.profileDetailData?.title} />,
            value: userDetail?.profileDetailData?.title?._id,
          },
        },
      })
      setIsLoading(false)
    }
  }, [userDetail])

  async function submitHandler(data) {
    window.localStorage.setItem('isFirstTimeUpdateUser', EYesNoOptionWithCapitalization.NO)
    setIsEdit(false)
    const needToSendWelcomeEmail = data.needToSendWelcomeEmail
    await adminUserStore.updateUser(data, userId, needToSendWelcomeEmail)
    await adminUserStore.getUserDetailV2(userId, false)
  }

  async function updateProjectRating(newProjects) {
    try {
      const projectIdArray = getValidArray(newProjects).map((project: IOption) => project.value)
      const beforeProjects = userDetail.profileDetailData.projects
      const beforeProjectsIdArray = getValidArray(beforeProjects).map((project: IOption) => project.value)
      let projectsNotAdded = []
      if (beforeProjectsIdArray.length > projectIdArray.length) {
        projectsNotAdded = difference(beforeProjectsIdArray, projectIdArray)
      }
      await adminUserStore.updateProjectRatings(userId, projectIdArray, projectsNotAdded)
    } catch (error) {
      toast.error('Update project rating failed')
    }
  }
  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }
  function toggleOpenUnarchiveModal() {
    setIsOpenUnarchiveModal(!isOpenUnarchiveModal)
  }
  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }
  function toggleOpenActiveModal() {
    setIsOpenActiveModal(!isOpenActiveModal)
  }
  function toggleConfirmYesNoModal() {
    setIsOpenConfirmYesNoModal(!isOpenConfirmYesNoModal)
  }
  function callOpenDialogConfirm(user: IProfileDetailOfDetailForm, nameOfEvent: string) {
    set(user, 'shortName', user?.fullName ?? '')
    switch (nameOfEvent) {
      case actionOnDataTable.DELETE:
        toggleOpenDeleteModal()
        break
      case actionOnDataTable.EDIT:
        console.log('The future is coming soon')
        break
      case actionOnDataTable.ARCHIVE:
        if (user?.isArchived) {
          adminProjectStore.getRemovedProjectsListOfUser(user?.id ?? '')
          toggleOpenUnarchiveModal()
          break
        }
        toggleOpenArchiveModal()
        break
      case actionOnDataTable.ACTIVE:
        toggleOpenActiveModal()
        break
      default:
        toast.error('Action not found')
    }
  }
  async function handleClickOKConfirmedDelete() {
    await adminUserStore.deleteUser(userId)
    history.push(frontendRoutes.userPage.list.value)
  }
  async function handleConfirmInfo(selectedProjectIds?: string[]) {
    setSelectedProjectIds(selectedProjectIds ?? [])
    toggleOpenUnarchiveModal()
    toggleConfirmYesNoModal()
  }
  async function handleClickOKOfUnarchiveConfirm() {
    await adminUserStore.unArchivedUser(userDetail.profileDetailData.id, selectedProjectIds)
    toggleConfirmYesNoModal()
    adminUserStore.getUserListWithPagination(history)
    history.push(frontendRoutes.userPage.list.value)
  }
  async function handleClickOKOfArchiveConfirm() {
    await adminUserStore.archivedUser(userId)
    history.push(frontendRoutes.userPage.list.value)
  }
  async function handleClickOKOfActiveConfirm() {
    await adminUserStore.activeUser(userId)
    toggleOpenActiveModal()
    adminUserStore.getUserListWithPagination(history)
    history.push(frontendRoutes.userPage.list.value)
  }

  async function handleClickOkOfDeactivateConfirm() {
    await adminUserStore.deactivateUser(userId)
    toggleOpenActiveModal()
    adminUserStore.getUserListWithPagination(history)
    history.push(frontendRoutes.userPage.list.value)
  }
  function editHandler() {
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }

  const actions: IRowActionItem[] = []
  if (!userDetail?.profileDetailData?.isDeleted) {
    actions.push(
      {
        name: userDetail?.profileDetailData?.isArchived ? 'Unarchive User' : 'Archive User',
        handler: () => callOpenDialogConfirm(userDetail?.profileDetailData, actionOnDataTable.ARCHIVE),
      },
      {
        name: userDetail?.profileDetailData.isActive === 'Yes' ? 'Deactivate User' : 'Activate User',
        handler: () => callOpenDialogConfirm(userDetail?.profileDetailData, actionOnDataTable.ACTIVE),
      },
      {
        name: 'Delete User',
        customClassNames: ['isDanger'],
        handler: () => callOpenDialogConfirm(userDetail?.profileDetailData, actionOnDataTable.DELETE),
      }
    )
  }

  return (
    <LoadingChakra isLoading={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => submitHandler(data))}>
          <VStack gap="12px">
            {isFirstTimeUpdateUser === EYesNoOptionWithCapitalization.YES && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>{Messages.updateUserAlertTitle}</AlertTitle>
                <AlertDescription>{Messages.updateUserAlertDescription}</AlertDescription>
              </Alert>
            )}
            <HeaderSection
              status={getUserStatus(userDetail?.profileDetailData)}
              fullName={userDetail?.profileDetailData?.fullName}
              isDisabledOptions={userDetail?.profileDetailData?.isDeleted}
              isEdit={isEdit}
              handleEdit={editHandler}
              handleCancel={cancelHandler}
              actions={actions}
            />
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              width="full"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="12px">
              <VStack gap="12px" width={{ base: 'full', lg: '80%' }}>
                <EditUserForm
                  isEdit={isEdit}
                  userId={userId}
                  partnerList={partnerNameList}
                  projectList={projectNameList}
                  creatableOptionList={creatableOptionList}
                  methods={methods}
                  technologyList={technologyList}
                  updateProjectRatingHandler={updateProjectRating}
                  name="profileDetailData"
                  isFirstTimeUpdateUser={isFirstTimeUpdateUser}
                />
                <DetailUserForm methods={methods} name="profileDetailData" isEdit={isEdit} />
                <ProjectRating />
                <SkillAnalysis />
                <LaborContract />
                <UserData name="profileDetailData.userData" />
                <CurriculumViate
                  name="profileDetailData.cv"
                  userId={userId}
                  isAllowedDownloadCV={userDetail?.profileDetailData?.allowToDownloadCV}
                />
              </VStack>
              <VStack
                width={{ base: 'full', lg: '20%' }}
                height="100%"
                gap="12px"
                style={{ position: 'sticky', top: 90 }}>
                <UserAvatar
                  type="avatar"
                  name="profileDetailData.avatar"
                  title="User profile picture"
                  isDetail={!isEdit}
                  afterUploadHandler={(value) => {
                    setValue('profileDetailData.avatar', value)
                  }}
                />
                <MenuDetail />
              </VStack>
            </Stack>
          </VStack>
        </form>
        <ConfirmModal
          data={userDetail?.profileDetailData}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'User'}
          content={'Full Name'}
          type={EModalType.DELETE}
        />
        <ConfirmModal
          data={userDetail?.profileDetailData}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={handleClickOKOfArchiveConfirm}
          title={'User'}
          content={'Full Name'}
          type={EModalType.ARCHIVE}
        />
        <ConfirmModal
          data={userDetail?.profileDetailData}
          isOpen={isOpenActiveModal}
          closeHandler={toggleOpenActiveModal}
          OKClickHandler={
            userDetail?.profileDetailData?.isActive === 'Yes'
              ? handleClickOkOfDeactivateConfirm
              : handleClickOKOfActiveConfirm
          }
          title={'User'}
          content={'Full Name'}
          type={EModalType.ACTIVE}
        />
        <UnarchiveUserModal
          isOpen={isOpenUnarchiveModal}
          closeHandler={toggleOpenUnarchiveModal}
          saveHandler={handleConfirmInfo}
        />
        <ConfirmYesNoModal
          isOpen={isOpenConfirmYesNoModal}
          title="Confirm Unarchive User"
          actionText="Are you sure you want to unarchive this user?"
          closeHandler={toggleConfirmYesNoModal}
          OKClickHandler={handleClickOKOfUnarchiveConfirm}
        />
      </FormProvider>
    </LoadingChakra>
  )
}
export default observer(UserDetail)
