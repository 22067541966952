import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { citizenIssuePlaceOptions, genderVIOptions, jobTitleVIOptions } from 'constants/defaultOptionList'
import styles from '../../../contract.module.scss'

const VNInformation = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        Vietnamese Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="addressVI" label="Địa chỉ thường trú (optional)" />
        <SingleSelect name="genderVI" label="Giới tính (optional)" optionsData={genderVIOptions} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <SingleSelect
          placeHolder="- Chọn nơi cấp CMND/CCCD -"
          name="IDIssuePlaceVI"
          label="Nơi cấp CCCD/CMND (optional)"
          optionsData={citizenIssuePlaceOptions}
          isCreatable
        />
        <SingleSelect
          placeHolder="- Chức vụ -"
          name="jobTitleVI"
          label="Chức vụ"
          optionsData={jobTitleVIOptions}
          isCreatable
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput
          name="publishedDateInfoVI"
          label="Thời gian và địa điểm ký văn bản (optional). (Ex: TP. Hồ Chí Minh, ngày 2 tháng 3 năm 2024)"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="educationLevelVI" label="Trình độ văn hóa (optional)" />
        <FormInput name="majorVI" label="Chuyên ngành (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="bankName" label="Ngân hàng nhận tiền" />
        <FormInput name="bankAccount" label="Số tài khoản ngân hàng" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default VNInformation
