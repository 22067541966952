import { useEffect } from 'react'
import {
  Stack,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Text,
  SimpleGrid,
  FormLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { getFormattedDateWith_YYYYMMDD, handleDatePickerChange } from 'utils/dateFormatUtils'
import styles from '../../../../index.module.scss'
import { IHourlyProjectRatingModal } from './interfaces'

const HourlyProjectRatingModal = (props: IHourlyProjectRatingModal) => {
  const { isOpen, closeHandler, saveHandler, data } = props
  const methods = useForm()
  const { reset, handleSubmit } = methods
  const paymentCategoryFromClient = data?.paymentCategoryFromClientOfProject
  useEffect(() => {
    if (data) {
      const parsedData = {
        ...data,
        currentApplyDate: getFormattedDateWith_YYYYMMDD(data.currentApplyDate),
        futureApplyDate: getFormattedDateWith_YYYYMMDD(data.futureApplyDate),
      }
      reset(parsedData)
    }
  }, [data])

  function renderPaymentCategoryFromClientInputField(paymentType: string) {
    switch (paymentType) {
      case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
        return <FormInput name="clientRate" label="Client Rate" type="number" step={0.01} />
      case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
        return <FormInput name="billableMonthlyAmount" label="Billable Monthly Amount" type="number" step={0.01} />

      default:
        return ''
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="2xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form className="full-form" onSubmit={handleSubmit((data) => saveHandler(data))}>
          <ModalContent maxHeight="620px" position="fixed">
            <Box background="white" width="full">
              <ModalHeader>Edit User Project Ratings</ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
            </Box>
            <ModalBody overflowY="auto">
              <FormControl>
                <FormInput name="projectName" label="Project" disabled={true} />
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 2 }}
                  gap={{ base: 4, lg: 6 }}
                  width="full"
                  marginTop={'20px'}>
                  <FormInput
                    name="paymentCategoryFromClientOfProject"
                    label="Payment Category From Client"
                    disabled={true}
                  />
                  {renderPaymentCategoryFromClientInputField(paymentCategoryFromClient)}
                </SimpleGrid>
                <Stack direction={{ sm: 'row', md: 'column', xl: 'column' }}>
                  <Box width={{ sm: '50%', md: 'full' }}>
                    <Text className={`${styles.headerBox} ${styles.border}`}>Current</Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} gap={{ base: 4, lg: 6 }} width="full">
                      <FormInput name="currentHourlyRate" label="Hourly Rate" type="number" step={0.01} />
                      <FormControl>
                        <FormLabel fontWeight="400">Apply Date</FormLabel>
                        <Controller
                          name="currentApplyDate"
                          control={methods.control}
                          render={({ field }) => (
                            <DatePicker
                              selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                              onChange={(date) => handleDatePickerChange(field, date)}
                              disabled={true}
                            />
                          )}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                  <Box width={{ sm: '50%', md: 'full' }}>
                    <Text className={`${styles.headerBox} ${styles.border}`}>Future</Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} gap={{ base: 4, lg: 6 }} width="full">
                      <FormInput name="futureHourlyRate" label="Hourly Rate" type="number" step={0.01} />
                      <FormControl>
                        <FormLabel fontWeight="400">Apply Date</FormLabel>
                        <Controller
                          name="futureApplyDate"
                          control={methods.control}
                          render={({ field }) => (
                            <DatePicker
                              selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                              onChange={(date) => handleDatePickerChange(field, date)}
                            />
                          )}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                </Stack>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" customStyles={{ marginRight: '10px' }} onClick={closeHandler}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="red">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(HourlyProjectRatingModal)
