export const partner = {
  value: '/partner',
  create: {
    value: '/partner/create',
  },
  list: {
    value: '/partner/list',
  },
  listWithFilter: {
    value: '/partner/list-with-filter',
  },
  nameListForAdmin: {
    value: '/partner/name-list-for-admin',
    condition: {
      value: (condition: string) => `/partner/name-list-for-admin/${condition}`,
    },
  },
  nameListForHR: {
    value: '/partner/name-list-for-hr',
    condition: {
      value: (condition: string) => `/partner/name-list-for-hr/${condition}`,
    },
  },
  detail: {
    value: `/partner/detail`,
    partnerId: {
      value: (partnerId: string) => `/partner/detail/${partnerId}`,
    },
  },
  update: {
    value: `/partner/update`,
    partnerId: {
      value: (partnerId: string) => `/partner/update/${partnerId}`,
    },
  },
  getUserData: {
    value: '/partner/get-user-data/',
    partnerId: {
      value: (partnerId: string) => `/partner/get-user-data/${partnerId}`,
    },
  },
}
