import { useState, useEffect } from 'react'
import { VStack, SimpleGrid, Container, Divider } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import SingleSelect from 'components/SingleSelect'
import SingleTag from 'components/SingleTag'
import { EPartnerCategory, EPartnerType, EYesNoOption } from 'constants/enum'

const CreatePartnerForm = (props) => {
  const { partnersType, patnerCategoryOptions, titleOptions, levelOptions, paymentCategoryOptions, methods } = props
  const { watch } = methods

  const title: string = 'New Partner'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))

  const createUserOption = ['yes', 'no']
  const type = watch('type')
  const category = watch('category')
  const isCreateUser = watch('isCreateUser')
  const [toggleCreateUser, setToggleCreateUser] = useState(false)
  const [toggleAddFieldsOfUser, setToggleAddFieldsOfUser] = useState(false)

  useEffect(
    function handleToggleCreateUser() {
      if (
        type === EPartnerType.PERSONAL &&
        (category?.value === EPartnerCategory.FREELANCER || category?.value === EPartnerCategory.EMPLOYEE)
      ) {
        setToggleCreateUser(true)
      } else {
        setToggleCreateUser(false)
      }
    },
    [type, category?.value]
  )

  useEffect(
    function handleToggleFields() {
      setToggleAddFieldsOfUser(isCreateUser === EYesNoOption.NO || isCreateUser === undefined ? false : true)
    },
    [isCreateUser]
  )

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack background="white" padding={{ base: 6, lg: 6 }} borderRadius="8px">
        <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="fullName" label="Full Name" isRequired />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name="type" data={partnersType} defaultValue={EPartnerType.ORGANIZATIONS} label="Partner Type" />
          <SingleSelect
            name="category"
            label="Partner Category"
            placeHolder="- Select partner category -"
            optionsData={patnerCategoryOptions}
            isRequire
          />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="phone" label="Phone Number (Optional)" />
          <FormInput name="address" label="Address" isRequired />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput
            name="email"
            isRequired={toggleCreateUser}
            label={`Email ${toggleCreateUser ? '' : '(Optional)'}`}
          />
          <FormInput name="website" isWebsite label="Website (Optional)" />
          <FormInput name="taxCode" label="Tax Code (Optional)" />
          <FormInput name="billingAddress" label="Billing Address (Optional)" />
        </SimpleGrid>

        {toggleCreateUser && (
          <>
            <Divider color={'gray/200'} />
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="isCreateUser" data={createUserOption} defaultValue="no" label="Create user" />
            </SimpleGrid>
          </>
        )}
        {toggleAddFieldsOfUser && (
          <>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <VStack>
                <SingleSelect
                  placeHolder="- Select Title -"
                  name="titleId"
                  label="Title"
                  isRequire
                  optionsData={titleOptions}
                  containerStyle={{ lineHeight: '0px' }}
                />
                <VStack alignSelf="flex-start">
                  <SingleTag fieldName="titleId" />
                </VStack>
              </VStack>
              <VStack>
                <SingleSelect
                  placeHolder="- Select Level -"
                  name="levelId"
                  label="Level"
                  isRequire
                  optionsData={levelOptions}
                  containerStyle={{ lineHeight: '0px' }}
                />
                <VStack alignSelf="flex-start">
                  <SingleTag fieldName="levelId" />
                </VStack>
              </VStack>
              <SingleSelect
                name="paymentCategoryId"
                label="Partner Category *"
                placeHolder="- Select Payment category -"
                optionsData={paymentCategoryOptions}
                isRequire
              />
            </SimpleGrid>
          </>
        )}
      </VStack>
    </Container>
  )
}

export default CreatePartnerForm
