import { Search2Icon } from '@chakra-ui/icons'
import { HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'

interface IHeaderSectionProps {
  toggleOpen: () => void
  changeName: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const history = useHistory()
  const { toggleOpen, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const initialFilter = queryString.parse(history.location.search)
  const name = initialFilter?.normalizedFullName

  return (
    <HStack marginBottom={6}>
      <InputGroup
        border={`1px solid ${backgroundGrey200}`}
        borderRadius="6px"
        background="white"
        width={isMobile ? '160px' : '400px'}>
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.400" />
        </InputLeftElement>
        <Input
          type="search"
          placeholder={isMobile ? 'Search' : 'Search Users by name...'}
          onChange={changeName}
          border="none"
          defaultValue={name}
        />
      </InputGroup>
      <Button leftIcon="filter.svg" variant="outline" onClick={toggleOpen}>
        {!isMobile && 'Filter'}
      </Button>
    </HStack>
  )
}

export default HeaderSection
