import { handleError } from 'API/error'
import { getLeaveTypeListAPI } from 'API/leaveType'
import { makeAutoObservable } from 'mobx'
import { ILeaveType } from 'types/leaveRequest'
import RootStore from '../rootStore'

class LeaveTypeStore {
  rootStore: RootStore
  userLeaveTypeList: ILeaveType[]
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getUserLeaveTypeList() {
    try {
      this.isLoading = true
      const { data } = await getLeaveTypeListAPI()
      this.userLeaveTypeList = data.leaveRequestTypes
    } catch (error) {
      handleError(error, 'src/store/user/leaveTypeStore.ts', 'getUserLeaveTypeList')
    } finally {
      this.isLoading = false
    }
  }
}

export default LeaveTypeStore
