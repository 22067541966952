import { ITableHeader } from 'types/table'

export const headerListMobile: ITableHeader[] = [
  {
    Header: 'Start Date - End Date',
    accessor: 'timeRange',
  },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actionsAccepted',
  },
  {
    Header: '',
    accessor: 'actionsRejected',
  },
]

export const headerListDesktop: ITableHeader[] = [
  {
    Header: 'Created Date',
    accessor: 'dateCreated',
  },
  {
    Header: 'Start Date - End Date',
    accessor: 'timeRange',
  },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Approval Authority',
    accessor: 'approver',
  },
  {
    Header: 'Leave Type',
    accessor: 'leaveType',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actionsCommented',
  },
  {
    Header: '',
    accessor: 'actionsAccepted',
  },
  {
    Header: '',
    accessor: 'actionsRejected',
  },
  ,
]

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerListMobile : headerListDesktop
}
