import { ITableHeader } from 'types/table'

export const headerListMobile: ITableHeader[] = [
  {
    Header: 'Start date - End Date',
    accessor: 'timeRangeMobile',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'mobileActions',
  },
]

export const headerListDesktop: ITableHeader[] = [
  {
    Header: 'Created Date',
    accessor: 'dateCreated',
  },
  {
    Header: 'Start Date - End Date',
    accessor: 'timeRange',
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Leave Type',
    accessor: 'leaveType',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'desktopActions',
  },
]

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerListMobile : headerListDesktop
}
