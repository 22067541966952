import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import Button from 'components/Button'
import { isInputCorrect } from 'utils/commonUtils'
import { EModalType } from './enums'

export interface IConfirmArchiveModal {
  data?: any
  isOpen?: boolean
  isLoading?: boolean
  title?: string
  content?: string
  closeHandler?: () => void
  type: EModalType
  OKClickHandler?: (id: string) => void
}

export function renderModalHeaderContent(type: EModalType, title: string, data: any): string {
  switch (type) {
    case EModalType.ARCHIVE:
      return data?.isArchived ? `Unarchive ${title}` : `Archive ${title}`
    case EModalType.ACTIVE:
      return data?.isActive ? `Deactive ${title}` : `Active ${title}`
    case EModalType.DELETE:
      return `Delete ${title}`
    default:
      return ''
  }
}

export function renderConfirmMessage(type: EModalType, title: string, data: any): string {
  switch (type) {
    case EModalType.ARCHIVE:
      return data?.isArchived ? `Are you sure to unarchive this ${title}?` : `Are you sure to archive this ${title}?`
    case EModalType.ACTIVE:
      return data?.isActive ? `Are you sure to deactive this ${title}?` : `Are you sure to active this ${title}?`
    case EModalType.DELETE:
      return `Are you sure you want to delete this ${title}? You can’t undo this action afterwards.`
    default:
      return ''
  }
}

const ConfirmModal = (props: IConfirmArchiveModal) => {
  const { isOpen, title, content, closeHandler, OKClickHandler, data, type, isLoading } = props
  const [isCorrectName, setCorrectName] = useState<boolean>(false)

  useEffect(() => {
    setCorrectName(false)
  }, [isOpen])

  function inputOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = event.target.value
    setCorrectName(isInputCorrect(inputValue, data?.shortName || data?.value || data?.name || ''))
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent height={'fit-content !important'}>
        <ModalHeader>{renderModalHeaderContent(type, title, data)}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
        <ModalBody overflowY="auto">
          <Text marginTop="0">{renderConfirmMessage(type, title, data)}</Text>
          <FormControl>
            <FormLabel fontWeight={'normal'}>
              Please type <b>{data?.shortName || data?.value || data?.name || ''}</b> in the <b>{content}</b> to
              confirm.
            </FormLabel>
            <Input autoFocus={true} placeholder={content} onChange={inputOnChange} width={'90%'} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: 3 }} onClick={closeHandler}>
            Cancel
          </Button>
          {type === EModalType.DELETE && (
            <Button
              isLoading={isLoading}
              variant="solid"
              colorScheme="red"
              disabled={!isCorrectName}
              onClick={() => OKClickHandler(data._id || data.id)}>
              Delete
            </Button>
          )}
          {!(type === EModalType.DELETE) && (
            <Button
              variant="solid"
              disabled={!isCorrectName}
              isLoading={isLoading}
              onClick={() => OKClickHandler(data._id)}>
              OK
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
