import { ILeaveRequest, ILeaveRequestFilterData } from 'types/leaveRequest'
import { getFormattedDateWith_DDMMYYYY, getFormattedTimeDate } from 'utils/dateFormatUtils'
import { getValueFromOption } from 'utils/dropdownOptionUtils'
import { ELeaveRequestStatus } from '../../../constants/enum'

export function convertLeaveRequestsToTableFormat(leaveRequests: ILeaveRequest[]) {
  return leaveRequests.map((leaveRequest: ILeaveRequest) => {
    let approverName = ''
    if (leaveRequest.status === ELeaveRequestStatus.APPROVED || leaveRequest.status === ELeaveRequestStatus.REJECTED) {
      approverName = leaveRequest.decidedBy
    }

    return {
      id: leaveRequest.id,
      dateCreated: getFormattedDateWith_DDMMYYYY(leaveRequest.createdAt),
      timeRange:
        getFormattedTimeDate(leaveRequest.startTime ?? '') + '\n- ' + getFormattedTimeDate(leaveRequest.endTime ?? ''),
      user: leaveRequest.createdBy,
      approver: approverName,
      tagColor: leaveRequest.tagColor,
      leaveType: leaveRequest.leaveType,
      status: leaveRequest.status,
      commitTime: leaveRequest.commitTime,
      reason: leaveRequest.reason,
      remainLeaveDays: leaveRequest.remainLeaveDays,
      comment: leaveRequest.comment,
      commentBy: leaveRequest.commentBy,
      commentAt: leaveRequest.commentAt,
    }
  })
}

export function formatFilterData(data: ILeaveRequestFilterData) {
  const userId: string[] = getValueFromOption(data.userId)
  const selectedStatusOptions = data?.status?.filter((status) => status.checked === true)
  const status: string[] = getValueFromOption(selectedStatusOptions)

  return {
    userId,
    status,
  }
}
