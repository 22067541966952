import { Box } from '@chakra-ui/layout'
import { ModalHeader, ModalCloseButton } from '@chakra-ui/modal'
import { borderGray200 } from 'theme/globalStyles'
import { ILeaveRequestModalHeaderProps } from './interface'

const LeaveRequestModalHeader = (props: ILeaveRequestModalHeaderProps) => {
  const { isEdit = false, onClose } = props
  return (
    <Box background="white" width="full">
      <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
        {isEdit && 'Edit leave request'}
        {!isEdit && 'Create leave request'}
      </ModalHeader>
      <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" onClick={onClose} />
    </Box>
  )
}

export default LeaveRequestModalHeader
