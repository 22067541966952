import { Flex, HStack, useMediaQuery, Text, Box } from '@chakra-ui/react'
import Button from 'components/Button'
import { maxMobileSize } from 'constants/common'
import Icon from 'components/Icon'

interface IHeaderSectionProps {
  handleCreate: () => void
  toggleOpen: () => void
  remainLeaveDays: number
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { handleCreate, toggleOpen, remainLeaveDays } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  return (
    <Box >
      <Flex
        justifyContent="space-between"
        alignItems='center'
        flexWrap="wrap"
        paddingX={isMobile ? 4 : 0}
        paddingTop={isMobile ? 4 : 0}
        height={isMobile ? 10 : 'auto'}>
        <HStack>
          {!isMobile && (
            <HStack>
              <Icon iconName="information-blue-fill-circle.svg" size={19} />
              <Text margin="0 0 0 5px">
                Your remaining leave days: <b>{remainLeaveDays} days</b>
              </Text>
            </HStack>
          )}
          <Button leftIcon="filter.svg" variant="outline" onClick={toggleOpen}>
            {!isMobile && 'Filter'}
          </Button>
        </HStack>
        <HStack>
          <Button variant="solid" leftIcon="add-white.svg" onClick={handleCreate}>
            {!isMobile && 'Create leave request'}
          </Button>
        </HStack>
      </Flex>

      {isMobile && (
        <HStack paddingX={isMobile ? 4 : 0} paddingTop={isMobile ? 4 : 0}>
          <Icon iconName="information-blue-fill-circle.svg" size={19} />
          <Text margin="0 0 0 5px">
            Your remaining leave days: <b>{remainLeaveDays} days</b>
          </Text>
        </HStack>
      )}
    </Box>
  )
}

export default HeaderSection
