import React from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Input, InputGroup, InputLeftElement, Tooltip } from '@chakra-ui/react'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { getInternalDocumentsAPI, updateInternalDocumentAPI } from 'API/internalDocument'
import { IInternalDocumentFilter, IInternalDocuments } from 'API/internalDocument/constants'
import { getPartnersAPI } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import Button from 'components/Button'
import ConfirmDialogContent from 'components/ConfirmDialogContent'
import FormModel from 'components/FormModel'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import identity from 'lodash/identity'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import pickBy from 'lodash/pickBy'
import { generatePath } from 'react-router'
import { IRowActionItem } from 'types/table'
import { maxTabletSize } from 'constants/common'
import { ECreatableOptionScope } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { IInternalDocument } from 'constants/schema'
import { getValidArray, getInQuery } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { getValueFromOption } from 'utils/dropdownOptionUtils'
import { EventName, Messages } from '../../../constants'
import FilterForm from '../FilterForm'
import {
  headerDesktop,
  headerTabletAndMobile,
  IDocumentFilter,
  initialValues,
  Props,
  States,
  validation,
} from './constant'
import styles from './internalDocumentList.module.scss'

class InternalDocumentList extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {
      internalDocuments: [],
      count: 0,
      open: false,
      exportNow: false,
      filter: initialValues,
      isLoading: false,
    }
  }
  isTablet: boolean = window.matchMedia(maxTabletSize).matches
  componentDidMount() {
    window.dispatchEvent(new Event('storage'))
    this.getListInternalDocumentsHandler()
    this.getListPartnersHandler()
    this.getListDoctypesHandler()
  }
  componentDidUpdate(_: Props, prevState: States) {
    if (prevState?.filter !== this.state?.filter) {
      this.getListInternalDocumentsHandler(true)
    }
  }
  filterSubmitHandler = (value: IDocumentFilter) => {
    this.setState({
      filter: {
        ...value,
        partnerId: getValueFromOption(value.partnerId),
        typeId: getValueFromOption(value.typeId),
        scope: getValueFromOption(value.scope),
      },
    })
  }
  toggleOpen = () => this.setState({ open: !this.state.open })
  handleCreate = () => this.props.history.push(frontendRoutes.internalDocument.create.value)
  toggleExport = () => this.setState({ exportNow: !this.state.exportNow })
  getListPartnersHandler = async () => {
    try {
      const response: ISuccessResponse<IPartnerListResponse> = await getPartnersAPI({ filter: { isDeleted: false } })
      this.setState({
        partners: response?.data?.partners,
      })
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'getListPartnersHandler')
    }
  }
  getListDoctypesHandler = async () => {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: { scope: ECreatableOptionScope.INTERNAL_DOCUMENT },
      })
      this.setState({
        doctypes: response?.data?.creatableOptions,
      })
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'getListDoctypesHandler')
    }
  }
  getListInternalDocumentsHandler = async (isReset?: boolean) => {
    try {
      this.setState({ isLoading: true })
      const { filter } = this.state
      const { partnerId, typeId, scope } = filter
      const currentPage: number = isReset ? 1 : Number(get(this.props, 'history.location.state.page', 1) || 1)
      const formattedFilter: IInternalDocumentFilter = {
        ...pickBy(filter, identity),
        partnerId: getInQuery(partnerId),
        typeId: getInQuery(typeId),
        scope: getInQuery(scope),
      }
      const truthyFilter: IInternalDocumentFilter = omitBy(formattedFilter, isNil)
      const response: IInternalDocuments = await getInternalDocumentsAPI({
        currentPage,
        perPage: 8,
        filter: truthyFilter,
      })
      this.setState({
        internalDocuments: response?.internalDocuments,
        count: response?.count,
      })
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'getListInternalDocumentsHandler')
    } finally {
      this.setState({ isLoading: false })
    }
  }
  updateInternalDocument = async (id: string, internalDocument: IInternalDocument) => {
    try {
      await updateInternalDocumentAPI(id, internalDocument)
      this.getListInternalDocumentsHandler()
      this.props.handleCloseConfirmDialog()
      const { isDeleted = false } = internalDocument
      if (isDeleted) {
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.deleteInternalDocumentSuccess })
      }
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'updateInternalDocument')
    }
  }
  inputTargetInternalDocumentNameHandler = (event, shortName: string) => {
    if (event.target.value === shortName.toString()) {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeleteInternalDocumentConfirmDialog, { detail: { isCorrectName: true } })
      )
    } else {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeleteInternalDocumentConfirmDialog, { detail: { isCorrectName: false } })
      )
    }
  }
  handleClickOKConfirmedDelete = (id: string) => {
    this.updateInternalDocument(id, { isDeleted: true })
  }

  callOpenDialogConfirm = (internalDocument: IInternalDocument, nameOfEvent: string) => {
    let content: React.ReactNode
    let OKClickHandler: (id: string) => void
    if (nameOfEvent === 'delete') {
      content = (
        <ConfirmDialogContent
          data={{ shortName: internalDocument.number }}
          inputDeleteDialogChangeHandler={this.inputTargetInternalDocumentNameHandler}
        />
      )
      OKClickHandler = this.handleClickOKConfirmedDelete
    }
    document.dispatchEvent(
      new CustomEvent(EventName.openDeleteInternalDocumentConfirmDialog, {
        detail: {
          title: Messages.deleteDialogTitle,
          isOpen: true,
          content,
          OKClickHandler,
          item: internalDocument,
        },
      })
    )
  }
  render() {
    const { internalDocuments, count, partners, doctypes, open, filter, exportNow } = this.state
    const { history } = this.props
    const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
    const pagination = {
      includePagination: true,
      pageIndex,
      tableLength: count,
      gotoPage: (page: number) => {
        history.push({ pathname: frontendRoutes.internalDocument.list.value, state: { page, filter } })
        this.getListInternalDocumentsHandler()
      },
    }
    const changeName = debounce((event: { target: { value: string } }) => {
      this.setState({ filter: { ...filter, number: { $regex: event?.target?.value ?? '', $options: 'i' } } })
    }, 1000)
    const dataInTable = getValidArray(internalDocuments).map((internalDocument) => {
      const isDeleted: boolean = internalDocument?.isDeleted ?? false
      const documentId: string = internalDocument?._id ?? ''
      const pathname = generatePath(frontendRoutes.internalDocument.detail.id.value, {
        id: documentId,
        isDeleted: isDeleted,
      })
      const actions: IRowActionItem[] = [
        {
          name: !isDeleted ? 'Edit document' : 'Go to detail',
          handler: () => history.push(pathname),
        },
      ]
      if (!isDeleted) {
        actions.push({
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: () => this.callOpenDialogConfirm(internalDocument, 'delete'),
        })
      }
      const type = internalDocument.type
      const partnerInfo: string = `${get(internalDocument, 'partner[0].fullName', '')} | ${capitalize(
        get(internalDocument, 'partner[0].category', '')
      )}`
      const typeData = get(internalDocument, 'typeData[0].value', '')
      const actionsTablet: IRowActionItem[] = [
        {
          name: !isDeleted ? 'Edit document' : 'Go to detail',
          handler: () => history.push(pathname),
        },
      ]

      if (!isDeleted) {
        actionsTablet.push({
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: () => this.callOpenDialogConfirm(internalDocument, 'delete'),
        })
      }
      return {
        ...internalDocument,
        partnerInfo: partnerInfo !== ' | ' ? partnerInfo : '',
        typeId: typeData || type,
        publishDate: getFormattedDateWith_MMMDDYYYY(internalDocument?.publishDate ?? ''),
        status: isDeleted ? 'Deleted' : capitalize(internalDocument?.status ?? ''),
        actions: <MoreDropdown actions={actions} />,
        actionsTablet: actionsTablet,
      }
    })
    return (
      <Box className={styles.internalDocumentContainer} bg="white" p={6}>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <HStack mb={6} w={{ base: '100%', md: 'auto' }}>
            <InputGroup borderRadius="6px" w={{ base: '100%', lg: '540px' }} bg="white">
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.400" />
              </InputLeftElement>
              <Input type="search" placeholder="Search Document by name or number..." onChange={changeName} />
            </InputGroup>
            <Button leftIcon="filter.svg" variant="outline" onClick={this.toggleOpen}>
              Filter
            </Button>
          </HStack>
          <HStack mb={6} w={{ base: '100%', md: 'auto' }}>
            <Tooltip
              label="Feature coming soon"
              hasArrow={true}
              sx={{
                fontSize: 'md',
                px: 4,
                py: 2,
                borderRadius: 'md',
                boxShadow: 'lg',
              }}>
              <Box>
                <Button leftIcon="export.svg" variant="outline" disabled>
                  Export
                </Button>
              </Box>
            </Tooltip>
            <Button variant="solid" leftIcon="add-white.svg" onClick={this.handleCreate}>
              Create
            </Button>
          </HStack>
        </Flex>
        {open && (
          <FormModel
            initialValues={initialValues}
            schema={validation}
            handleSubmit={this.filterSubmitHandler}
            component={
              <FilterForm isLoading={this.state.isLoading} partners={partners} doctypes={doctypes} {...this.props} />
            }
          />
        )}
        <Table
          headerList={this.isTablet ? headerTabletAndMobile : headerDesktop}
          isLoading={this.state.isLoading}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          exportNow={exportNow}
          toggleExport={this.toggleExport}
        />
      </Box>
    )
  }
}

export default InternalDocumentList
