import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { getCvFileForGuestAPI } from 'API/guest'
import { ISuccessResponse } from 'API/interface'
import EmptyContentBox from 'components/EmptyContentBox'
import { Document, Page, pdfjs } from 'react-pdf'
import { Messages } from 'constants/index'
import { EProfileTab } from 'containers/Profile/constants'
import { ICurriculumVitae } from './interfaces'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const CurriculumVitaeDetail = (props: ICurriculumVitae) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [disabledPre, setDisabledPre] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [cvFile, setCVFile] = useState<File | null>(null)
  const { cvFileName, cvFilePreview } = props

  const title: string = `${EProfileTab.CURRICULUM_VITAE} User Detail`
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))

  useEffect(() => {
    checkDisabledNavBtn()
  }, [pageNumber, numPages])

  function checkDisabledNavBtn() {
    let isDisabledPre = true
    let isDisabledNext = true
    if (numPages > 1) {
      if (pageNumber === 1) {
        isDisabledNext = false
      } else if (pageNumber === numPages) {
        isDisabledPre = false
      } else {
        isDisabledPre = false
        isDisabledNext = false
      }
    }
    setDisabledPre(isDisabledPre)
    setDisabledNext(isDisabledNext)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  function clickNextPageHandler() {
    setPageNumber(pageNumber + 1)
  }

  function clickPrePageHandler() {
    setPageNumber(pageNumber - 1)
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  async function getCVFileHandler() {
    try {
      if (cvFileName) {
        const response: ISuccessResponse<BlobPart> = await getCvFileForGuestAPI(cvFileName)
        const cv: BlobPart = response.data
        const result: any = await toBase64(new Blob([cv], { type: 'application/pdf' }))
        setCVFile(result)
      }
      if (cvFilePreview) {
        setCVFile(cvFilePreview)
      }
    } catch (error) {
      handleError(error, 'src/components/CurriculumVitaeDetail/index.tsx', 'getCVFileHandler')
    }
  }

  useEffect(() => {
    getCVFileHandler()
  }, [cvFileName])

  return (
    <Box alignContent={'center'}>
      {cvFile ? (
        <div className={`box-body`}>
          <Document
            file={cvFile}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}>
            <Page size="A4" orientation={'portrait'} pageNumber={pageNumber} />
          </Document>
          {numPages && (
            <div className="pagination-cv-page">
              <div className="pagination-sub">
                <span className={`nav-button ${disabledPre ? 'disabled' : ''}`} onClick={clickPrePageHandler}>
                  <span className="double-arrow">«</span>
                </span>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <span className={`nav-button ${disabledNext ? 'disabled' : ''}`} onClick={clickNextPageHandler}>
                  <span className="double-arrow">»</span>
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyContentBox text={Messages.noData} />
      )}
    </Box>
  )
}

export default CurriculumVitaeDetail
