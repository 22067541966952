import { frontendRoutes } from 'constants/routes'

export enum ESubMenuName {
  TIMESHEET = 'timesheet-page',
  SETTINGS = 'setting-page',
  USER_DASHBOARD = 'user-dashboard',
  ADMIN_DASHBOARD = 'admin-dashboard',
  LEAVE_MANAGEMENT = 'leave-management',
}

// * INFO: This is the sub sidebar menu not have page
export const settingSubmenu = [
  {
    name: 'Global config',
    menuName: ESubMenuName.SETTINGS,
    router: frontendRoutes.settingPage.globalConfig.value,
  },
]
export const adminDashboardSubmenu = [
  {
    name: 'Statistic',
    menuName: ESubMenuName.ADMIN_DASHBOARD,
    router: frontendRoutes.adminDashboardPage.statistic.value,
  },
  {
    name: 'Heat Map',
    menuName: ESubMenuName.ADMIN_DASHBOARD,
    router: frontendRoutes.adminDashboardPage.heatmap.value,
  },
  {
    name: 'Skill Summary',
    menuName: ESubMenuName.ADMIN_DASHBOARD,
    router: frontendRoutes.adminDashboardPage.skill.value,
  },
]

export const userDashboardSubmenuOfSupervisor = [
  {
    name: 'Personal',
    menuName: ESubMenuName.USER_DASHBOARD,
    router: frontendRoutes.userDashboardPage.personal.value,
  },
  {
    name: 'Statistic',
    menuName: ESubMenuName.USER_DASHBOARD,
    router: frontendRoutes.userDashboardPage.statistic.value,
  },
  {
    name: 'Heat Map',
    menuName: ESubMenuName.USER_DASHBOARD,
    router: frontendRoutes.userDashboardPage.heatmap.value,
  },
]

export const userDashboardSubmenuOfUser = [
  {
    name: 'Personal',
    menuName: ESubMenuName.USER_DASHBOARD,
    router: frontendRoutes.userDashboardPage.personal.value,
  },
  {
    name: 'Heatmap',
    menuName: ESubMenuName.USER_DASHBOARD,
    router: frontendRoutes.userDashboardPage.heatmap.value,
  },
]

export const timesheetSubmenuAdmin = [
  {
    name: 'Overview',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetAdmin.overview.value,
  },
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.board.value,
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetAdmin.report.value,
  },
  {
    name: 'Bill Report',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetAdmin.billReport.value,
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetAdmin.history.value,
  },
]

export const timesheetSubmenuUser = [
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.board.value,
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetUser.report.value,
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetUser.history.value,
  },
]

export const timesheetSubmenuSupperVisor = [
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.board.value,
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetUser.report.value,
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetUser.history.value,
  },
  {
    name: 'Supervisor',
    menuName: ESubMenuName.TIMESHEET,
    router: frontendRoutes.timesheetPage.timesheetUser.supervise.value,
  },
]

export const leaveSubmenuAdmin = [
  {
    name: 'Leave Request',
    menuName: ESubMenuName.LEAVE_MANAGEMENT,
    router: frontendRoutes.leaveManagementPage.leaveManagementForAdmin.myLeave.value,
  },
]

export const leaveSubmenuUser = [
  {
    name: 'My Leave',
    menuName: ESubMenuName.LEAVE_MANAGEMENT,
    router: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
  },
]

export interface IRouteName {
  userRoute: string
  partner: string
  timesheetRoute: string
  projectRoute: string
  internalDocument: string
  paymentRoute: string
  policyRoute: string
  announcementRoute: string
  diagramRoute: string
  linkToHome: string
  qrCodeRoute: string
  dashboardRoute: string
  leaveManagementRoute: string
  todoListOfHRRoute: string
  staffDocumentOfHRRoute: string
}

export interface IMenuList {
  name: string
  menuName: ESubMenuName
  router: string
}

export enum ESidebarHeaderTitle {
  DEVELOPMENT = 'DEVELOPMENT',
  MANAGEMENT = 'MANAGEMENT',
  GENERAL = 'GENERAL',
  FINANCIAL = 'FINANCIAL',
  INFORMATION = 'INFORMATION',
  EXTENSIONS = 'EXTENSIONS',
  OPERATING_MANAGEMENT = 'OPERATING MANAGEMENT',
  HUMAN_RESOURCES = 'HUMAN RESOURCES',
}

export enum ESidebarMenuContent {
  USER_DASHBOARD = 'Dashboard',
  ADMIN_DASHBOARD = 'Dashboard',
  DASHBOARD = 'Dashboard',
  PARTNER = 'Partner',
  USERS = 'Users',
  INTERNAL_DOCUMENT = 'Internal Document',
  SETTINGS = 'Settings',
  STRATEGY_DIAGRAM = 'Strategy Diagram',
  TIMESHEET = 'Timesheet',
  PROJECT = 'Project',
  COMMIT_TIME = 'Commit Time',
  POLICY = 'Policy',
  ANNOUNCEMENT = 'Announcement',
  PAYMENTS = 'Payments',
  ACCOUNTING = 'Accounting',
  GUIDELINE = 'Guideline',
  QR_CODE = 'QR code',
  CONTRACTS = 'Contracts',
  BIRTHDAY_OF_STAFFS = 'Birthday of staffs',
  LEAVE_MANAGEMENT = 'Leave Management',
  TODO_LIST_OF_HR = 'Todo List',
  STAFF_DOCUMENT = 'Staff Document',
}
