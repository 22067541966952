export interface Project {
  value: string
  // Add other project properties as needed
}

export interface User {
  value: string
  // Add other user properties as needed
}

export interface Filters {
  project?: string
  user?: string
}

export interface FiltersProject {
  project: string
}

export interface FiltersUser {
  user: string
}

export enum Mode {
  PROJECT = 'project',
  USER = 'user',
}

export interface ProjectHistoryData {
  // Add project history data structure
}

export interface UserHistoryData {
  // Add user history data structure
}
