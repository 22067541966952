import { useEffect, useState } from 'react'
import { HStack, VStack } from '@chakra-ui/layout'
import { useMediaQuery, Accordion } from '@chakra-ui/react'
import ItemAccordion from 'components/ItemAccordion'
import TabItem from 'components/Tabs/TabItem'
import { useHistory } from 'react-router-dom'
import { EGlobalConfigTab, globalConfigTabs } from './constants'
import Country from './Country'
import Currency from './Currency'
import DocumentType from './DocumentType'
import LeaveType from './LeaveType'
import Level from './Level'
import ProjectType from './ProjectType'
import PushNotification from './PushNotification'
import TaskType from './TaskType'
import Technology from './Technology'
import Title from './Title'

const GlobalConfig = () => {
  const [currentTab, setCurrentTab] = useState(EGlobalConfigTab.TECHNOLOGY)
  const history = useHistory()
  const { pathname, search } = history.location
  const [isTablet] = useMediaQuery('(max-width: 820px)')
  window.sessionStorage.setItem('subPageTitle', 'Global Config')
  window.dispatchEvent(new Event('storage'))

  useEffect(() => {
    if (search) {
      const tabIndex = Number(search.split('=')[1])
      if (tabIndex !== undefined) {
        setCurrentTab(globalConfigTabs[tabIndex].label)
      } else {
        setCurrentTab(EGlobalConfigTab.TECHNOLOGY)
      }
    }
  })

  function getCurrentTabItem(tabItemName: EGlobalConfigTab, index?: number) {
    setCurrentTab(tabItemName)
    const tabURL = index ? `${pathname}?index=${index}` : `${pathname}?index=0`
    history.replace(tabURL)
  }

  return (
    <>
      {!isTablet ? (
        <HStack
          spacing={{ base: 6, lg: 8 }}
          alignItems={'flex-start'}
          backgroundColor="white"
          borderRadius="8px"
          p={{ base: 6, lg: 8 }}>
          <VStack spacing={3} alignSelf="flex-start" flex={1}>
            <TabItem
              index={0}
              tab={EGlobalConfigTab.TECHNOLOGY}
              iconName={'technology.svg'}
              isActive={currentTab === EGlobalConfigTab.TECHNOLOGY}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={1}
              tab={EGlobalConfigTab.PROJECT_TYPE}
              iconName={'project.svg'}
              isActive={currentTab === EGlobalConfigTab.PROJECT_TYPE}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={2}
              tab={EGlobalConfigTab.TITLE}
              iconName={'title.svg'}
              isActive={currentTab === EGlobalConfigTab.TITLE}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={3}
              tab={EGlobalConfigTab.LEVEL}
              iconName={'level.svg'}
              isActive={currentTab === EGlobalConfigTab.LEVEL}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={4}
              tab={EGlobalConfigTab.CURRENCY}
              iconName={'currency.svg'}
              isActive={currentTab === EGlobalConfigTab.CURRENCY}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={5}
              tab={EGlobalConfigTab.COUNTRY}
              iconName={'country.svg'}
              isActive={currentTab === EGlobalConfigTab.COUNTRY}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={6}
              tab={EGlobalConfigTab.NOTIFICATION}
              iconName={'notification.svg'}
              isActive={currentTab === EGlobalConfigTab.NOTIFICATION}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={7}
              tab={EGlobalConfigTab.TASK_TYPE}
              iconName={'task-type.svg'}
              isActive={currentTab === EGlobalConfigTab.TASK_TYPE}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={8}
              tab={EGlobalConfigTab.DOCUMENT_TYPE}
              iconName={'internal-doc.svg'}
              isActive={currentTab === EGlobalConfigTab.DOCUMENT_TYPE}
              getCurrentTabItem={getCurrentTabItem}
            />
            <TabItem
              index={9}
              tab={EGlobalConfigTab.LEAVE_TYPE}
              iconName={'leave-type.svg'}
              isActive={currentTab === EGlobalConfigTab.LEAVE_TYPE}
              getCurrentTabItem={getCurrentTabItem}
            />
          </VStack>
          <VStack flex={6} alignItems="center" width="70%">
            {currentTab === EGlobalConfigTab.TECHNOLOGY && <Technology />}
            {currentTab === EGlobalConfigTab.PROJECT_TYPE && <ProjectType />}
            {currentTab === EGlobalConfigTab.TITLE && <Title />}
            {currentTab === EGlobalConfigTab.LEVEL && <Level />}
            {currentTab === EGlobalConfigTab.COUNTRY && <Country />}
            {currentTab === EGlobalConfigTab.CURRENCY && <Currency />}
            {currentTab === EGlobalConfigTab.NOTIFICATION && <PushNotification />}
            {currentTab === EGlobalConfigTab.TASK_TYPE && <TaskType />}
            {currentTab === EGlobalConfigTab.DOCUMENT_TYPE && <DocumentType />}
            {currentTab === EGlobalConfigTab.LEAVE_TYPE && <LeaveType />}
          </VStack>
        </HStack>
      ) : (
        <Accordion allowToggle>
          <ItemAccordion label={EGlobalConfigTab.TECHNOLOGY} component={<Technology />} />
          <ItemAccordion label={EGlobalConfigTab.PROJECT_TYPE} component={<ProjectType />} />
          <ItemAccordion label={EGlobalConfigTab.TITLE} component={<Title />} />
          <ItemAccordion label={EGlobalConfigTab.LEVEL} component={<Level />} />
          <ItemAccordion label={EGlobalConfigTab.COUNTRY} component={<Country />} />
          <ItemAccordion label={EGlobalConfigTab.CURRENCY} component={<Currency />} />
          <ItemAccordion label={EGlobalConfigTab.NOTIFICATION} component={<PushNotification />} />
          <ItemAccordion label={EGlobalConfigTab.TASK_TYPE} component={<TaskType />} />
          <ItemAccordion label={EGlobalConfigTab.DOCUMENT_TYPE} component={<DocumentType />} />
          <ItemAccordion label={EGlobalConfigTab.LEAVE_TYPE} component={<LeaveType />} />
        </Accordion>
      )}
    </>
  )
}
export default GlobalConfig
