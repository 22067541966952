import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import { handleError } from 'API/error'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import ViewContractModal from 'components/ViewContractModal'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import moment from 'moment'
import { generatePath } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IRowActionItem } from 'types/table'
import { maxMobileSize } from 'constants/common'
import { EUserDetailTitle } from 'constants/enum'
import { actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IContract } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getHeaderLaborContractList } from '../../constant'
import { ILaborContractData } from '../../constant'
import { EIdItemLink } from '../MenuDetail/MenuItem/constants'
import Section from '../Section'
import MobileContractTable from './components/MobileContractTable'

const LaborContract = () => {
  const { adminContractStore } = useStores()
  const [isOpenModal, setOpenModal] = useState(false)
  const [contractName, setContractName] = useState('')
  const { contractListOfUser, currentContractContent } = adminContractStore
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openActiveModal, setOpenActiveModal] = useState<boolean>(false)
  const [selectedContract, setSelectedProject] = useState<IContract>()
  const [isMobile] = useMediaQuery(maxMobileSize)
  const history = useHistory()
  const params = useParams()
  const userId = get(params, 'id', '')

  async function handleDownloadContract(id: string, fileName: string): Promise<void> {
    try {
      await adminContractStore.downloadContractFile(id, fileName)
    } catch (error) {
      handleError(error, 'src/containers/User/UserDetail/components/LaborContract/index.tsx', 'handleDownloadContract')
    }
  }

  async function handleShowContract(id: string, fileName: string): Promise<void> {
    try {
      setOpenModal(true)
      setContractName(fileName)
      await adminContractStore.showContractFile(id, fileName)
    } catch (error) {
      handleError(error, 'src/containers/User/UserDetail/components/LaborContract/index.tsx', 'handleShowContract')
    }
  }

  function onCloseModal() {
    setOpenModal(false)
  }

  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenActiveModal() {
    setOpenActiveModal(!openActiveModal)
  }

  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    try {
      await adminContractStore.deleteContract(id)
      await adminContractStore.getContractListOfUser(userId)
      setOpenDeleteModal(false)
    } catch (error) {
      handleError(
        error,
        'src/containers/User/UserDetail/components/LaborContract/index.tsx',
        'handleClickOKConfirmedDelete'
      )
      toast.error('Delete contract failed')
    }
  }

  async function handleClickOKConfirmedActive(id: string): Promise<void> {
    try {
      await adminContractStore.activeContract(id)
      await adminContractStore.getContractListOfUser(userId)
      setOpenActiveModal(false)
    } catch (error) {
      handleError(
        error,
        'src/containers/User/UserDetail/components/LaborContract/index.tsx',
        'handleClickOKConfirmedActive'
      )
      toast.error('Active contract failed')
    }
  }

  async function handleClickOKConfirmedDeactive(id: string): Promise<void> {
    try {
      await adminContractStore.deactiveContract(id)
      await adminContractStore.getContractListOfUser(userId)
      setOpenActiveModal(false)
    } catch (error) {
      handleError(
        error,
        'src/containers/User/UserDetail/components/LaborContract/index.tsx',
        'handleClickOKConfirmedDeactive'
      )
      toast.error('Deactive contract failed')
    }
  }

  function callOpenDialogConfirm(contract: IContract, nameOfEvent: string) {
    set(contract, 'shortName', contract?.number ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      setSelectedProject(contract)
      toggleOpenDeleteModal()
    } else {
      setSelectedProject(contract)
      toggleOpenActiveModal()
    }
  }

  const dataInTable: ILaborContractData[] = getValidArray(contractListOfUser).map((contract: IContract) => {
    const isActive: boolean = contract?.isActive ?? false
    const isDeleted: boolean = contract?.isDeleted ?? false
    const actions: IRowActionItem[] = []
    let statusText = 'Not-Active'
    const pathname = generatePath(frontendRoutes.contractPage.contractDetail.id.value, { id: contract._id })
    const actionDetailHandler = () => history.push(pathname)
    if (isActive) {
      statusText = 'Active'
      actions.push({
        name: 'Deactivate',
        customClassNames: ['isWarning'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.ACTIVE),
      })
      actions.push({
        name: 'Delete',
        customClassNames: ['isDanger'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.DELETE),
      })
      actions.push({
        name: 'Edit',
        handler: () => actionDetailHandler(),
      })
    }

    if (!isActive && !isDeleted) {
      actions.push({
        name: 'Active',
        customClassNames: ['isSafe'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.ACTIVE),
      })
    }
    const actionsTablet: IRowActionItem[] = []
    actionsTablet.push({
      name: 'Detail',
      handler: () => actionDetailHandler(),
    })
    if (isDeleted) {
      statusText = 'Deleted'
    }

    return {
      ...contract,
      status: statusText,
      publishedDate: moment(contract?.publishedDate ?? '').format('DD/MM/YYYY'),
      actions: (
        <MoreDropdown
          isDownload={true}
          downloadActionHandler={() => handleDownloadContract(contract._id, contract.fileName)}
          isDetail={true}
          detailActionHandler={() => handleShowContract(contract._id, contract.fileName)}
          actions={actions}
        />
      ),
      actionsTablet: actionsTablet,
    }
  })
  useEffect(() => {
    adminContractStore.getContractListOfUser(userId)
  }, [])

  return (
    <Section title={EUserDetailTitle.LABOR_CONTRACT} id={EIdItemLink.LABOR_CONTRACT}>
      {isMobile ? (
        <MobileContractTable contractList={dataInTable} />
      ) : (
        <Table
          headerList={getHeaderLaborContractList()}
          tableData={dataInTable}
          isStriped
          isShowPagination={false}
          heightEmptyContentBox="300px"
        />
      )}
      <ViewContractModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        content={currentContractContent}
        fileName={contractName}
      />
      <ConfirmModal
        data={selectedContract}
        isOpen={openDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Contract'}
        content={'Contract Number'}
        type={EModalType.DELETE}
      />
      <ConfirmModal
        data={selectedContract}
        isOpen={openActiveModal}
        closeHandler={toggleOpenActiveModal}
        OKClickHandler={selectedContract?.isActive ? handleClickOKConfirmedDeactive : handleClickOKConfirmedActive}
        title={'Contract'}
        content={'Contract Number'}
        type={EModalType.ACTIVE}
      />
    </Section>
  )
}

export default observer(LaborContract)
