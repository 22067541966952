import { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { getErrorMessage } from 'API/error'
import TagOnTable from 'components/Tag/TagOnTable'
import { History } from 'history'
import { debounce, set } from 'lodash'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import Table from '../../../components/Table'
import MoreDropdown from '../../../components/Table/DesktopTable/components/MoreDropdown'
import { limitItemPerPage, Messages } from '../../../constants'
import { maxMobileSize } from '../../../constants/common'
import { EConditionGetList, ELeaveRequestStatus, ELeaveStatus } from '../../../constants/enum'
import { frontendRoutes } from '../../../constants/routes'
import { backgroundGrey200 } from '../../../theme/globalStyles'
import {
  ILeaveRequest,
  ITableLeaveData,
  ILeaveRequestFilterData,
  ILeaveRequestFilter,
} from '../../../types/leaveRequest'
import { getArrayValueFromParsedQuery, getValidArray, removeAccents } from '../../../utils/commonUtils'
import { useStores } from '../../../utils/hooks/useStores'
import FilterForm from './components/FilterForm'
import HeaderSection from './components/HeaderSection'
import LeaveCommentModal from './components/LeaveCommentModal'
import LeaveModal from './components/LeaveModal'
import { getHeaderList } from './constant'
import { convertLeaveRequestsToTableFormat, formatFilterData } from './container'
import styles from './styles.module.scss'
const AdminLeaveRequest = () => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false)
  const [isOpenFilterForm, setIsOpenFilterForm] = useState(false)
  const history: History = useHistory()
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminLeaveRequestStore, adminUserStore } = useStores()
  const { userNameList } = adminUserStore
  const { userLeaveList, count } = adminLeaveRequestStore
  const [leaveRequestSelected, setLeaveRequestSelected] = useState<ITableLeaveData>(null)
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })

  initialFilter.userId = getArrayValueFromParsedQuery(initialFilter, 'userId')
  initialFilter.status = getArrayValueFromParsedQuery(initialFilter, 'status')
  const [filter, setFilter] = useState<ILeaveRequestFilter>(initialFilter ?? {})

  function openDetailModal(leaveRequest: ITableLeaveData) {
    const currentParams = queryString.parse(history.location.search)
    const updatedParams = { ...currentParams, id: leaveRequest.id }
    history.push({
      pathname: frontendRoutes.leaveManagementPage.leaveManagementForAdmin.myLeave.value,
      search: queryString.stringify(updatedParams),
    })
    setLeaveRequestSelected(leaveRequest)
    setIsDetailModalOpen(true)
  }

  function toggleOpenFilterForm() {
    setIsOpenFilterForm(!isOpenFilterForm)
  }

  function closeDetailModal() {
    const params = new URLSearchParams(history.location.search)
    if (params.has('id')) {
      params.delete('id')
      history.replace({
        search: params.toString(),
      })
    }
    setIsDetailModalOpen(false)
  }

  const changeName = debounce((event: { target: { value: string } }) => {
    const normalizedFullName = removeAccents(event?.target?.value ?? '')
    const changedFilter = {
      ...filter,
      normalizedFullName,
      page: 1,
    }

    history.push({ search: queryString.stringify(changedFilter) })
    setFilter(changedFilter)
  }, 1000)

  const usersOptions = createOptionsOfReactSelectFromDB(userNameList)

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex: pageIndex,
    pageSize: limitItemPerPage,
    tableLength: count,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForAdmin.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })

      adminLeaveRequestStore.adminAggregateLeaveRequest(history)
    },
  }

  async function updateLeaveStatus(id: string, status: ELeaveRequestStatus) {
    try {
      await adminLeaveRequestStore.adminReviewLeaveRequest(id, status)
      toast.success(Messages.updateLeaveRequestSuccess)
      await adminLeaveRequestStore.adminAggregateLeaveRequest(history)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  function filterSubmitHandler(data: ILeaveRequestFilterData): void {
    const formattedFilter = formatFilterData(data)
    initialFilter.normalizedFullName && set(formattedFilter, 'normalizedFullName', initialFilter.normalizedFullName)
    history.push({ search: queryString.stringify(formattedFilter) })
    setFilter(formattedFilter)
    toggleOpenFilterForm()
  }

  function openCommentModal(leaveRequest: ITableLeaveData) {
    setLeaveRequestSelected(leaveRequest)
    setIsCommentModalOpen(true)
  }

  function closeCommentModal() {
    setIsCommentModalOpen(false)
  }

  function updateComment(id: string, comment: string) {
    toast
      .promise(adminLeaveRequestStore.adminCommentLeaveRequest(id, comment), {
        pending: 'Updating comment...',
        success: 'Comment updated successfully!',
        error: 'Failed to update comment',
      })
      .then(() => {
        setIsCommentModalOpen(false)
      })
      .finally(() => {
        adminLeaveRequestStore.adminAggregateLeaveRequest(history)
      })
  }

  useEffect(() => {
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    adminLeaveRequestStore.adminAggregateLeaveRequest(history, filter)
  }, [filter])

  useEffect(() => {
    function handleOpenDetailModalFromUrl() {
      if (Array.isArray(userLeaveList) && userLeaveList.length > 0) {
        const params = new URLSearchParams(history.location.search)
        if (params.has('id')) {
          const id = params.get('id')
          const selectedItem = convertLeaveRequestsToTableFormat(getValidArray<ILeaveRequest>(userLeaveList)).find(
            (item) => item.id === id
          )
          if (selectedItem) {
            openDetailModal(selectedItem as any)
          } else {
            toast.error(Messages.wrongUser)
            closeDetailModal()
          }
        }
      }
    }
    handleOpenDetailModalFromUrl()
  }, [userLeaveList])

  const dataInTable = convertLeaveRequestsToTableFormat(getValidArray<ILeaveRequest>(userLeaveList)).map(
    (leaver: any) => {
      return {
        ...leaver,
        dateCreated: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.dateCreated}
          </Text>
        ),
        timeRange: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.timeRange}
          </Text>
        ),
        user: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.user}
          </Text>
        ),
        leaveType: (
          <TagOnTable label={leaver.leaveType} color={leaver.tagColor} textColor={leaver.tagColor}></TagOnTable>
        ),
        actionsCommented: (
          <MoreDropdown
            isCommented={true}
            commentActionHandler={() => openCommentModal(leaver)}
            isLoading={adminLeaveRequestStore.isLoading}
          />
        ),
        actionsAccepted:
          leaver.status === ELeaveStatus.PENDING ? (
            <MoreDropdown
              isAccepted={true}
              acceptActionHandler={() => updateLeaveStatus(leaver.id, ELeaveRequestStatus.APPROVED)}
              isLoading={adminLeaveRequestStore.isLoading}
            />
          ) : null,
        actionsRejected:
          leaver.status === ELeaveStatus.PENDING ? (
            <MoreDropdown
              isReject={true}
              rejectActionHandler={() => updateLeaveStatus(leaver.id, ELeaveRequestStatus.REJECTED)}
              isLoading={adminLeaveRequestStore.isLoading}
            />
          ) : null,
      }
    }
  )

  return (
    <Box
      className={styles.leaveContainer}
      background="white"
      padding={6}
      borderRadius="6px"
      border={`1px solid ${backgroundGrey200}`}>
      {isDetailModalOpen && (
        <LeaveModal
          isLoading={adminLeaveRequestStore.isLoading}
          isOpen={isDetailModalOpen}
          saveHandler={updateLeaveStatus}
          closeHandler={closeDetailModal}
          leaveSelectedData={leaveRequestSelected}
        />
      )}
      {isCommentModalOpen && (
        <LeaveCommentModal
          isOpen={isCommentModalOpen}
          isLoading={adminLeaveRequestStore.isLoading}
          closeHandler={closeCommentModal}
          saveHandler={updateComment}
          leaveSelectedData={leaveRequestSelected}
        />
      )}
      <HeaderSection toggleOpen={toggleOpenFilterForm} changeName={changeName} />
      <FilterForm
        openModalFilterForm={isOpenFilterForm}
        setOpenFilterForm={toggleOpenFilterForm}
        usersList={usersOptions}
        filterSubmit={filterSubmitHandler}
      />
      <Table
        headerList={getHeaderList(isMobile)}
        isLoading={adminLeaveRequestStore.isLeaveListLoading}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
    </Box>
  )
}

export default observer(AdminLeaveRequest)
