import { useState, useEffect } from 'react'
import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import Icon from 'components/Icon'
import Table from 'components/Table'
import { History } from 'history'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { ILeaveFilter, ILeaveRequest, ILeaveRequestFormData, IUserLeaveRequestAggregatePayload, IUserLeaveRequestFilter, IUserLeaveRequestFilterData } from 'types/leaveRequest'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { ELeaveManagementSubTitle } from 'constants/enum'
import { defaultPage, defaultPageSize } from 'constants/index'
import { Messages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from './components/HeaderSection'
import LeaveRequestDetailModal from './components/LeaveRequestDetailModal'
import LeaveRequestModal from './components/LeaveRequestModal'
import LeaveRequestSummaryModal from './components/LeaveRequestSummaryModal'
import { getHeaderList } from './constant'
import { convertLeaveRequestsToTableFormat, formatFilterData } from './container'
import FilterForm from './components/FilterForm'

import styles from './styles.module.scss'

const UserLeaveRequest = () => {
  window.sessionStorage.setItem('subPageTitle', ELeaveManagementSubTitle.MY_LEAVE)
  window.dispatchEvent(new Event('storage'))

  const [isMobile] = useMediaQuery(maxMobileSize)
  const [isTablet] = useMediaQuery(maxTabletSize)

  const history: History = useHistory()

  const { userLeaveRequestStore, leaveTypeStore, userProfileStore } = useStores()
  const { leaveRequestList, isLoadingSubmit, isLoadingList, totalCount } = userLeaveRequestStore
  const { profileDetail } = userProfileStore
  const remainLeaveDays = profileDetail?.remainLeaveDays

  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })


  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState<ILeaveRequest>(null)
  const [filter, setFilter] = useState<IUserLeaveRequestAggregatePayload>(initialFilter ?? {})
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isOpenDeleteLeaveConfirm, setIsOpenDeleteLeaveConfirm] = useState<boolean>(false)
  const [isOpenFilterForm, setIsOpenFilterForm] = useState<boolean>(false)

  function openCreateModal() {
    setIsCreateModalOpen(true)
  }

  function closeCreateModal() {
    setIsCreateModalOpen(false)
  }

  function openSummaryModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsSummaryModalOpen(true)
  }

  function closeSummaryModal() {
    setIsSummaryModalOpen(false)
  }

  function openDetailModal(leaveRequest: ILeaveRequest) {
    const currentParams = queryString.parse(history.location.search)
    const updatedParams = { ...currentParams, id: leaveRequest.id }
    history.push({
      pathname: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
      search: queryString.stringify(updatedParams),
    })
    setSelectedLeaveRequest(leaveRequest)
    setIsDetailModalOpen(true)
  }

  function closeDetailModal() {
    const params = new URLSearchParams(history.location.search)
    if (params.has('id')) {
      params.delete('id')
      history.replace({
        search: params.toString(),
      })
    }
    setIsDetailModalOpen(false)
  }

  function openEditModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsEditModalOpen(true)
  }

  function closeEditModal() {
    setIsEditModalOpen(false)
  }

  function openConfirmDeleteModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsOpenDeleteLeaveConfirm(true)
  }

  function closeConfirmDeleteModal() {
    setIsOpenDeleteLeaveConfirm(false)
  }

  function toggleOpenFilterForm() {
    setIsOpenFilterForm(!isOpenFilterForm)
  }

  async function handleCreateLeaveRequest(data: ILeaveRequestFormData) {
    try {
      await userLeaveRequestStore.userCreateLeaveRequest(data)
      closeCreateModal()
      setFilter({ ...filter, currentPage: 1 })
    } catch {}
  }

  async function handleEditLeaveRequest(data: ILeaveRequestFormData) {
    try {
      await userLeaveRequestStore.userUpdateLeaveRequest(selectedLeaveRequest.id, data)
      closeEditModal()
      setFilter({ ...filter, currentPage: 1 })
      toast.success(Messages.editLeaveRequeSuccess)
    } catch {}
  }

  async function handleDeleteLeaveRequest(id: string): Promise<void> {
    await userLeaveRequestStore.userDeleteLeaveRequest(id)
    setIsOpenDeleteLeaveConfirm(false)
    setFilter({ ...filter, currentPage: 1 })
  }

  const pageIndex: number = Number(get(filter, 'page', defaultPage))
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: defaultPageSize,
    tableLength: totalCount,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })
    },
  }

  function filterSubmitHandler(data: IUserLeaveRequestFilterData): void {
    const formattedFilter = formatFilterData(data)
    history.push({ search: queryString.stringify(formattedFilter) })
    setFilter(formattedFilter)
    toggleOpenFilterForm()
  }


  const dataInTable =
    convertLeaveRequestsToTableFormat(
      leaveRequestList,
      openSummaryModal,
      openDetailModal,
      openEditModal,
      openConfirmDeleteModal
    ) ?? []

  useEffect(() => {
    leaveTypeStore.getUserLeaveTypeList()
    userProfileStore.getProfileDetail()
  }, [])

  useEffect(() => {
    function handleOpenDetailModalFromUrl() {
      if (Array.isArray(leaveRequestList) && leaveRequestList.length > 0) {
        const params = new URLSearchParams(history.location.search)
        if (params.has('id')) {
          const id = params.get('id')
          const selectedItem = leaveRequestList.find((item) => item.id === id)
          if (selectedItem) {
            openDetailModal(selectedItem)
          } else {
            toast.error(Messages.wrongUser)
            closeDetailModal()
          }
        }
      }
    }
    handleOpenDetailModalFromUrl()
  }, [leaveRequestList])

  useEffect(() => {
    userLeaveRequestStore.userAggregateLeaveRequest(history, filter)
  }, [filter])

  return (
    <Box>
      <Box
        className={styles.leaveContainer}
        background={isMobile ? 'none' : 'white'}
        paddingY={isMobile ? 2 : 4}
        paddingX={isMobile ? 0 : 4}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>

        <HeaderSection handleCreate={openCreateModal} toggleOpen={toggleOpenFilterForm} remainLeaveDays={remainLeaveDays} />
        <FilterForm
          openModalFilterForm={isOpenFilterForm}
          setOpenFilterForm={toggleOpenFilterForm}
          filterSubmit={filterSubmitHandler}
        />
        <Box className={styles.body} paddingY={3} paddingX={4}>
          <LeaveRequestModal
            isLoading={isLoadingSubmit}
            isOpen={isCreateModalOpen}
            closeHandler={closeCreateModal}
            saveHandler={handleCreateLeaveRequest}
          />

          <LeaveRequestModal
            selectedLeaveRequest={selectedLeaveRequest}
            isLoading={isLoadingSubmit}
            isEdit={true}
            isOpen={isEditModalOpen}
            closeHandler={closeEditModal}
            saveHandler={handleEditLeaveRequest}
          />
          <ConfirmYesNoDeleteModal
            isOpen={isOpenDeleteLeaveConfirm}
            title="leave request"
            closeHandler={closeConfirmDeleteModal}
            OKClickHandler={handleDeleteLeaveRequest}
            itemId={selectedLeaveRequest?.id}
          />
          <LeaveRequestDetailModal
            isOpen={isDetailModalOpen}
            closeHandler={closeDetailModal}
            selectedLeaveRequest={selectedLeaveRequest}
          />
          <LeaveRequestSummaryModal
            isOpen={isSummaryModalOpen}
            handleCloseModal={closeSummaryModal}
            selectedLeaveRequest={selectedLeaveRequest}
          />
        </Box>
        <Table
          headerList={getHeaderList(isTablet)}
          isLoading={isLoadingList}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
        />
      </Box>
    </Box>
  )
}
export default observer(UserLeaveRequest)
