import { Input, InputGroup, InputLeftAddon, InputRightAddon, Textarea } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { backgroundGrey100, borderGray200, borderRed500, textGrey700 } from 'theme/globalStyles'
import { Messages } from 'constants/index'
import { getPattern } from './container'
import { IFormInputFieldProps } from './interfaces'
import styles from './index.module.scss'

const FormInputField = (props: IFormInputFieldProps) => {
  const {
    name,
    type,
    placeholder,
    isRequired,
    readonly,
    rightAddonText,
    disabled,
    isWebsite,
    defaultValue,
    minHeightOfTextarea,
    onBlurHandler,
    id,
    step,
  } = props
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  let pattern = getPattern(name)

  const disabledProps = disabled
    ? {
        disabled: true,
        background: backgroundGrey100,
        opacity: '0.7 !important',
        color: textGrey700,
        variant: 'filled',
      }
    : {}
  return (
    <InputGroup width="100%">
      {isWebsite && <InputLeftAddon children="https://" />}
      {type !== 'textarea' ? (
        <Input
          step={step}
          id={id}
          className={styles.inputField}
          type={type}
          defaultValue={defaultValue}
          autoComplete={name}
          placeholder={placeholder}
          isReadOnly={readonly}
          {...disabledProps}
          focusBorderColor={errors?.name ? borderRed500 : borderGray200}
          {...register(name, {
            required: isRequired ? Messages.fieldRequired : false,
            pattern,
          })}
          color={textGrey700}
        />
      ) : (
        <Textarea
          id={id}
          resize={'none'}
          paddingLeft={'20px'}
          readOnly={readonly}
          {...disabledProps}
          {...register(name)}
          fontSize="16px"
          width={'100%'}
          minH={minHeightOfTextarea}
          focusBorderColor={errors?.name ? borderRed500 : borderGray200}
          onBlur={onBlurHandler}
          {...register(name, {
            required: isRequired ? Messages.fieldRequired : false,
            pattern,
          })}
        />
      )}
      {rightAddonText && <InputRightAddon paddingY="1px" children={rightAddonText} />}
    </InputGroup>
  )
}

export default FormInputField
