import { Box } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import Button from 'components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { borderGray200, borderRed600, textRed600 } from 'theme/globalStyles'
import { ELeaveRequestStatus, ELeaveStatus } from 'constants/enum'
import LeaveDetailForm from '../LeaveDetailForm'
import { ILeaveFormProps } from './interface'

const LeaveModal = (props: ILeaveFormProps) => {
  const { isOpen, isLoading, closeHandler, saveHandler, leaveSelectedData } = props
  const methods = useForm()

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="xl" isCentered>
      <ModalOverlay />
      <FormProvider {...methods}>
        <form>
          <ModalContent maxHeight={{ lg: '600px', md: '600px', sm: '600px' }}>
            <Box background="white" width="full">
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                Leave request detail
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            </Box>
            <ModalBody overflowY="auto" paddingTop="20px">
              <LeaveDetailForm leaveSelectedData={leaveSelectedData} />
            </ModalBody>
            {leaveSelectedData.status === ELeaveStatus.PENDING && (
              <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
                <Button
                  onClick={() => saveHandler(leaveSelectedData.id, ELeaveRequestStatus.REJECTED)}
                  variant="outline"
                  colorScheme={borderRed600}
                  color={textRed600}
                  type="submit"
                  customStyles={{ marginRight: '16px' }}>
                  Reject
                </Button>
                <Button
                  onClick={() => saveHandler(leaveSelectedData.id, ELeaveRequestStatus.APPROVED)}
                  variant="solid"
                  type="submit"
                  isLoading={isLoading}>
                  Approve
                </Button>
              </ModalFooter>
            )}
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default LeaveModal
