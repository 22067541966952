/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { Box, Divider, FormControl, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import InfoBox from 'components/InfoBox'
import LoadingChakra from 'components/LoadingChakra'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { EProjectType } from 'enums/developerOnProject'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { textSecondary } from 'theme/globalStyles'
import { ICommitTime } from 'types/dashboard-user'
import { IDeveloperOnProject } from 'types/developerOnProject'
import {
  EConditionGetList,
  EGender,
  EPaymentCategoryOfUserEnum,
  EPerformanceReportTypes,
  EWorkingHourPeriods,
  EDashboardSubTitle,
} from 'constants/enum'
import SingleSelectWithNavigation from 'containers/Dashboard/components/SingleSelectWithNavigation'
import { getValidArray, getDisplayName } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateUserOptions } from 'utils/user'
import { ReactComponent as HelloBoy } from '../../../../assets/images/svg/hello-boy.svg'
import { ReactComponent as HelloGirl } from '../../../../assets/images/svg/hello-girl.svg'
import { ReactComponent as Salary } from '../../../../assets/images/svg/salary.svg'
import { ReactComponent as WorkingProject } from '../../../../assets/images/svg/working-project.svg'
import { ReactComponent as WorkingTime } from '../../../../assets/images/svg/working-time.svg'
import LaborCostChart from '../../components/LaborCostChart'
import LoggedTimeChart from '../../components/LoggedTimeChart'
import MonthlyPerformanceChart from '../../components/MonthlyPerformanceChart'
import ProjectWorkingTimeChart from '../../components/ProjectWorkingTimeChart'
import SalaryChart from '../../components/SalaryChart'
import { IOption, performanceReportTypeOptions, workingPeriodOptions } from '../../constant'

interface IDashboardUserForm {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  reportType: IOption<EPerformanceReportTypes>
  date: Date
  selectedProject: IOption<string>
}
const DashboardUser = () => {
  window.sessionStorage.setItem('subPageTitle', EDashboardSubTitle.PERSONAL)
  window.dispatchEvent(new Event('storage'))
  const params = useParams()
  const userId: string = get(params, 'id', '')
  const { userProjectStore, userProfileStore, adminProjectStore, adminUserStore } = useStores()
  const { projectNameList } = userProjectStore
  const { projectNameListOfAGivenUser } = adminProjectStore
  const { userNameList } = adminUserStore

  let projectOptions: IOption[] = [
    { label: 'All projects', value: 'all' },
    ...createOptionsOfReactSelectFromDB(projectNameList),
  ]
  if (userId) {
    projectOptions = [
      { label: 'All projects', value: 'all' },
      ...createOptionsOfReactSelectFromDB(projectNameListOfAGivenUser),
    ]
  }
  const methods = useForm<IDashboardUserForm>({
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
      reportType: performanceReportTypeOptions[0],
      selectedProject: projectOptions[0],
    },
  })
  const { control, setValue } = methods
  const { dashboardData, profileDetail } = userProfileStore
  const [gender, setGender] = useState(localStorage.getItem('displayGender') ?? profileDetail?.gender ?? EGender.MALE)
  const {
    totalSalary,
    lastTotalSalary,
    totalWorkingTime,
    lastTotalWorkingTime,
    numberOfProjects,
    lastNumberOfProjects,
    projectWorkingTimePieData,
  } = dashboardData
  const commitTimes: ICommitTime[] = getValidArray<ICommitTime>(dashboardData?.commitTimes) || []

  const selectedProject = useWatch({
    name: 'selectedProject',
    control,
  })
  const period = useWatch({
    name: 'period',
    control,
  })
  const date = useWatch({
    name: 'date',
    control,
  })
  const developerOnProjectsData: IDeveloperOnProject[] = getValidArray<IDeveloperOnProject>(
    profileDetail?.developerOnProjectsData
  )
  const paymentCategory: EPaymentCategoryOfUserEnum = profileDetail?.paymentCategory
    ?.value as EPaymentCategoryOfUserEnum
  const extraProject = developerOnProjectsData.filter((item) => item.projectType !== EProjectType.NORMAL_PROJECT)

  const isAll: boolean = selectedProject.value === 'all'
  const isCommittedAll: boolean =
    commitTimes.findIndex((item) => item?.isAll && item?.isActive && item?.commitTime > 0) > -1
  function getCommitTime(): number {
    const isMonth: boolean = period?.value !== EWorkingHourPeriods.WEEK
    const allValue: number = commitTimes.find((item) => item?.isAll && item?.isActive)?.commitTime ?? 0
    const fullTimeValue: number = isMonth ? 160 : 40
    const partTimeValue: number = isMonth ? 40 : 10
    const maxValue: number = isMonth ? 320 : 80
    const totalValue: number = commitTimes
      .filter((item) => item?.isActive && item?.isAll === false)
      .reduce((acc, item) => acc + item.commitTime, 0)
    const selectedValue: number = commitTimes.find((item) => item?.project === selectedProject.value)?.commitTime ?? 0
    const result: number = (isAll ? (allValue > 0 ? allValue : totalValue) : selectedValue) || 10
    if (result < fullTimeValue && paymentCategory !== EPaymentCategoryOfUserEnum.FREELANCER_FEE && isAll) {
      if (paymentCategory === EPaymentCategoryOfUserEnum.MIXED_PAYROLL_FREELANCER_FEE) {
        return Math.min(maxValue, fullTimeValue + extraProject.length * partTimeValue)
      }
      return fullTimeValue
    }
    return period?.value === EWorkingHourPeriods.MONTH ? result * 4 : result
  }
  const selectedData = getValidArray(projectWorkingTimePieData).find((item) => item.id === selectedProject.value)
  const selectedLoggedTime: number = isAll ? totalWorkingTime : selectedData?.value ?? 0

  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }
  const reportType = useWatch({
    name: 'reportType',
    control,
  })
  async function fetchData() {
    try {
      await userProfileStore.fetchDashboardUser(
        (period?.value ?? EWorkingHourPeriods.WEEK) as EWorkingHourPeriods.WEEK,
        dayjs(date).format('DD-MM-YYYY'),
        userId
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (period && date) {
      fetchData()
      setValue('selectedProject', projectOptions[0])
    }
  }, [period, date, userId])

  useEffect(() => {
    if (userId) {
      adminProjectStore.getAllNameProjectListOfAGivenUser(EConditionGetList.ACTIVE, userId)
      adminUserStore.getAllNameUserList(EConditionGetList.ALL)
    } else {
      userProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    }
  }, [userId])

  return (
    <FormProvider {...methods}>
      <form>
        <VStack spacing="28px" height="auto" width="full" marginX={{ base: 2, lg: 'auto' }} maxWidth="1312px">
          <HStack
            width="calc(100% - 80px)"
            height="88px"
            padding="40px"
            marginRight={{ base: 0, lg: 6 }}
            marginLeft={{ base: -4, lg: 6 }}
            position="relative"
            borderRadius={{ base: 'none', lg: '8px' }}
            background="radial-gradient(3537.50% 139.95% at 18.83% -6.85%, #D1EBFA 0%, #A0D8F8 100%)">
            <VStack spacing={4} alignItems="flex-start">
              <HStack>
                <Text
                  marginY={0}
                  color="gray.700"
                  fontSize={{ base: '24px', lg: '36px' }}
                  lineHeight={{ base: '28px', lg: '40px' }}
                  fontWeight="700"
                  display="flex">
                  {userId ? 'Welcome to' : 'Hi'}
                  <Text marginLeft={2} marginY={0} color={textSecondary}>
                    {userId ? `${getDisplayName(dashboardData)}'s Dashboard` : getDisplayName(dashboardData)}
                  </Text>
                </Text>
                {userId && (
                  <SingleSelectWithNavigation
                    name="selectedUser"
                    optionsData={generateUserOptions(userNameList)}
                    placeHolder="Search user"
                    menuIsOpen
                    isClearable={false}
                  />
                )}
              </HStack>
              <Text
                marginY={0}
                color="gray.700"
                fontSize={{ base: '20px', lg: '24px' }}
                lineHeight={{ base: '22px', lg: '32px' }}>
                Have a nice day of work!
              </Text>
            </VStack>
            <HStack
              position="absolute"
              right="10%"
              top="32px"
              display={{ base: 'none', lg: 'flex' }}
              cursor="pointer"
              onClick={() => {
                const displayGender: EGender = gender === EGender.MALE ? EGender.FEMALE : EGender.MALE
                setGender(displayGender)
                localStorage.setItem('displayGender', displayGender)
              }}>
              {gender === EGender.MALE ? <HelloBoy /> : <HelloGirl />}
            </HStack>
          </HStack>
          <VStack spacing={6} width="full">
            <Stack
              flexDirection={{ base: 'column', lg: 'row' }}
              spacing={0}
              gap={4}
              width={{ base: 'calc(100% - 16px)', lg: '416px' }}
              alignSelf="flex-start">
              <SingleSelect
                name="period"
                placeHolder="Select Period"
                optionsData={workingPeriodOptions}
                isClearable={false}
                borderColor="#4D5DFB"
              />
              <FormControl background="white">
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { value } }) => (
                    <DatePicker
                      selectedDate={value}
                      onChange={pickDate}
                      style={{
                        border: '1px solid #4D5DFB',
                        borderColor: '#4D5DFB',
                      }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <LoadingChakra isLoading={userProfileStore.isDashboardLoading}>
              <>
                <Stack
                  flexDirection={{ base: 'column', lg: 'row' }}
                  spacing={0}
                  gap={4}
                  transform={{ base: 'translateX(-8px)', lg: 'unset' }}
                  width={{ base: 'calc(100% - 16px)', lg: 'full' }}>
                  <InfoBox
                    title="Total Salary (VND)"
                    value={totalSalary.toLocaleString()}
                    percent={((totalSalary / lastTotalSalary) * 100 - 100).toFixed(2)}
                    icon={<Salary width="48px" height="48px" style={{ alignSelf: 'flex-start' }} />}
                  />
                  <InfoBox
                    title="Total Working Time"
                    value={Number(totalWorkingTime).toFixed(1)}
                    percent={((totalWorkingTime / lastTotalWorkingTime) * 100 - 100).toFixed(2)}
                    icon={<WorkingTime width="48px" height="48px" style={{ alignSelf: 'flex-start' }} />}
                  />
                  <InfoBox
                    title={`Working Project${numberOfProjects > 1 ? 's' : ''}`}
                    value={String(numberOfProjects)}
                    percent={((numberOfProjects / lastNumberOfProjects) * 100 - 100).toFixed(2)}
                    icon={<WorkingProject width="48px" height="48px" style={{ alignSelf: 'flex-start' }} />}
                  />
                </Stack>
                <Stack
                  flexDirection={{
                    base: 'column',
                    lg: 'row',
                  }}
                  width="full"
                  spacing={0}
                  gap="24px">
                  <VStack
                    spacing={6}
                    alignItems="flex-start"
                    flex={{ base: 'unset', lg: 5 }}
                    minWidth={{ base: 'unset', lg: 'min(531px, 42%)' }}>
                    <VStack
                      padding={6}
                      background="white"
                      borderRadius="6px"
                      boxShadow="base"
                      height={{ base: 'auto', lg: '188px' }}
                      width={{ base: 'calc(100% - 48px - 16px)', lg: 'calc(100% - 48px)' }}
                      alignItems="flex-start"
                      justifyContent="space-between">
                      <HStack width="full" justifyContent="space-between">
                        <Text
                          marginY={0}
                          color="gray.700"
                          fontSize="18px"
                          fontWeight="500"
                          lineHeight="28px"
                          whiteSpace="nowrap">
                          Logged Time
                        </Text>
                        <HStack width="170px">
                          <SingleSelect
                            name="selectedProject"
                            placeHolder="Select Projects"
                            optionsData={isCommittedAll ? [projectOptions[0]] : projectOptions}
                            isMulti
                            isClearable={false}
                          />
                        </HStack>
                      </HStack>
                      <Stack
                        flexDirection={{ base: 'column', lg: 'row' }}
                        spacing={0}
                        gap={4}
                        width="full"
                        justifyContent="space-between"
                        position="relative">
                        <Box width="200px" height="200px">
                          <LoggedTimeChart
                            loggedTime={selectedLoggedTime}
                            percent={Number(((selectedLoggedTime / getCommitTime()) * 100).toFixed(1))}
                          />
                        </Box>
                        <VStack spacing={3} whiteSpace="nowrap" alignItems="flex-start">
                          <Text marginY={0} color="gray.500" fontSize="30px" fontWeight="700" lineHeight="36px">
                            {selectedLoggedTime.toFixed(1)} (hrs)
                          </Text>
                          <Divider borderColor="gray.500" borderBottomWidth="2px" opacity={1} />
                          <Text marginY={0} color="gray.500" fontSize="18px" fontWeight="500" lineHeight="28px">
                            {getCommitTime()} (hrs)
                          </Text>
                        </VStack>
                      </Stack>
                    </VStack>
                    {totalWorkingTime && (
                      <VStack
                        padding={6}
                        background="white"
                        borderRadius="6px"
                        boxShadow="base"
                        height={{ base: '284px', lg: '284px' }}
                        width={{ base: 'calc(100% - 48px - 16px)', lg: 'calc(100% - 48px)' }}
                        alignItems="flex-start">
                        <Text marginY={0} color="gray.700" fontSize="18px" fontWeight="500" lineHeight="28px">
                          Project Working Time (hrs)
                        </Text>
                        <ProjectWorkingTimeChart />
                      </VStack>
                    )}
                  </VStack>
                  {totalWorkingTime && (
                    <VStack
                      padding={6}
                      background="white"
                      borderRadius="6px"
                      boxShadow="base"
                      minHeight="auto"
                      height={{ base: 'auto', lg: 'auto' }}
                      flex={{ base: 'unset', lg: 12 }}
                      width={{ base: 'calc(100% - 48px - 16px)', lg: 'calc(100% - 48px)' }}>
                      <Stack
                        flexDirection={{
                          base: 'column',
                          md: 'row',
                        }}
                        spacing={0}
                        gap={4}
                        width="full"
                        justifyContent="space-between">
                        <Text
                          alignSelf="flex-start"
                          marginY={0}
                          color="gray.700"
                          fontSize="18px"
                          fontWeight="500"
                          lineHeight="28px">
                          {period?.label?.split(' ')[0] ?? 'Month'}ly Performance Report
                        </Text>
                        <HStack width="210px">
                          <SingleSelect
                            name="reportType"
                            placeHolder="Select report"
                            optionsData={performanceReportTypeOptions}
                            isClearable={false}
                          />
                        </HStack>
                      </Stack>
                      <HStack width="full" height="484px">
                        {reportType?.value === EPerformanceReportTypes.LOGGED_TIME ? (
                          <MonthlyPerformanceChart />
                        ) : (
                          <SalaryChart />
                        )}
                      </HStack>
                    </VStack>
                  )}
                </Stack>
                {userId && totalWorkingTime && (
                  <VStack
                    padding={6}
                    background="white"
                    borderRadius="6px"
                    boxShadow="base"
                    height={{ base: '284px', lg: '284px' }}
                    width={{ base: 'calc(100% - 48px - 16px)', lg: 'calc(100% - 48px)' }}
                    alignItems="flex-start">
                    <Text marginY={0} color="gray.700" fontSize="18px" fontWeight="500" lineHeight="28px">
                      Labor Cost (million VND)
                    </Text>
                    <LaborCostChart />
                  </VStack>
                )}
              </>
            </LoadingChakra>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(DashboardUser)
