import { Link, Text } from '@chakra-ui/react'
import styles from './index.module.scss'

export interface MenuItemProps {
  href: string
  text: string
  isActive: boolean
  handleLinkClick: (e: React.MouseEvent) => void
}

const MenuItem = ({ href, text, isActive, handleLinkClick }: MenuItemProps) => {
  const linkStyle = isActive ? { backgroundColor: '#F7F7F7', color: '#2D3748' } : {}

  return (
    <Link
      href={href}
      padding={'6px 12px'}
      paddingBottom={0}
      width={'90%'}
      onClick={handleLinkClick}
      style={linkStyle}
      borderRadius={'6px'}
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Text
        fontWeight={isActive ? '500' : '400'}
        alignSelf={'flex-start'}
        fontSize={{ base: '16px' }}
        className={styles.headerBox}
        style={linkStyle}
        paddingBottom={'6px'}
        lineHeight={'24px'}>
        {text}
      </Text>
    </Link>
  )
}

export default MenuItem
