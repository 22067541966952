import { handleError } from 'API/error'
import {
  getStaffDocumentListForHrAPI,
  createInternalDocumentAPI,
  deleteInternalDocumentAPI,
  updateInternalDocumentAPI,
} from 'API/internalDocument'
import { IStaffDocumentFilter, IInternalDocumentItem, IStaffDocumentItem } from 'API/internalDocument/constants'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from 'store/rootStore'
import { Messages } from 'constants/index'

class UserStaffDocumentOfHRStore {
  rootStore: RootStore
  staffDocumentList: IStaffDocumentItem[]
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getStaffDocumentListForHR(filter?: IStaffDocumentFilter) {
    try {
      this.isLoading = true
      const data = await getStaffDocumentListForHrAPI({ filter })
      this.staffDocumentList = data
      this.isLoading = false
    } catch (error) {}
  }

  public async createStaffDocument(staffDocument: IInternalDocumentItem) {
    try {
      await createInternalDocumentAPI(staffDocument)
      toast.success(Messages.createInternalDocumentSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/staffDocumentOfHRStore.ts', 'createTodoOfHR')
    }
  }

  public async deleteStaffDocument(internalDocumentId: string) {
    try {
      await deleteInternalDocumentAPI(internalDocumentId)
      toast.success(Messages.deleteInternalDocumentSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/staffDocumentOfHRStore.ts', 'deleteTodoOfHR')
    }
  }

  public async updateStaffDocument(staffDocumentId: string, staffDocument: IInternalDocumentItem) {
    try {
      await updateInternalDocumentAPI(staffDocumentId, staffDocument)
      toast.success(Messages.updateInternalDocumentSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/staffDocumentOfHRStore.ts', 'createTodoOfHR')
    }
  }
}

export default UserStaffDocumentOfHRStore
