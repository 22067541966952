export enum EItemLink {
  USER_PROFILE_DETAIL = '#UserProfileDetail',
  PROJECT_RATINGS = '#ProjectRatings',
  SKILL_ANALYSIS = '#DevelopmentSkillAnalysis',
  LABOR_CONTRACT = '#LaborContract',
  USER_DATA = '#UserData',
  CURRICULUM_VITAE = '#CurriculumVitae',
}

export enum EIdItemLink {
  USER_PROFILE_DETAIL = 'UserProfileDetail',
  PROJECT_RATINGS = 'ProjectRatings',
  SKILL_ANALYSIS = 'DevelopmentSkillAnalysis',
  LABOR_CONTRACT = 'LaborContract',
  USER_DATA = 'UserData',
  CURRICULUM_VITAE = 'CurriculumVitae',
}

export enum EItemText {
  USER_PROFILE_DETAIL = 'User Profile Detail',
  PROJECT_RATINGS = 'Project Ratings',
  SKILL_ANALYSIS = 'Skill Analysis',
  LABOR_CONTRACT = 'Labor Contract',
  USER_DATA = 'User Data',
  CURRICULUM_VITAE = 'Curriculum Vitae',
}
