import { useState, useEffect } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Text,
  SimpleGrid,
  Stack,
  FormLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { getFormattedDateWith_YYYYMMDD, handleDatePickerChange } from 'utils/dateFormatUtils'
import styles from '../../../../index.module.scss'
import { IFixedProjectRatingModal } from './interfaces'

const FixedProjectRatingModal = (props: IFixedProjectRatingModal) => {
  const { isOpen, closeHandler, saveHandler, data } = props
  const methods = useForm()
  const { reset, handleSubmit } = methods
  const [errors, setErrors] = useState<any>({})
  useEffect(() => {
    if (data) {
      const parsedData = {
        ...data,
        currentApplyDate: getFormattedDateWith_YYYYMMDD(data.currentApplyDate),
        futureApplyDate: getFormattedDateWith_YYYYMMDD(data.futureApplyDate),
      }
      reset(parsedData)
    }
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="2xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form className="full-form" onSubmit={handleSubmit((data) => saveHandler(data))}>
          <ModalContent maxHeight="550px" position="fixed">
            <Box background="white" width="full">
              <ModalHeader>Edit User Project Ratings</ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
            </Box>
            <ModalBody overflowY="auto">
              <FormControl isInvalid={!!get(errors, 'value') ? true : false}>
                <FormInput name="projectName" label="Project" disabled={true} />
                <Stack direction={{ sm: 'row', md: 'column', xl: 'column' }}>
                  <Box width={{ sm: '50%', md: 'full' }}>
                    <Text paddingLeft="10px" className={`${styles.headerBox} ${styles.border}`}>
                      Current
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} gap={{ base: 4, lg: 6 }} width="full">
                      <FormInput name="currentFixedRate" label="Fixed Rate" />
                      <FormControl>
                        <FormLabel fontWeight="400">Apply Date</FormLabel>
                        <Controller
                          name="currentApplyDate"
                          control={methods.control}
                          render={({ field }) => (
                            <DatePicker
                              selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                              onChange={(date) => handleDatePickerChange(field, date)}
                              disabled={true}
                            />
                          )}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                  <Box width={{ sm: '50%', md: 'full' }}>
                    <Text paddingLeft="10px" className={`${styles.headerBox} ${styles.border}`}>
                      Future
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} gap={{ base: 4, lg: 6 }} width="full">
                      <FormInput name="futureFixedRate" label="Fixed Rate" />
                      <FormControl>
                        <FormLabel fontWeight="400">Apply Date</FormLabel>
                        <Controller
                          name="futureApplyDate"
                          control={methods.control}
                          render={({ field }) => (
                            <DatePicker
                              selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                              onChange={(date) => handleDatePickerChange(field, date)}
                            />
                          )}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                </Stack>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" onClick={closeHandler} customStyles={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="red">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(FixedProjectRatingModal)
