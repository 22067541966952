import { handleError } from 'API/error'
import {
  createTodoOfHrAPI,
  getTodoOfHRGroupsByMonthAPI,
  deleteTodoOfHrAPI,
  editTodoOfHrAPI,
  changeStateTodoOfHrAPI,
} from 'API/todoOfHR'
import { ITodoOfHR } from 'API/todoOfHR/constants'
import { ITodoOfHRGroupsByMonth } from 'API/todoOfHR/constants'
import dayjs from 'dayjs'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from 'store/rootStore'
import { Messages } from 'constants/index'
import { ITodoOfHRFilter } from 'containers/HumanResources/TodoList/constants'

class UserTodoOfHRStore {
  rootStore: RootStore
  selectedDateFromCalendar: string = dayjs().format('YYYY-MM-DD')
  todoOfHRGroupsByMonth: ITodoOfHRGroupsByMonth[]
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async createTodoOfHR(todoData: ITodoOfHR) {
    try {
      await createTodoOfHrAPI(todoData)
      toast.success(Messages.createTodoOfHRSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/todoOfHRStore.ts', 'createTodoOfHR')
    }
  }
  public async editTodoOfHR(todoData: ITodoOfHR) {
    try {
      await editTodoOfHrAPI(todoData)
      toast.success(Messages.editTodoOfHRSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/todoOfHRStore.ts', 'editTodoOfHR')
    }
  }

  public async changeStateHandler(newStateValue, todoOfHRId) {
    try {
      await changeStateTodoOfHrAPI(newStateValue, todoOfHRId)
      toast.success(Messages.updateStateTodoOfHRSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/todoOfHRStore.ts', 'changeStateHandler')
    }
  }

  public setSelectedDateFromCalendar(currentSelectedDate: string): void {
    this.selectedDateFromCalendar = currentSelectedDate
  }

  public async getTodoOfHRGroupsByMonth(filter?: ITodoOfHRFilter) {
    try {
      const data = await getTodoOfHRGroupsByMonthAPI({ filter })
      this.todoOfHRGroupsByMonth = data
    } catch (error) {
      handleError(error, 'src/store/admin/todoOfHRStore.ts', 'getTodoOfHRGroupsByMonth')
    }
  }

  public async deleteTodoOfHR(todoId: string) {
    try {
      await deleteTodoOfHrAPI(todoId)
      toast.success(Messages.deleteTodoOfHRSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/todoOfHRStore.ts', 'deleteTodoOfHR')
    }
  }
}

export default UserTodoOfHRStore
