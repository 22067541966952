import { EItemLink } from "containers/User/UserDetail/components/MenuDetail/MenuItem/constants"

export function handleScrollLogic(
  isManualClick: boolean,
  targetSection: string | null,
  activeSection: string,
  setActiveSection: (section: string) => void
): void {
  if (isManualClick) {
    return
  }

  const sections = Object.values(EItemLink)
    .map((link) => document.getElementById(link.slice(1)))
    .filter(Boolean)

  const currentScrollTop = window.scrollY
  const navbarHeight = 90
  const scrollPosition = currentScrollTop + navbarHeight
  const windowHeight = window.innerHeight
  const documentHeight = document.documentElement.scrollHeight
  const threshold = 50

  if (currentScrollTop + windowHeight >= documentHeight - 10) {
    setActiveSection(EItemLink.CURRICULUM_VITAE)
    return
  }

  if (targetSection) {
    const targetElement = document.getElementById(targetSection.slice(1))
    if (targetElement) {
      const targetTop = targetElement.offsetTop - navbarHeight - threshold
      const targetBottom = targetTop + targetElement.offsetHeight + threshold

      if (scrollPosition >= targetTop && scrollPosition <= targetBottom) {
        setActiveSection(targetSection)
        return
      }
    }
  }

  for (const section of sections) {
    if (!section) {
      continue
    }

    const sectionTop = section.offsetTop - navbarHeight - threshold
    const sectionBottom = sectionTop + section.offsetHeight + threshold

    if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
      const newActiveSection = `#${section.id}`
      if (activeSection !== newActiveSection) {
        setActiveSection(newActiveSection)
      }
      break
    }
  }
}