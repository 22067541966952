export enum ECreatableOptionTypes {
  LEVEL_OF_USER = 'level',
  TITLE_OF_USER = 'title',
  PAYMENT_CATEGORY_OF_USER = 'payment category of user',
  DOCTYPE_OF_INTERNAL_DOCS = 'doctype',
}

export const EPaymentCategoryOfUser = {
  PAYROLL: 'Payroll',
  FREELANCER_FEE: 'Freelancer Fee',
  MIXED_PAYROLL_FREELANCER_FEE: 'Mix Payroll & Freelancer Fee',
}

export enum EPaymentCategoryOfUserEnum {
  PAYROLL = 'Payroll',
  FREELANCER_FEE = 'Freelancer Fee',
  MIXED_PAYROLL_FREELANCER_FEE = 'Mix Payroll & Freelancer Fee',
}

export enum EStatusOfPaymentRequest {
  PENDING = 'Pending',
  DENIED = 'Denied',
  APPROVED = 'Approved',
}

export enum EContractTypes {
  LABOR_CONTRACT = 'Labor Contract',
  FREELANCER_CONTRACT = 'Freelancer Contract',
  CONFIDENTIALITY_AGREEMENT = 'Confidentiality Agreement',
}

export enum EPriorityType {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export enum EStateType {
  TODO = 'Todo',
  IN_PROGRESS = 'In Progress',
  NEED_REVIEW = 'Need Review',
  FIXING = 'Fixing',
  COMPLETE = 'Complete',
}

export enum EAbbreviatedContractTypesVI {
  LABOR_CONTRACT = 'HDLD-UDT',
  FREELANCER_CONTRACT = 'HDDV-UDT',
  CONFIDENTIALITY_AGREEMENT = 'TTBM-UDT',
}

export enum EContractLanguages {
  VIETNAMESE = 'VIETNAMESE',
  BILINGUAL = 'BILINGUAL',
}

export enum ELaborContractCategories {
  OFFICIAL = 'OFFICIAL',
  PROBATION = 'PROBATION',
}

export enum ELaborContractTerm {
  INDEFINITE_TERM = 'Indefinite term',
  DEFINITE_TERM = 'Definite term',
}

export enum EPaymentCategoryOfProject {
  HOURLY = 'Hourly',
  FIXED_PRICE = 'Fixed Price',
}

export enum ERoleOfUser {
  ALL = 'all',
  USER = 'user',
  ADMIN = 'admin',
}

export enum EPartnerType {
  ORGANIZATIONS = 'organizations',
  PERSONAL = 'personal',
}
export enum EYesNoOption {
  YES = 'yes',
  NO = 'no',
}
export enum EYesNoOptionWithCapitalization {
  YES = 'Yes',
  NO = 'No',
}

export enum EPartnerCategory {
  EMPLOYEE = 'employee',
  FREELANCER = 'freelancer',
  OWNER = 'owner',
  CLIENT = 'client',
  SUPPLIER = 'supplier',
  BANK = 'bank',
  SET = 'set - software engineer training',
}

export enum EActivityObjectType {
  TIME_SHEET = 'timesheet',
  USER = 'user',
  PROJECT = 'project',
  INFORMATION = 'information',
  POLICY = 'policy',
  SETTING = 'setting',
  FEEDBACK = 'feedback',
}

export enum EFeatureFlags {
  BACKEND_V2 = 'backend_v2',
  DIAGRAM = 'diagram',
  TIMESHEET_V2 = 'timesheet_v2',
  NODE_DETAIL_OPTIONS = 'diagram_node_detail_options',
}

export enum EStorageKeys {
  BACKEND_URL = 'backendUrl',
}

export enum EEnvironment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum EUserEducation {
  UNDERGRADUATE = 'Undergraduate',
  BACHELOR = 'Bachelor',
  MASTER = 'Master',
  DOCTOR = 'Doctor',
  CERTIFICATE = 'Certificate',
}

export enum EUserEducationVI {
  UNDERGRADUATE = 'Đại học',
  BACHELOR = 'Cử nhân',
  MASTER = 'Thạc sĩ',
  DOCTOR = 'Tiến sĩ',
  CERTIFICATE = 'Chứng chỉ',
}

export enum EUserJobTitleVI {
  ACCOUNTING = 'Kế toán viên',
  SALE = 'Nhân viên kinh doanh',
  BA = 'Chuyên viên phân tích nghiệp vụ',
  ENGINEER = 'Kỹ sư phần mềm',
  QA_QC = 'Chuyên viên kiểm thử - kiểm tra chất lượng',
}

export enum EGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum EGenderVI {
  MALE = 'Nam',
  FEMALE = 'Nữ',
}

export enum ECreatableOptionScope {
  USER = 'user',
  INTERNAL_DOCUMENT = 'internal-document',
}

export enum ETypeOfFilterOfHeatMap {
  FILTER_BY_PROJECT = 'filter by project',
  FILTER_BY_USER = 'filter by user',
  FILTER_ALL = 'filter all',
}

export enum ETypeOfFilter {
  FILTER_BY_PROJECT = 'filter by project',
  FILTER_BY_USER = 'filter by user',
}

export enum EDiagramNodeTypes {
  PROJECT = 'project',
  USER = 'user',
}

export enum ELeaveRequestStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum ELeaveDefaultReason {
  SICK = 'Sick',
  REMOTE = 'Remote',
  HOLIDAY = 'Holiday',
}

export enum EProjectTypes {
  NORMAL_PROJECT = 'normal-project',
  EXTRA_PROJECT = 'extra-project',
  EXTERNAL_PROJECT = 'external-project',
}

export enum EWorkingHourPeriods {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum EPerformanceReportTypes {
  SALARY = 'salary',
  LOGGED_TIME = 'logged-time',
}

export enum EUserStatusLabel {
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  ACTIVE = 'Active',
}

export enum EPartnerStatus {
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum EPathName {
  DETAIL = 'detail',
  PROJECT = 'project',
  NEW = 'new',
  INDEX = 'index',
  PROFILE = 'profile-page',
}

export enum EActionType {
  CREATE = 'Create',
  EDIT = 'Edit',
}

export enum EFirstPathElement {
  USER_DASHBOARD = 'user-dashboard-page',
  ADMIN_DASHBOARD = 'admin-dashboard-page',
  TIMESHEET = 'timesheet-page',
  PROJECT = 'project-page',
  POLICIES = 'policy-page',
  ANNOUNCEMENTS = 'announcement-page',
  PAYMENTS = 'payment-page',
  QR_CODE = 'qr-code-page',
  CONTRACT = 'contract-page',
  BIRTHDAY = 'birthday-page',
  DASHBOARD = 'admin-dashboard-page',
  DIAGRAM = 'diagram-page',
  PARTNER = 'partner-page',
  USER = 'user-page',
  INTERNAL_DOCUMENT = 'internal-document-page',
  SETTINGS = 'setting-page',
  LEAVE_MANAGEMENT = 'leave-management-page',
  TODO_LIST_OF_HR = 'todo-list-ofHR-page',
  STAFF_DOCUMENT_OF_HR = 'staff-document-ofHR-page',
}

export enum EUploadedImageType {
  AVATAR = 'avatar',
  LOGO = 'logo',
  BADGE_IMAGE = 'badge-image',
}

export enum EConditionGetList {
  ALL = 'all',
  ACTIVE = 'active',
}

export enum EUploadBadgeImageModalType {
  LEVEL = 'level',
  TITLE = 'title',
}

export enum EUserDetailTitle {
  USER_PROFILE_DETAIL = 'User Profile Detail',
  PROJECT_RATINGS = 'Project Ratings',
  LABOR_CONTRACT = 'Labor Contract',
  SKILL_ANALYSIS = 'Development Skill Analysis',
  USER_DATA = 'User Data',
  USER_PROFILE_PICTURE = 'User Profile Picture',
  CURRICULUMN_VITAE = 'Curriculumn Vitae',
}

export enum EDashboardSubTitle {
  STATISTIC = 'Statistic',
  HEATMAP = 'Heat Map',
  SKILL = 'Skill Summary',
  PERSONAL = 'Personal',
}

export enum ETimesheetSubTitle {
  YOUR_TIMESHEET = 'Your Timesheet',
  REPORT = 'Report',
  HISTORY = 'History',
  SUPERVISOR = 'Supervisor',
  BILL_REPORT = 'Bill Report',
  OVERVIEW = 'Overview',
}

export enum ELeaveManagementSubTitle {
  MY_LEAVE = 'My Leave',
}

export enum ELeaveStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum ELeaveType {
  SICK = 'Sick',
  HOLIDAY = 'Holiday',
  REMOTE = 'Remote',
}

export enum ETimeSelection {
  EIGHT_AM = '08:00',
  ONE_PM = '13:00',
  FIVE_PM = '17:00',
}

export enum EStatusOfLateLoggingTime {
  NORMAL = 'normal',
  EMERGENCY = 'emergency',
  WARNING = 'warning',
}

export enum EStatusOfDuration {
  NORMAL = 'normal',
  EMERGENCY = 'emergency',
  WARNING = 'warning',
}

export enum EResponseStatus {
  ERROR = 'Error',
  SUCCESS = 'Success',
}

export enum EStatus {
  INVALID = 'Invalid',
  VALID = 'Valid',
}

export enum EPaymentCategoryFromClientOfProject {
  FIXED_SCOPE = 'Fixed Scope',
  TIME_AND_MATERIAL = 'Time And Material',
  MONTHLY_FIXED_SCOPE = 'Monthly Fixed Scope',
}

export enum EChartType {
  TIME_TOTAL_CHART = 'Time Total Chart',
  REVENUE_CHART_FOR_TIME_AND_MATERIAL = 'Revenue Chart For Time And Material',
  REVENUE_CHART_FOR_MONTHLY_FIXED_SCOPE = 'Revenue Chart For Month Fixed Scope',
  PROFIT_CHART = 'profitChart',
}

export enum TooltipTitle {
  VIEW_DETAIL = 'View Detail',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  COMMENT = 'Comment',
}

export enum EInternalDocScope {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}
