import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/interface'
import { AxiosResponse } from 'axios'
import {
  ICreatableOptionListWithFilterRequest,
  ICreatableOptionListWithFilterResponse,
  ICreatableOptionListResponse,
  ICreatableOption,
  ICreatableOptionListPaginationRequest,
  ICreatableOptionDataFromCreateForm,
} from './constants'

export async function getListCreatableOptionsAPI(
  data: ICreatableOptionListWithFilterRequest
): Promise<ISuccessResponse<ICreatableOptionListWithFilterResponse>> {
  try {
    return apiV2.post(APIRouters.creatableOption.listWithFilter.value, data)
  } catch (error) {
    throw error
  }
}

export async function getCreatableOptionListWithPaginationAPI(
  payload: ICreatableOptionListPaginationRequest
): Promise<ICreatableOptionListResponse> {
  try {
    const url: string = APIRouters.creatableOption.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      creatableOptions: response?.data?.creatableOptions || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createCreatableOptionAPI(creatableOption: ICreatableOptionDataFromCreateForm): Promise<string> {
  try {
    const url: string = APIRouters.creatableOption.value
    const fullUrl: string = `${url}?type=${creatableOption.type}`
    const response: AxiosResponse = await apiV2.post(fullUrl, creatableOption)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateCreatableOptionAPI(creatableOption: ICreatableOption): Promise<string> {
  try {
    const url: string = APIRouters.creatableOption.creatableOptionId.value(creatableOption.id)
    const response: AxiosResponse = await apiV2.patch(url, creatableOption)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateCreatableOptionsAPI(creatableOptions: ICreatableOption[]): Promise<string> {
  try {
    const url: string = APIRouters.creatableOption.updateMany.value
    const response: AxiosResponse = await apiV2.patch(url, creatableOptions)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteCreatableOptionAPI(creatableOptionId: string, type?: string): Promise<string> {
  try {
    const url: string = APIRouters.creatableOption.creatableOptionId.value(creatableOptionId)
    const fullUrl: string = `${url}?${type ? `type=${type}` : ''}`
    const response: AxiosResponse = await apiV2.delete(fullUrl)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
