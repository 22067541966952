import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react'
import Button from 'components/Button'
import SingleSelect from 'components/SingleSelect'
import { useForm, FormProvider } from 'react-hook-form'
import { textBlue500 } from 'theme/globalStyles'
import { PartnerCategoryForStaffDocument } from 'constants/defaultOptionList'
import { EPartnerCategory } from 'constants/enum'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { IStaffDocumentFilterModalProps } from './interface'

const StaffDocumentFilterModal = (props: IStaffDocumentFilterModalProps) => {
  const { isOpen, closeHandler, submitHandler } = props
  const methods = useForm()
  const { handleSubmit, reset, getValues } = methods
  const partnerCategoryOptions = createOptionsOfSelect(PartnerCategoryForStaffDocument)

  function resetForm() {
    reset({
      filteredPartnerCategory: EPartnerCategory.EMPLOYEE,
    })
  }

  const onSubmit = () => {
    const { filteredPartnerCategory } = getValues()
    const data = { filteredPartnerCategory: filteredPartnerCategory.value }
    submitHandler(data)
    closeHandler()
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeHandler} size="sm">
      <DrawerOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent>
            <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            <DrawerHeader>Filter</DrawerHeader>
            <DrawerBody>
              <VStack gap={5}>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <SingleSelect
                      name="filteredPartnerCategory"
                      label="Partner Category"
                      placeholder="Select Partner Category"
                      optionsData={partnerCategoryOptions}
                    />
                  </VStack>
                </SimpleGrid>
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="space-between">
              <Button customStyles={{ color: textBlue500 }} variant="ghost" onClick={resetForm}>
                Reset
              </Button>
              <HStack>
                <Button variant="outline" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button variant="solid" type="submit">
                  Apply Filter
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </FormProvider>
    </Drawer>
  )
}

export default StaffDocumentFilterModal
