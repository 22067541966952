import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { ITodoOfHR, ITodoOfHRGroupsByMonthRequest, ITodoOfHRGroupsByMonth } from './constants'

export async function createTodoOfHrAPI(payload: ITodoOfHR): Promise<void> {
  try {
    const url: string = APIRouters.todoOfHR.create.value
    await apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function editTodoOfHrAPI(payload: ITodoOfHR): Promise<void> {
  try {
    const url: string = APIRouters.todoOfHR.update.todoOfHRId.value(payload.id)
    await apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function changeStateTodoOfHrAPI(newStateValue: string, todoOfHRId: string): Promise<void> {
  try {
    const url: string = APIRouters.todoOfHR.changeState.todoOfHRId.value(todoOfHRId)
    await apiV2.post(url, {
      state: newStateValue,
    })
  } catch (error) {
    throw error
  }
}

export async function getTodoOfHRGroupsByMonthAPI(
  payload?: ITodoOfHRGroupsByMonthRequest
): Promise<ITodoOfHRGroupsByMonth[]> {
  try {
    const url = APIRouters.todoOfHR.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function deleteTodoOfHrAPI(todoId: string): Promise<void> {
  try {
    await apiV2.delete(APIRouters.todoOfHR.delete.todoOfHRId.value(todoId))
  } catch (error) {
    throw error
  }
}
