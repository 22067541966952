import {
  Text,
  VStack,
  SimpleGrid,
  Container,
  Divider,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Link,
} from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { UserRole, genderOptions, educationOptions, citizenIssuePlaceOptions } from 'constants/defaultOptionList'
import { ECreatableOptionTypes, EPaymentCategoryOfUser, EYesNoOptionWithCapitalization } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { ICreatableOption } from 'constants/schema'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import {
  createOptionsOfSelectFromDropdown,
  createOptionsOfReactSelectFromDB,
  createOptionsBadgeImageOfReactSelectFromDB,
} from 'utils/dropdownOptionUtils'
import PasswordField from '../../../components/PasswordFiled'
import { isSupervisor, isDownloadCV, needToSendEmail } from '../../constant'
import { IProfileDetailProps } from './interfaces'

const EditUserForm = (props: IProfileDetailProps) => {
  const {
    isEdit,
    userId,
    partnerList,
    projectList,
    creatableOptionList,
    methods,
    name,
    updateProjectRatingHandler,
    technologyList,
    isFirstTimeUpdateUser,
  } = props
  const { watch } = methods
  const diagramUrl: string = `${frontendRoutes.diagramPage.diagramAdmin.value}?userId=${userId}`
  const checkIsSupervisor = watch(`${name}.isSupervisor`)
  const options = getValidArray(creatableOptionList)
  const titleOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions = createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const paymentCategoryOfUser = watch(`${name}.paymentCategoryId`)
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const projectsOptions = createOptionsOfReactSelectFromDB(projectList)
  const technologiesOptions = createOptionsOfReactSelectFromDB(technologyList)
  const userProjects: IOption[] = watch(`${name}.projects`) ?? []
  const extraProjectsOptions: IOption[] = createOptionsOfSelectFromDropdown(userProjects)
  const isFreelancerToo =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  const isReceivedPayroll =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.PAYROLL ||
        paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  if (!isEdit) {
    return null
  }
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8} id="UserProfileDetail">
      <VStack alignItems={'initial'} margin={{ base: 6, lg: 6 }} marginTop={{ base: 0, lg: 0 }} height="full" gap={6}>
        <Text fontWeight={500} marginBottom={0} lineHeight={7} fontSize={'18px'}>
          User Profile Detail
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <SingleSelect name={`${name}.partnerId`} label="Partner" optionsData={partnersOptions} />
          </Stack>
          <FormInput name={`${name}.displayName`} label="Display Name (Optional)" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormInput name={`${name}.email`} label="Email" isRequired disabled />
          <FormInput name={`${name}.phone`} label="Phone Number" type="tel" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <PasswordField name={`${name}.resetPassword`} label="Reset Password" />
          </Stack>
          {isFirstTimeUpdateUser === EYesNoOptionWithCapitalization.YES && (
            <GroupRadio
              name={`needToSendWelcomeEmail`}
              isEdit
              data={needToSendEmail}
              label="Need to send welcome email?"
            />
          )}
        </SimpleGrid>
        <Divider color="gray/200" />
        <SimpleGrid width="full" columns={2} gap={{ base: 6, lg: 8 }}>
          <FormInput name={`${name}.fullName`} label="Full Name" isRequired />
          <SingleSelect name={`${name}.currentGender`} label="Gender" optionsData={genderOptions} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.placeOfBirth`} label="Place Of Origin (Optional)" />
          <FormControl>
            <FormLabel fontWeight="400">Date of Birth (Optional)</FormLabel>
            <Controller
              name={`${name}.dateOfBirth`}
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                  onChange={(date) => handleDatePickerChange(field, date)}
                />
              )}
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.address`} label="Place of Residence (Optional)" />
          <FormInput name={`${name}.nationality`} label="Nationality (Optional)" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.IDNumber`} label="ID Number (Optional)" />
          <FormControl>
            <FormLabel fontWeight="400">ID Issue Date (Optional)</FormLabel>
            <Controller
              name={`${name}.IDIssueDate`}
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                  onChange={(date) => handleDatePickerChange(field, date)}
                />
              )}
            />
          </FormControl>
          <SingleSelect
            placeHolder="- Select ID Issue Place -"
            name={`${name}.IDIssuePlace`}
            label="ID Issue Place (Optional)"
            optionsData={citizenIssuePlaceOptions}
            isCreatable
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <SingleSelect name={`${name}.education`} label="Education (Optional)" optionsData={educationOptions} />
          </Stack>
          <FormInput name={`${name}.major`} label="Major (Optional)" />
        </SimpleGrid>
        <Divider color="gray/200" />
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormControl>
            <FormLabel fontWeight="400">Join Date (Optional)</FormLabel>
            <Controller
              name={`${name}.joinDate`}
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                  onChange={(date) => handleDatePickerChange(field, date)}
                />
              )}
            />
          </FormControl>
          <GroupRadio name={`${name}.role`} isEdit data={UserRole} label="Role" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormInput name={`${name}.bankName`} label="Bank Name (Optional)" type="textarea" />
          <FormInput name={`${name}.bankAccount`} label="Bank Account (Optional)" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <VStack>
            <SingleSelect
              name={`${name}.titleId`}
              label="Title"
              optionsData={titleOptions}
              isRequire
              containerStyle={{ lineHeight: '0px' }}
            />
          </VStack>

          <VStack>
            <SingleSelect
              name={`${name}.levelId`}
              label="Level"
              optionsData={levelOptions}
              isRequire
              containerStyle={{ lineHeight: '0px' }}
            />
          </VStack>
        </SimpleGrid>
        <SimpleGrid width="full" columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }}>
          <FormControl>
            <HStack justifyContent="space-between" align="flex-start">
              <FormLabel color="gray.700" fontWeight={400}>
                Projects (Optional)
              </FormLabel>
              {isValidArray(userProjects) && (
                <Link color="blue.600" fontWeight={400} target="_blank" href={diagramUrl}>
                  Edit Assignment
                </Link>
              )}
            </HStack>
            <VStack>
              <MultipleSelect
                name={`${name}.projects`}
                placeholder="Select Projects"
                options={projectsOptions}
                selectHandler={updateProjectRatingHandler}
                allowSelectAll={false}
                selectedEntityName="Projects"
              />
              <VStack alignSelf={'flex-start'}>
                <GroupChips fieldName={`${name}.projects`} selectDataHandler={updateProjectRatingHandler} />
              </VStack>
            </VStack>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <SingleSelect
            name={`${name}.paymentCategoryId`}
            label="Payment Category"
            optionsData={paymentCategoryOptions}
            isRequire
          />
          <VStack>
            <MultipleSelect
              label="Extra Projects (Optional)"
              name={`${name}.extraProjects`}
              placeholder="Select Extra Projects"
              options={extraProjectsOptions}
              isDisabled={!isFreelancerToo}
              allowSelectAll={false}
              selectedEntityName="Extra Projects"
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName={`${name}.extraProjects`} />
            </VStack>
          </VStack>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.defaultRating`} label="Default Rate" isRequired type="number" step={0.01} />
          <FormInput
            name={`${name}.fixedSalary`}
            label="Net Salary (VND)"
            disabled={!isReceivedPayroll}
            type="number"
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name={`${name}.isSupervisor`} isEdit data={isSupervisor} label="Is Supervisor" />
          <VStack>
            <MultipleSelect
              label="Projects of Supervisor (Optional)"
              name={`${name}.projectsOfSupervisor`}
              isDisabled={!(checkIsSupervisor === EYesNoOptionWithCapitalization.YES)}
              placeholder="Select Projects Of Supervisor"
              options={extraProjectsOptions}
              allowSelectAll={false}
              selectedEntityName="Projects Of Supervisor"
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName={`${name}.projectsOfSupervisor`} />
            </VStack>
          </VStack>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <VStack>
            <MultipleSelect
              label="External Projects (Optional)"
              name={`${name}.externalProjects`}
              placeholder="Select External Projects"
              options={extraProjectsOptions}
              selectedEntityName="External Projects"
              allowSelectAll={false}
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName={`${name}.externalProjects`} />
            </VStack>
          </VStack>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name={`${name}.allowToDownloadCV`} isEdit data={isDownloadCV} label="Is Download CV ?" />
          <VStack>
            <MultipleSelect
              label="Technology (Optional)"
              name={`${name}.technologies`}
              placeholder="Select Technology"
              selectedEntityName="Technology"
              options={technologiesOptions}
              allowSelectAll={false}
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName={`${name}.technologies`} />
            </VStack>
          </VStack>
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default EditUserForm
