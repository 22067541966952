import { ChangeEvent, useRef, useState, useEffect } from 'react'
import { HStack, Avatar, Input } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { uploadImage } from 'API/upload'
import { IUploadImageResponse } from 'API/upload/constants'
import Button from 'components/Button'
import { useForm, useFormContext } from 'react-hook-form'
import { generateImageURL } from 'utils/imageUtils'

interface IUserAvatar {
  type?: string
  name?: string
  afterUploadHandler?
}

const UserAvatar = (props: IUserAvatar) => {
  const { type, name, afterUploadHandler } = props
  const avatarRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, selectImage] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [avatarURL, setAvatarURL] = useState(null)

  const { getValues } = useFormContext()

  const avatarURLForm = getValues(name)
  const fullAvatarURL = generateImageURL(avatarURLForm, 'avatar')

  const { register } = useForm()

  function handleChooseAvatar(): void {
    avatarRef.current?.click()
  }

  async function handleChangeAvatar(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      const file: File = event?.target?.files[0]
      const formData: FormData = new FormData()
      formData.append('myImage', file)
      const response: ISuccessResponse<IUploadImageResponse> = await uploadImage(formData, type)
      if (!!event?.target?.files?.[0]) {
        !!event?.target?.files?.[0] && selectImage(event?.target?.files?.[0])
      }
      afterUploadHandler(response?.data?.fileName)
    } catch (error) {
      handleError(error, 'src/containers/Profile/components/UserAvatar/index.tsx', 'handleChangeAvatar')
    }
  }

  useEffect(() => {
    setAvatarURL(fullAvatarURL)
  }, [avatarURLForm])

  useEffect(() => {
    if (!selectedImage) {
      setPreview('')
      return
    }
    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)

    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  return (
    <HStack>
      <Avatar
        bg="#285E61"
        size="2xl"
        color={'white'}
        fontWeight={'normal'}
        name={name}
        src={preview.length === 0 ? avatarURL : preview}>
        <Button
          leftIcon="edit-avatar.svg"
          variant="outline"
          colorScheme="gray"
          width="50px"
          height="50px"
          onClick={handleChooseAvatar}
          customStyles={{
            borderRadius: '100%',
            position: 'absolute',
            right: '0%',
            top: '64,91%',
            bottom: '0%',
          }}></Button>
        <Input
          {...register('file')}
          type="file"
          ref={avatarRef}
          accept={'image/*'}
          display="none"
          onChange={handleChangeAvatar}
        />
      </Avatar>
    </HStack>
  )
}

export default UserAvatar
