import { handleError } from 'API/error'
import { ISuccessAggregateResponse } from 'API/interface'
import {
  userCreateLeaveRequestAPI,
  userUpdateLeaveRequestAPI,
  userDeleteLeaveRequestAPI,
  userAggregateLeaveRequestAPI,
} from 'API/leaveRequest'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { ILeaveRequest, IUserLeaveRequestAggregatePayload, IUserLeaveRequestFilter } from 'types/leaveRequest'
import { ILeaveRequestFormData } from 'types/leaveRequest'
import { Messages } from 'constants/index'
import RootStore from '../rootStore'
import { defaultPage, defaultPageSize } from 'constants/index'
import queryString from 'query-string'
import { THistory } from 'types/common'

class UserLeaveRequestStore {
  rootStore: RootStore
  leaveRequestList: ILeaveRequest[]
  isLoadingList: boolean = false
  isLoadingSubmit: boolean = false
  totalCount: number = 0

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async userAggregateLeaveRequest(history?: THistory ,payload?: IUserLeaveRequestAggregatePayload) {
    try {
      this.isLoadingList = true
      const searchString = history?.location?.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', defaultPage))
      const response: ISuccessAggregateResponse<ILeaveRequest[]> = await userAggregateLeaveRequestAPI({
        currentPage : payload?.currentPage ?? currentPage,
        pageSize: defaultPageSize,
        status: payload?.status,
        period: payload?.period,
        date: payload?.date
      })
      this.leaveRequestList = response?.data
      this.totalCount = response?.totalCount ?? 0
    } catch (error) {
      handleError(error, 'src/store/user/leaveRequestStore.ts', 'userAggregateLeaveRequest')
    } finally {
      this.isLoadingList = false
    }
  }

  public async userCreateLeaveRequest(data: ILeaveRequestFormData) {
    try {
      this.isLoadingSubmit = true
      await userCreateLeaveRequestAPI(data)
      toast.success(Messages.createLeaveRequestSuccess)
    } catch (error) {
      handleError(error, 'src/store/user/leaveRequestStore.ts', 'userCreateLeaveRequest', true)
    } finally {
      this.isLoadingSubmit = false
    }
  }

  public async userUpdateLeaveRequest(id: string, payload: ILeaveRequestFormData) {
    try {
      this.isLoadingSubmit = true
      await userUpdateLeaveRequestAPI(id, payload)
    } catch (error) {
      handleError(error, 'src/store/user/leaveRequestStore.ts', 'userUpdateLeaveRequest', true)
    } finally {
      this.isLoadingSubmit = false
    }
  }

  public async userDeleteLeaveRequest(id: string): Promise<void> {
    try {
      this.isLoadingSubmit = true
      await userDeleteLeaveRequestAPI(id)
    } catch (error) {
      handleError(error, 'src/store/user/leaveRequestStore.ts', 'userDeleteLeaveRequest')
    } finally {
      this.isLoadingSubmit = false
    }
  }
}

export default UserLeaveRequestStore
