import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import Button from 'components/Button'
import TagColorSelect from 'components/TagColorSelect'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { ILeaveType } from 'types/leaveRequest'
import { getModalTitle } from 'utils/commonUtils'

interface ILeaveTypeModal {
  isOpen?: boolean
  content?: string
  closeHandler?: () => void
  actionType?: string
  saveHandler?: (item: object) => void
  data?: ILeaveType
  isSubmitSuccess?: boolean
}

const LeaveTypeModal = (props: ILeaveTypeModal) => {
  const { isOpen, closeHandler, saveHandler, actionType, data, isSubmitSuccess } = props
  const [nameField, setNameField] = useState<any>(null)
  const [errors, setErrors] = useState<any>({})
  const [tagColor, setTagColor] = useState<any>(null)

  useEffect(() => {
    if (data) {
      setNameField(data.name)
      setTagColor(data.tagColor)
    } else {
      setNameField(null)
      setTagColor(null)
    }
    if (isSubmitSuccess) {
      setNameField(null)
      setTagColor(null)
    }
  }, [data, isSubmitSuccess])

  function nameInputOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputName = event.target.value
    if (inputName) {
      setErrors({
        ...errors,
        value: null,
      })
    }
    setNameField(inputName)
  }

  function selectTagColor(color: string): void {
    setTagColor(color)
  }

  function OKClickHandler(): void {
    let isValidForm = true
    const data = {
      name: nameField,
      tagColor,
      id: get(props, 'data.id', ''),
    }
    let newErrors = {}
    if (!nameField) {
      isValidForm = false
      newErrors = {
        ...errors,
        value: {
          type: 'required',
          message: 'Leave Type name is required',
        },
      }
    }
    setErrors(newErrors)
    if (isValidForm) {
      saveHandler(data)
    }
  }

  const modalTitle = getModalTitle(actionType, 'Leave Type')

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="320px">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
        <ModalBody paddingY={0}>
          <FormControl isInvalid={!!get(errors, 'value') ? true : false}>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Leave Type Name
            </FormLabel>
            <Input
              value={nameField}
              autoFocus={true}
              placeholder="Enter Leave Type name"
              onChange={nameInputOnChange}
              boxSizing="border-box"
            />
            <FormErrorMessage>{get(errors, 'value.message', '')}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Tag Color (optional)
            </FormLabel>
            <TagColorSelect selectedColor={tagColor} selectTagColor={selectTagColor} actionType={actionType} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: '10px' }} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={OKClickHandler} variant="solid">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default observer(LeaveTypeModal)
