import { Box, Text } from '@chakra-ui/react'
import { ReactComponent as ArrowRight } from 'assets/images/svg/arrow-right-s-line.svg'
import { ReactComponent as Dashboard } from 'assets/images/svg/dashboard.svg'
import { ReactComponent as Diagram } from 'assets/images/svg/diagram.svg'
import { ReactComponent as InternalDoc } from 'assets/images/svg/internal-doc.svg'
import { ReactComponent as Partner } from 'assets/images/svg/partner.svg'
import { ReactComponent as Settings } from 'assets/images/svg/settings.svg'
import { ReactComponent as User } from 'assets/images/svg/user.svg'
import { Link, useHistory } from 'react-router-dom'
import { ERoleOfUser, EFirstPathElement } from 'constants/enum'
import { ESidebarHeaderTitle, ESidebarMenuContent, ESubMenuName, IRouteName } from '../../constant'
import { getRouteName, handleRedirect } from '../../utils'
import SubMenu from '../Submenu'
interface ISubmenuProps {
  getClassName?: (name: string) => string
  handleOpenSubmenu?: (name: string) => void
  actionTab: string
  subMenu: string
  isCollapsed: boolean
  subMenuListOfSettingTab?: any[]
  subMenuListOfAdminDashboardTab?: any[]
  setIsCollapsed?: () => void
  handleCollapse?: () => void
}

const SubmenuAdmin = (props: ISubmenuProps) => {
  const {
    getClassName,
    handleOpenSubmenu,
    isCollapsed,
    subMenu,
    subMenuListOfSettingTab,
    subMenuListOfAdminDashboardTab,
    handleCollapse,
  } = props
  const role: string = window.localStorage.getItem('userRole')
  const isAdmin: boolean = role === ERoleOfUser.ADMIN
  const route: IRouteName = getRouteName(isAdmin)
  const history = useHistory()
  const currentPath = history.location.pathname
  return (
    <>
      <Box className="sidebar-header">{ESidebarHeaderTitle.MANAGEMENT}</Box>
      <SubMenu
        subMenuList={subMenuListOfAdminDashboardTab}
        isOpen={subMenu === ESubMenuName.ADMIN_DASHBOARD && !isCollapsed}
        className="admin-dashboard"
        toggleCollapsedSidebar={handleCollapse}>
        <Box
          className={getClassName(EFirstPathElement.ADMIN_DASHBOARD)}
          onClick={() => handleOpenSubmenu(ESubMenuName.ADMIN_DASHBOARD)}>
          <Text userSelect="none">
            <Dashboard className="icon" /> {ESidebarMenuContent.ADMIN_DASHBOARD}
          </Text>
          {!isCollapsed && (
            <ArrowRight className={`icon arrowIcon ${subMenu === ESubMenuName.ADMIN_DASHBOARD ? '' : 'rotateArrow'}`} />
          )}
        </Box>
      </SubMenu>
      <Box className={getClassName(EFirstPathElement.DIAGRAM)}>
        <Link to={route.diagramRoute} onClick={() => handleRedirect(route.diagramRoute, currentPath, handleCollapse)}>
          <Diagram className="icon" /> {ESidebarMenuContent.STRATEGY_DIAGRAM}
        </Link>
      </Box>
      <Box className={getClassName(EFirstPathElement.PARTNER)}>
        <Link to={route.partner} onClick={() => handleRedirect(route.partner, currentPath, handleCollapse)}>
          <Partner className="icon" /> {ESidebarMenuContent.PARTNER}
        </Link>
      </Box>
      <Box className={getClassName(EFirstPathElement.USER)}>
        <Link to={route.userRoute} onClick={() => handleRedirect(route.userRoute, currentPath, handleCollapse)}>
          <User className="icon" /> {ESidebarMenuContent.USERS}
        </Link>
      </Box>
      <Box className={getClassName(EFirstPathElement.INTERNAL_DOCUMENT)}>
        <Link
          to={route.internalDocument}
          onClick={() => handleRedirect(route.internalDocument, currentPath, handleCollapse)}>
          <InternalDoc className="icon" /> {ESidebarMenuContent.INTERNAL_DOCUMENT}
        </Link>
      </Box>
      <SubMenu
        subMenuList={subMenuListOfSettingTab}
        isOpen={subMenu === ESubMenuName.SETTINGS && !isCollapsed}
        className="settings"
        toggleCollapsedSidebar={handleCollapse}>
        <Box
          className={getClassName(EFirstPathElement.SETTINGS)}
          onClick={() => handleOpenSubmenu(ESubMenuName.SETTINGS)}>
          <Text userSelect="none">
            <Settings className="icon" /> {ESidebarMenuContent.SETTINGS}
          </Text>
          {!isCollapsed && (
            <ArrowRight className={`icon arrowIcon ${subMenu === ESubMenuName.SETTINGS ? '' : 'rotateArrow'}`} />
          )}
        </Box>
      </SubMenu>
    </>
  )
}

export default SubmenuAdmin
