/* eslint-disable max-lines */
import { useEffect } from 'react'
import { SimpleGrid } from '@chakra-ui/layout'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal,
} from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import Editor from 'components/Editor'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useForm, FormProvider, UseFormReturn, Controller } from 'react-hook-form'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import DescriptionGuideline from '../DescriptionGuildline'
import ButtonOption from './components/ButtonOption'
import { IStaffDocumentForm, IStaffDocumentProps } from './constants'
import { ModalTextInput } from './todoOfHRModal.styles'
import './styles.scss'

const StaffDocument = (props: IStaffDocumentProps) => {
  const { isOpenModal, partnerNameList, selectedItem, isEdit, saveHandler, closeHandler } = props
  const nowDate: Date = new Date()
  const partnerOptions = createOptionsOfReactSelectFromDB(partnerNameList)
  const methods: UseFormReturn<IStaffDocumentForm> = useForm<IStaffDocumentForm>({
    defaultValues: {
      publishedDate: nowDate,
    },
  })
  const { control } = methods
  const {
    setValue: setFormValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  useEffect(() => {
    if (isEdit) {
      reset({
        id: selectedItem?._id,
        type: selectedItem?.type,
        description: selectedItem?.description,
        publishedDate: selectedItem?.publishedDate,
        number: selectedItem?.number,
        storageURL: selectedItem?.storageURL,
        partnerId: {
          value: selectedItem?.partnerData?.id,
          label: selectedItem?.partnerData?.fullName,
        },
      })
    }
  }, [isOpenModal])

  return (
    <Modal onClose={closeHandler} isOpen={isOpenModal} isCentered size="xl" closeOnOverlayClick={false}>
      <FormProvider {...methods}>
        <form name="add-staff-document-form" onSubmit={handleSubmit(saveHandler)}>
          <ModalOverlay />
          {/* *INFO: get heigh value base current inputs in add timesheet form */}
          <ModalContent maxH={800}>
            <ModalHeader fontSize="lg" fontWeight={500} lineHeight={7} borderBottom="1px solid #E2E8F0" bg="white">
              {isEdit ? 'Edit Staff Document' : 'Create New Staff Document'}
              <ModalCloseButton color="black" background="none" mt="2" />
            </ModalHeader>
            <ModalBody overflowY="auto">
              <FormControl isInvalid={Boolean(errors?.type)}>
                <FormLabel fontSize="md" fontWeight="400">
                  Document Type
                </FormLabel>
                <HStack>
                  <ModalTextInput placeholder="Document type" {...register('type', { required: true })} />
                </HStack>
                <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
              </FormControl>
              <SimpleGrid marginY="24px" gap="24px">
                <FormControl isInvalid={Boolean(errors?.number)}>
                  <FormLabel fontSize="md" fontWeight="400">
                    Document Number
                  </FormLabel>
                  <HStack>
                    <ModalTextInput placeholder="Document number" {...register('number', { required: true })} />
                  </HStack>
                  <FormErrorMessage>{errors?.number?.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid marginY="24px" gap="24px">
                <FormControl>
                  <FormLabel fontWeight="400">Published Date</FormLabel>
                  <Controller
                    name="publishedDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                        onChange={(date) => handleDatePickerChange(field, date)}
                        placement="top"
                      />
                    )}
                  />
                </FormControl>
              </SimpleGrid>
              <HStack mt="6">
                <FormControl>
                  <FormLabel fontSize="md" fontWeight="400">
                    Belongs to partner
                  </FormLabel>
                  <InputDropdown
                    name="partnerId"
                    placeHolder="Select Partner"
                    optionsData={partnerOptions}
                    isClearable={false}
                  />
                </FormControl>
              </HStack>
              <SimpleGrid mt="6">
                <FormControl isInvalid={Boolean(errors?.taskName)}>
                  <FormLabel fontSize="md" fontWeight="400">
                    Store document at
                  </FormLabel>
                  <HStack>
                    <ModalTextInput placeholder="url" {...register('storageURL', { required: true })} />
                  </HStack>
                  <FormErrorMessage>{errors?.storageURL?.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid mt="6">
                <Editor name="description" placeholder="Enter description" label="Description" />
              </SimpleGrid>
              <DescriptionGuideline />
            </ModalBody>
            <ModalFooter bottom="0" bg="white" borderTop="1px solid #E2E8F0">
              <ButtonOption isEditMode={isEdit} onClose={closeHandler} />
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(StaffDocument)
