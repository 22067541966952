import { Box } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { backgroundWhite, borderGray200, textGrey500 } from 'theme/globalStyles'
import LeaveRequestDetailForm from '../LeaveRequestDetailForm'
import { ILeaveRequestDetailModalProps } from './interface'

const LeaveRequestDetailModal = (props: ILeaveRequestDetailModalProps) => {
  const { isOpen, closeHandler, selectedLeaveRequest } = props

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxHeight={{ lg: '600px', md: '600px', sm: '700px' }}>
        <Box background={backgroundWhite} width="full">
          <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
            Leave request
          </ModalHeader>
          <ModalCloseButton color={textGrey500} background="none" cursor="pointer" mt="2" />
        </Box>
        <ModalBody overflowY="auto" paddingTop={5}>
          <LeaveRequestDetailForm selectedLeaveRequest={selectedLeaveRequest} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LeaveRequestDetailModal
