import apiV2, { getUploadHeader } from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { IHeader } from 'API/constants'
import { ISuccessResponse } from 'API/interface'
import { EUploadFileType } from 'types/upload'
import { IUploadImageResponse, IUploadFileResponse } from './constants'

export async function uploadImage(data: FormData, type: string): Promise<ISuccessResponse<IUploadImageResponse>> {
  try {
    const url = APIRouters.upload.image.type.value(type)
    const uploadHeader: IHeader = getUploadHeader()
    return apiV2.post(url, data, {
      headers: {
        ...uploadHeader,
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

export async function uploadTimesheetImage(data: FormData): Promise<ISuccessResponse<IUploadImageResponse>> {
  try {
    const url = APIRouters.upload.timesheetImage.value
    const uploadHeader: IHeader = getUploadHeader()
    return apiV2.post(url, data, {
      headers: {
        ...uploadHeader,
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

export async function uploadFileCv(
  payload: FormData,
  type: EUploadFileType
): Promise<ISuccessResponse<IUploadFileResponse>> {
  try {
    const uploadHeader: IHeader = getUploadHeader()
    const url = APIRouters.upload.file.type.value(type)
    return apiV2.post(url, payload, {
      headers: {
        ...uploadHeader,
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

export async function deleteFileCv(type: EUploadFileType): Promise<void> {
  try {
    const uploadHeader: IHeader = getUploadHeader()
    const url = APIRouters.upload.file.delete.type.value(type)
    return apiV2.post(url, {
      headers: {
        ...uploadHeader,
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}
