import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Tag,
  TagLabel,
  Box,
  Link,
} from '@chakra-ui/react'
import Button from 'components/Button'
import EmptyContentBox from 'components/EmptyContentBox'
import Icon from 'components/Icon'
import ReactQuill from 'react-quill'
import { backgroundPrimary, backgroundGrey50, backgroundGrey200, borderGray200 } from 'theme/globalStyles'
import { Messages } from 'constants/index'
import { isValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { IStaffDocumentProps } from './interface'

const StaffDocumentList = (props: IStaffDocumentProps) => {
  const { dataGroupsByMonth, openConfirmDeleteModal } = props
  if (!isValidArray(dataGroupsByMonth)) return <EmptyContentBox text={Messages.noData} />
  return (
    <Box>
      {dataGroupsByMonth?.map((item) => {
        return (
          <Accordion allowMultiple key={item.id}>
            <Tag
              variant="subtle"
              colorScheme="blue"
              color="white"
              background={backgroundPrimary}
              userSelect="none"
              padding="4px 12px"
              marginBottom="16px">
              <Icon iconName="user.svg" />
              <TagLabel marginLeft="3px">{item?.staffName}</TagLabel>
            </Tag>
            {item?.data?.map((data) => {
              return (
                <AccordionItem
                  key={data._id}
                  border={`1px solid ${borderGray200}`}
                  borderRadius="6px"
                  boxShadow="none"
                  backgroundColor={backgroundGrey50}
                  marginBottom="16px">
                  <AccordionButton
                    backgroundColor="white"
                    borderRadius="6px"
                    _expanded={{ background: `${backgroundGrey50}` }}>
                    <AccordionIcon color="black" />
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color="black"
                      paddingLeft="10px"
                      fontWeight="500"
                      fontSize="16px">
                      {data?.type}
                    </Box>
                    <Box as="span" color="gray.500" marginLeft="4px">
                      {getFormattedDateWith_MMMDDYYYY(data?.publishedDate)}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel paddingY="16px">
                    <ReactQuill className="task-description" value={data.description} theme="bubble" readOnly />
                    <Box>
                      {data?.storageURL && (
                        <Link href={data?.storageURL} isExternal>
                          Document is storaged at: <ExternalLinkIcon mx="2px" />
                        </Link>
                      )}
                    </Box>
                    <HStack justifyContent="end" borderTop={`1px solid ${backgroundGrey200}`} paddingTop="16px">
                      <Button
                        variant="outline"
                        customStyles={{ color: 'red' }}
                        leftIcon="delete-bin-fill.svg"
                        onClick={() => openConfirmDeleteModal(data)}>
                        Delete
                      </Button>
                      <Button variant="outline" leftIcon="edit-fill.svg" onClick={() => props.openEditModal(data)}>
                        Edit
                      </Button>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        )
      })}
    </Box>
  )
}
export default StaffDocumentList
